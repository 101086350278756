var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"mflmac drag",attrs:{"id":"ZQTC"}},[_c('div',[_c('div',{staticClass:"title-hang",attrs:{"fl":""}},[_vm._v(_vm._s(_vm.projectData.project)+"_ZQT_C")]),_c('div',{staticClass:"param11-header-icon",attrs:{"fr":""},on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"titlelable"},[_vm._v("主汽温度控制模型")]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"leftmain fl"},[_c('div',{staticClass:"leftmain-top"},[_c('div',{staticClass:"left-span1 fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1A_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZQT_1A_PVL',
                  _vm.infoList.ZQT_1A_PVL_node_string,
                  'ZQT_1A_PVL_BM',
                  '屏过A温度过程滤波值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZQT_1A_PVL,
                  'ZQT_1A_PVL',
                  _vm.infoList.ZQT_1A_PVL_node_string,
                  'ZQT_1A_PVL_BM'
                )}}},[_vm._v(" "+_vm._s("ZQT_1A_PVL" in _vm.infoList ? _vm.infoList.ZQT_1A_PVL : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1A_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_1A_TC,
                  'ZQT_1A_TC',
                  'ZQT_1A_TC',
                  _vm.infoList.ZQT_1A_TC_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_1A_TC" in _vm.infoList ? _vm.infoList.ZQT_1A_TC : 0)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1A_SPR")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'SP',
                  _vm.infoList.ZQT_XK1_node_string,
                  'ZQT_XK1_SP_BM',
                  '一级甲主汽温度',
                  'ZQT_XK1'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZQT_XK1.SP,
                  'SP',
                  _vm.infoList.ZQT_XK1_node_string,
                  'ZQT_XK1_SP_BM'
                )}}},[_vm._v(" "+_vm._s("ZQT_XK1" in _vm.infoList ? _vm.infoList.ZQT_XK1.SP : 0)+" ")])]),_c('div',{staticClass:"zlbutton",on:{"click":function($event){return _vm.toCompon(13, 'ZQT1ASP', '负荷气温补偿折现', '风量', 'FM')}}},[_vm._v(" SP增量 ")]),_c('div',{staticClass:"left-span-col1 left-jg2"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1A_SP")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZQT_1A_SP',
                  _vm.infoList.ZQT_1A_SP_node_string,
                  'ZQT_1A_SP_BM',
                  '甲一级减温控制点'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZQT_1A_SP,
                  'ZQT_1A_SP',
                  _vm.infoList.ZQT_1A_SP_node_string,
                  'ZQT_XK1_SP_BM'
                )}}},[_vm._v(" "+_vm._s("ZQT_1A_SP" in _vm.infoList ? _vm.infoList.ZQT_1A_SP : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1A_SPH")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_1A_SP_HL,
                  'ZQT_1A_SPH',
                  'ZQT_1A_SP_HL',
                  _vm.infoList.ZQT_1A_SP_HL_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_1A_SP_HL" in _vm.infoList ? _vm.infoList.ZQT_1A_SP_HL : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1A_SPL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_1A_SP_LL,
                  'ZQT_1A_SPL',
                  'ZQT_1A_SP_LL',
                  _vm.infoList.ZQT_1A_SP_LL_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_1A_SP_LL" in _vm.infoList ? _vm.infoList.ZQT_1A_SP_LL : 0)+" ")])])]),_c('div',{staticClass:"left-span2 fl"},[_c('div',{staticClass:"lablebt1"},[_vm._v("左一级减温控制")]),_c('div',{staticClass:"left-span2-row1"},[_c('div',{staticClass:"bottonx fl",style:({
                background: !_vm.infoList.ZQT_XK1.RM ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.ZQT_XK1
                  ? _vm.toDetail(
                      1,
                      'ZQT_XK1',
                      _vm.infoList.ZQT_XK1_node_string,
                      '',
                      '一级甲主汽温度'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fr",style:({
                background:
                  'ZQT_MAN1' ||
                  'ZQT_MAN2' ||
                  'ZQT_MAN3' ||
                  'ZQT_MAN4' in _vm.infoList
                    ? _vm.infoList.ZQT_MAN1.RM == 1 ||
                      _vm.infoList.ZQT_MAN2.RM == 1 ||
                      _vm.infoList.ZQT_MAN3.RM == 1 ||
                      _vm.infoList.ZQT_MAN4.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANQW', 4)}}},[_vm._v(" A ")])]),_c('div',{staticClass:"left-span2-row2"},[_c('div',{staticClass:"bottonx fl",style:({
                background: !_vm.infoList.ZQT_QK1.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.ZQT_QK1
                  ? _vm.toDetail(
                      2,
                      'ZQT_QK1',
                      _vm.infoList.ZQT_QK1_node_string,
                      '',
                      '一级减温左前馈'
                    )
                  : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"buttonlable fl",style:({
                background: !_vm.infoList.ZQT_1A_QS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_1A_QS,
                  'ZQT_1A_QS',
                  'ZQT_1A_QS',
                  _vm.infoList.ZQT_1A_QS_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_1A_QS" in _vm.infoList ? _vm.infoList.ZQT_1A_QS ? "串级" : "单回路" : 0)+" ")])]),_c('div',{staticClass:"left-span2-row3"},[_c('div',{staticClass:"bottonx fl",style:({
                background: !_vm.infoList.ZQT_XK2.RM ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.ZQT_XK2
                  ? _vm.toDetail(
                      1,
                      'ZQT_XK2',
                      _vm.infoList.ZQT_XK2_node_string,
                      '',
                      '一级甲主汽温度副调'
                    )
                  : ''}}},[_vm._v(" X ")])]),_c('div')]),_c('div',{staticClass:"left-span3 fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MAN1")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.ZQT_MAN1_node_string,
                  'ZQT_MAN1_AV_BM',
                  '一级甲减温水手操器',
                  'ZQT_MAN1'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZQT_MAN1.AV,
                  'AV',
                  _vm.infoList.ZQT_MAN1_node_string,
                  'ZQT_MAN1_AV_BM'
                )}}},[_vm._v(" "+_vm._s("ZQT_MAN1" in _vm.infoList ? _vm.infoList.ZQT_MAN1.AV : 0)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV1A_HL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_MAN1.OutT,
                  'ZQT_MV1A_HL',
                  'OutT',
                  _vm.infoList.ZQT_MAN1_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_MAN1" in _vm.infoList ? _vm.infoList.ZQT_MAN1.OutT : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV1A_LL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_MAN1.OutB,
                  'ZQT_1A_SP_LL',
                  'OutB',
                  _vm.infoList.ZQT_MAN1_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_MAN1" in _vm.infoList ? _vm.infoList.ZQT_MAN1.OutB : 0)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg2"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_1A_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'JWHT_1A_PVL',
                  _vm.infoList.JWHT_1A_PVL_node_string,
                  'JWHT_1A_PVL_BM',
                  '一级左减温后温度滤波值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.JWHT_1A_PVL,
                  'JWHT_1A_PVL',
                  _vm.infoList.JWHT_1A_PVL_node_string,
                  'JWHT_1A_PVL_BM'
                )}}},[_vm._v(" "+_vm._s("JWHT_1A_PVL" in _vm.infoList ? _vm.infoList.JWHT_1A_PVL : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_1A_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.JWHT_1A_TC,
                  'JWHT_1A_TC',
                  'JWHT_1A_TC',
                  _vm.infoList.JWHT_1A_TC_node_string
                )}}},[_vm._v(" "+_vm._s("JWHT_1A_TC" in _vm.infoList ? _vm.infoList.JWHT_1A_TC : 0)+" ")])])])]),_c('div',[_c('div',{staticClass:"left-span1 fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1B_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZQT_1B_PVL',
                  _vm.infoList.ZQT_1B_PVL_node_string,
                  'ZQT_1B_PVL_BM',
                  '屏过B温度过程滤波值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZQT_1B_PVL,
                  'ZQT_1B_PVL',
                  _vm.infoList.ZQT_1B_PVL_node_string,
                  'ZQT_1B_PVL_BM'
                )}}},[_vm._v(" "+_vm._s("ZQT_1B_PVL" in _vm.infoList ? _vm.infoList.ZQT_1B_PVL : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1B_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_1B_TC,
                  'ZQT_1B_TC',
                  'ZQT_1B_TC',
                  _vm.infoList.ZQT_1B_TC_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_1B_TC" in _vm.infoList ? _vm.infoList.ZQT_1B_TC : 0)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1B_SPR")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'SP',
                  _vm.infoList.ZQT_XK3_node_string,
                  'ZQT_XK3_SP_BM',
                  '一级乙主汽温度',
                  'ZQT_XK3'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZQT_XK3.SP,
                  'SP',
                  _vm.infoList.ZQT_XK3_node_string,
                  'ZQT_XK3_SP_BM'
                )}}},[_vm._v(" "+_vm._s("ZQT_XK3" in _vm.infoList ? _vm.infoList.ZQT_XK3.SP : 0)+" ")])]),_c('div',{staticClass:"zlbutton",on:{"click":function($event){return _vm.toCompon(13, 'ZQT1BSP', '负荷气温补偿折现', '风量', 'FM')}}},[_vm._v(" SP增量 ")]),_c('div',{staticClass:"left-span-col1 left-jg2"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1B_SP")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZQT_1B_SP',
                  _vm.infoList.ZQT_1B_SP_node_string,
                  'ZQT_1B_SP_BM',
                  '乙一级减温控制点'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZQT_1B_SP,
                  'ZQT_1B_SP',
                  _vm.infoList.ZQT_1B_SP_node_string,
                  'ZQT_1B_SP_BM'
                )}}},[_vm._v(" "+_vm._s("ZQT_1B_SP" in _vm.infoList ? _vm.infoList.ZQT_1B_SP : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1B_SPH")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_1B_SP_HL,
                  'ZQT_1B_SPH',
                  'ZQT_1B_SP_HL',
                  _vm.infoList.ZQT_1B_SP_HL_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_1B_SP_HL" in _vm.infoList ? _vm.infoList.ZQT_1B_SP_HL : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_1B_SPL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_1B_SP_LL,
                  'ZQT_1B_SPL',
                  'ZQT_1B_SP_LL',
                  _vm.infoList.ZQT_1B_SP_LL_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_1B_SP_LL" in _vm.infoList ? _vm.infoList.ZQT_1B_SP_LL : 0)+" ")])])]),_c('div',{staticClass:"left-span2 fl"},[_c('div',{staticClass:"lablebt1"},[_vm._v("右一级减温控制")]),_c('div',{staticClass:"left-span2-row1 lablebt1"},[_c('div',{staticClass:"bottonx fl",style:({
                background: !_vm.infoList.ZQT_XK3.RM ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.ZQT_XK3
                  ? _vm.toDetail(
                      1,
                      'ZQT_XK3',
                      _vm.infoList.ZQT_XK3_node_string,
                      '',
                      '一级乙主汽温度'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fr",style:({
                background:
                  'ZQT_MAN1' ||
                  'ZQT_MAN2' ||
                  'ZQT_MAN3' ||
                  'ZQT_MAN4' in _vm.infoList
                    ? _vm.infoList.ZQT_MAN1.RM == 1 ||
                      _vm.infoList.ZQT_MAN2.RM == 1 ||
                      _vm.infoList.ZQT_MAN3.RM == 1 ||
                      _vm.infoList.ZQT_MAN4.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANQW', 4)}}},[_vm._v(" A ")])]),_c('div',{staticClass:"left-span2-row2"},[_c('div',{staticClass:"bottonx fl",style:({
                background: !_vm.infoList.ZQT_QK2.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.ZQT_QK2
                  ? _vm.toDetail(
                      2,
                      'ZQT_QK2',
                      _vm.infoList.ZQT_QK2_node_string,
                      '',
                      '一级减温右前馈'
                    )
                  : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"buttonlable fl",style:({
                background: !_vm.infoList.ZQT_1B_QS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_1B_QS,
                  '此按钮',
                  'ZQT_1B_QS',
                  _vm.infoList.ZQT_1B_QS_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_1B_QS" in _vm.infoList ? _vm.infoList.ZQT_1B_QS ? "串级" : "单回路" : 0)+" ")])]),_c('div',{staticClass:"left-span2-row3"},[_c('div',{staticClass:"bottonx fl",style:({
                background: !_vm.infoList.ZQT_XK4.RM ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.ZQT_XK4
                  ? _vm.toDetail(
                      1,
                      'ZQT_XK4',
                      _vm.infoList.ZQT_XK4_node_string,
                      '',
                      '一级主汽温度副调'
                    )
                  : ''}}},[_vm._v(" X ")])]),_c('div')]),_c('div',{staticClass:"left-span3 fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MAN2")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.ZQT_MAN2_node_string,
                  'ZQT_MAN2_AV_BM',
                  '一级乙减温水手操器',
                  'ZQT_MAN2'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZQT_MAN2.AV,
                  'AV',
                  _vm.infoList.ZQT_MAN2_node_string,
                  'ZQT_MAN2_AV_BM'
                )}}},[_vm._v(" "+_vm._s("ZQT_MAN2" in _vm.infoList ? _vm.infoList.ZQT_MAN2.AV : 0)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV1B_HL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_MAN2.OutT,
                  'ZQT_MV1B_HL',
                  'OutT',
                  _vm.infoList.ZQT_MAN2_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_MAN2" in _vm.infoList ? _vm.infoList.ZQT_MAN2.OutT : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV1B_LL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_MAN2.OutB,
                  'ZQT_MV1B_LL',
                  'OutB',
                  _vm.infoList.ZQT_MAN2_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_MAN2" in _vm.infoList ? _vm.infoList.ZQT_MAN2.OutB : 0)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg2"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_1B_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'JWHT_1B_PVL',
                  _vm.infoList.JWHT_1B_PVL_node_string,
                  'JWHT_1B_PVL_BM',
                  '一级右减温后温度滤波值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.JWHT_1B_PVL,
                  'JWHT_1B_PVL',
                  _vm.infoList.JWHT_1B_PVL_node_string,
                  'JWHT_1B_PVL_BM'
                )}}},[_vm._v(" "+_vm._s("JWHT_1B_PVL" in _vm.infoList ? _vm.infoList.JWHT_1B_PVL : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_1B_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.JWHT_1A_TC,
                  'JWHT_1B_TC',
                  'JWHT_1B_TC',
                  _vm.infoList.JWHT_1A_TC_node_string
                )}}},[_vm._v(" "+_vm._s("JWHT_1A_TC" in _vm.infoList ? _vm.infoList.JWHT_1B_TC : 0)+" ")])])])])]),_c('div',{staticClass:"rightmain fl"},[_c('div',{staticClass:"rightmain-top"},[_c('div',{staticClass:"left-span1 fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2A_SPH")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_2A_SP_HL,
                  'ZQT_2A_SPH',
                  'ZQT_2A_SP_HL',
                  _vm.infoList.ZQT_2A_SP_HL_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_2A_SP_HL" in _vm.infoList ? _vm.infoList.ZQT_2A_SP_HL : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2A_SPL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_2A_SP_LL,
                  'ZQT_2A_SPL',
                  'ZQT_2A_SP_LL',
                  _vm.infoList.ZQT_2A_SP_LL_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_2A_SP_LL" in _vm.infoList ? _vm.infoList.ZQT_2A_SP_LL : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2A_SP")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZQT_2A_SP',
                  _vm.infoList.ZQT_2A_SP_node_string,
                  'ZQT_2A_SP_BM',
                  '甲二级减温控制点'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZQT_2A_SP,
                  'ZQT_2A_SP',
                  _vm.infoList.ZQT_2A_SP_node_string,
                  'ZQT_2A_SP_BM'
                )}}},[_vm._v(" "+_vm._s("ZQT_2A_SP" in _vm.infoList ? _vm.infoList.ZQT_2A_SP : 0)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2A_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZQT_2A_PVL',
                  _vm.infoList.ZQT_2A_PVL_node_string,
                  'ZQT_2A_PVL_BM',
                  '主汽温度过程滤波值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZQT_2A_PVL,
                  'ZQT_2A_PVL',
                  _vm.infoList.ZQT_2A_PVL_node_string,
                  'ZQT_2A_PVL_BM'
                )}}},[_vm._v(" "+_vm._s("ZQT_2A_PVL" in _vm.infoList ? _vm.infoList.ZQT_2A_PVL : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2A_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_2A_TC,
                  'ZQT_2A_TC',
                  'ZQT_2A_TC',
                  _vm.infoList.ZQT_2A_TC_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_2A_TC" in _vm.infoList ? _vm.infoList.ZQT_2A_TC : 0)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_2A_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'JWHT_2A_PVL',
                  _vm.infoList.JWHT_2A_PVL_node_string,
                  'JWHT_2A_PVL_BM',
                  '二级左减温后温度滤波值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.JWHT_2A_PVL,
                  'JWHT_2A_PVL',
                  _vm.infoList.JWHT_2A_PVL_node_string,
                  'JWHT_2A_PVL_BM'
                )}}},[_vm._v(" "+_vm._s("JWHT_2A_PVL" in _vm.infoList ? _vm.infoList.JWHT_2A_PVL : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_2A_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.JWHT_2A_TC,
                  'JWHT_2A_TC',
                  'JWHT_2A_TC',
                  _vm.infoList.JWHT_2A_TC_node_string
                )}}},[_vm._v(" "+_vm._s("JWHT_2A_TC" in _vm.infoList ? _vm.infoList.JWHT_2A_TC : 0)+" ")])])]),_c('div',{staticClass:"right-span2 fl"},[_c('div',{staticClass:"lablebt"},[_vm._v("左二级减温控制")]),_c('div',{staticClass:"right-span2-row1"},[_c('div',{staticClass:"bottonx fl",style:({
                background: !_vm.infoList.ZQT_XK5.RM ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.ZQT_XK5
                  ? _vm.toDetail(
                      1,
                      'ZQT_XK5',
                      _vm.infoList.ZQT_XK5_node_string,
                      '',
                      '二级甲减温先控调节'
                    )
                  : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"right-span2-row2"},[_c('div',{staticClass:"bottonx fl",style:({
                background: !_vm.infoList.ZQT_QK3.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.ZQT_QK3
                  ? _vm.toDetail(
                      2,
                      'ZQT_QK3',
                      _vm.infoList.ZQT_QK3_node_string,
                      '',
                      '二级减温左前馈'
                    )
                  : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"buttonlable fl",style:({
                background: !_vm.infoList.ZQT_2A_QS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_2A_QS,
                  'ZQT_2A_QS',
                  'ZQT_2A_QS',
                  _vm.infoList.ZQT_2A_QS_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_2A_QS" in _vm.infoList ? _vm.infoList.ZQT_2A_QS ? "串级" : "单回路" : 0)+" ")])]),_c('div',{staticClass:"right-span2-row3"},[_c('div',{staticClass:"bottonx fl",style:({
                background: !_vm.infoList.ZQT_XK6.RM ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.ZQT_XK6
                  ? _vm.toDetail(
                      1,
                      'ZQT_XK6',
                      _vm.infoList.ZQT_XK6_node_string,
                      '',
                      '二级甲减温先控副调节'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl",staticStyle:{"margin-left":"20vw"},style:({
                background: !_vm.infoList.ZQT_QK5.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.ZQT_QK5
                  ? _vm.toDetail(
                      2,
                      'ZQT_QK5',
                      _vm.infoList.ZQT_QK5_node_string,
                      '',
                      '负荷前馈'
                    )
                  : ''}}},[_vm._v(" Q ")])]),_c('div')]),_c('div',{staticClass:"left-span3 fl"},[_c('div',[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx",staticStyle:{"margin-right":"1vw"},style:({
                  background:
                    'ZQT_MAN1' ||
                    'ZQT_MAN2' ||
                    'ZQT_MAN3' ||
                    'ZQT_MAN4' in _vm.infoList
                      ? _vm.infoList.ZQT_MAN1.RM == 1 ||
                        _vm.infoList.ZQT_MAN2.RM == 1 ||
                        _vm.infoList.ZQT_MAN3.RM == 1 ||
                        _vm.infoList.ZQT_MAN4.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANQW', 4)}}},[_vm._v(" A ")])]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MAN3")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'AV',
                      _vm.infoList.ZQT_MAN3_node_string,
                      'ZQT_MAN3_AV_BM',
                      '二级甲减温水手操器',
                      'ZQT_MAN3'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.ZQT_MAN3.AV,
                      'AV',
                      _vm.infoList.ZQT_MAN3_node_string,
                      'ZQT_MAN3_AV_BM'
                    )}}},[_vm._v(" "+_vm._s("ZQT_MAN3" in _vm.infoList ? _vm.infoList.ZQT_MAN3.AV : 0)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV2A_HL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.ZQT_MAN3.OutT,
                      'ZQT_MV2A_HL',
                      'OutT',
                      _vm.infoList.ZQT_MAN3_node_string
                    )}}},[_vm._v(" "+_vm._s("ZQT_MAN3" in _vm.infoList ? _vm.infoList.ZQT_MAN3.OutT : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV2A_LL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.ZQT_MAN3.OutB,
                      'ZQT_MV2A_LL',
                      'OutB',
                      _vm.infoList.ZQT_MAN3_node_string
                    )}}},[_vm._v(" "+_vm._s("ZQT_MAN3" in _vm.infoList ? _vm.infoList.ZQT_MAN3.OutB : 0)+" ")])])])])])]),_c('div',[_c('div',{staticClass:"left-span1 fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2B_SPH")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_2B_SP_HL,
                  'ZQT_2B_SPH',
                  'ZQT_2B_SP_HL',
                  _vm.infoList.ZQT_2B_SP_HL_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_2B_SP_HL" in _vm.infoList ? _vm.infoList.ZQT_2B_SP_HL : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2B_SPL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_2B_SP_LL,
                  'ZQT_2B_SPL',
                  'ZQT_2B_SP_LL',
                  _vm.infoList.ZQT_2B_SP_LL_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_2B_SP_LL" in _vm.infoList ? _vm.infoList.ZQT_2B_SP_LL : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2B_SP")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZQT_2B_SP',
                  _vm.infoList.ZQT_2B_SP_node_string,
                  'ZQT_2B_SP_BM',
                  '乙一级减温控制点'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZQT_2B_SP,
                  'ZQT_2B_SP',
                  _vm.infoList.ZQT_2B_SP_node_string,
                  'ZQT_2B_SP_BM'
                )}}},[_vm._v(" "+_vm._s("ZQT_2B_SP" in _vm.infoList ? _vm.infoList.ZQT_2B_SP : 0)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2B_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZQT_2B_PVL',
                  _vm.infoList.ZQT_2B_PVL_node_string,
                  'ZQT_2B_PVL_BM',
                  '主汽温度过程滤波值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZQT_2B_PVL,
                  'ZQT_2B_PVL',
                  _vm.infoList.ZQT_2B_PVL_node_string,
                  'ZQT_2B_PVL_BM'
                )}}},[_vm._v(" "+_vm._s("ZQT_2B_PVL" in _vm.infoList ? _vm.infoList.ZQT_2B_PVL : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_2B_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_2B_TC,
                  'ZQT_2B_TC',
                  'ZQT_2B_TC',
                  _vm.infoList.ZQT_2B_TC_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_2B_TC" in _vm.infoList ? _vm.infoList.ZQT_2B_TC : 0)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_2B_PVL")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'JWHT_2B_PVL',
                  _vm.infoList.JWHT_2B_PVL_node_string,
                  'JWHT_2B_PVL_BM',
                  '二级右减温后温度滤波值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.JWHT_2B_PVL,
                  'JWHT_2B_PVL',
                  _vm.infoList.JWHT_2B_PVL_node_string,
                  'JWHT_2B_PVL_BM'
                )}}},[_vm._v(" "+_vm._s("JWHT_2B_PVL" in _vm.infoList ? _vm.infoList.JWHT_2B_PVL : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("JWHT_2B_TC")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.JWHT_2B_TC,
                  'JWHT_2B_TC',
                  'JWHT_2B_TC',
                  _vm.infoList.JWHT_2B_TC_node_string
                )}}},[_vm._v(" "+_vm._s("JWHT_2B_TC" in _vm.infoList ? _vm.infoList.JWHT_2B_TC : 0)+" ")])])]),_c('div',{staticClass:"right-span2 fl"},[_c('div',{staticClass:"lablebt"},[_vm._v("右二级减温控制")]),_c('div',{staticClass:"right-span2-row1",staticStyle:{"margin-top":"2vh"}},[_c('div',{staticClass:"bottonx fl",style:({
                background: !_vm.infoList.ZQT_XK7.RM ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.ZQT_XK7
                  ? _vm.toDetail(
                      1,
                      'ZQT_XK7',
                      _vm.infoList.ZQT_XK7_node_string,
                      '',
                      '二级乙减温先控调节'
                    )
                  : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"right-span2-row2"},[_c('div',{staticClass:"bottonx fl",style:({
                background: !_vm.infoList.ZQT_QK4.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.ZQT_QK4
                  ? _vm.toDetail(
                      2,
                      'ZQT_QK4',
                      _vm.infoList.ZQT_QK4_node_string,
                      '',
                      '二级减温右前馈'
                    )
                  : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"buttonlable fl",style:({
                background: !_vm.infoList.ZQT_2B_QS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_2B_QS,
                  '此按钮',
                  'ZQT_2B_QS',
                  _vm.infoList.ZQT_2B_QS_node_string
                )}}},[_vm._v(" "+_vm._s("ZQT_2B_QS" in _vm.infoList ? _vm.infoList.ZQT_2B_QS ? "串级" : "单回路" : 0)+" ")])]),_c('div',{staticClass:"right-span2-row3"},[_c('div',{staticClass:"bottonx fl",style:({
                background: !_vm.infoList.ZQT_XK8.RM ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.ZQT_XK8
                  ? _vm.toDetail(
                      1,
                      'ZQT_XK8',
                      _vm.infoList.ZQT_XK8_node_string,
                      '',
                      '二级乙减温先控副调节'
                    )
                  : ''}}},[_vm._v(" X ")])]),_c('div')]),_c('div',{staticClass:"left-span3 fl"},[_c('div',[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx",staticStyle:{"margin-right":"1vw"},style:({
                  background:
                    'ZQT_MAN1' ||
                    'ZQT_MAN2' ||
                    'ZQT_MAN3' ||
                    'ZQT_MAN4' in _vm.infoList
                      ? _vm.infoList.ZQT_MAN1.RM == 1 ||
                        _vm.infoList.ZQT_MAN2.RM == 1 ||
                        _vm.infoList.ZQT_MAN3.RM == 1 ||
                        _vm.infoList.ZQT_MAN4.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANQW', 4)}}},[_vm._v(" A ")])]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MAN4")]),_c('div',{staticClass:"valuex fl color",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'AV',
                      _vm.infoList.ZQT_MAN4_node_string,
                      'ZQT_MAN4_AV_BM',
                      '二级乙减温水手操器',
                      'ZQT_MAN4'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.ZQT_MAN4.AV,
                      'AV',
                      _vm.infoList.ZQT_MAN4_node_string,
                      'ZQT_MAN4_AV_BM'
                    )}}},[_vm._v(" "+_vm._s("ZQT_MAN4" in _vm.infoList ? _vm.infoList.ZQT_MAN4.AV : 0)+" ")])]),_c('div',{staticClass:"left-span-col1 left-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV2B_HL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.ZQT_MAN4.OutT,
                      'ZQT_MV2B_HL',
                      'OutT',
                      _vm.infoList.ZQT_MAN4_node_string
                    )}}},[_vm._v(" "+_vm._s("ZQT_MAN4" in _vm.infoList ? _vm.infoList.ZQT_MAN4.OutT : 0)+" ")])]),_c('div',{staticClass:"left-span-col1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQT_MV2B_LL")]),_c('div',{staticClass:"valuex fl colorx",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.ZQT_MAN4.OutB,
                      'ZQT_MV2B_LL',
                      'OutB',
                      _vm.infoList.ZQT_MAN4_node_string
                    )}}},[_vm._v(" "+_vm._s("ZQT_MAN4" in _vm.infoList ? _vm.infoList.ZQT_MAN4.OutB : 0)+" ")])])])])])])])]),_c('div'),(_vm.mflmanyManual)?_c('mflmanyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"mark":_vm.Manualmark,"manvar":_vm.Manualvar,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"node1":_vm.node1,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose1}}):_vm._e(),_c('inputVal',{ref:"inputVal"}),(_vm.MfZQFOZX)?_c('MfZQFOZX',{attrs:{"infoList":_vm.infoList,"MfZQFOZXCS1":_vm.MfZQFOZXCS1,"MfZQFOZXCS2":_vm.MfZQFOZXCS2,"MfZQFOZXCS3":_vm.MfZQFOZXCS3,"MfZQFOZXCS4":_vm.MfZQFOZXCS4},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }