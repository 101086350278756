<template>
  <div class="mflmac drag" id="YCFC" v-draw>
    <!-- 标题部分 -->
    <div>
      <div class="title-hang" fl>{{ projectData.project }}_YCF_C</div>
      <div class="param11-header-icon" fr @click="closeCompon">
        <img
          src="~@/assets/images/close.png"
          style="width: 100%; height: auto"
        />
      </div>
    </div>
    <div class="titlelable">一次风压优化控制模型</div>
    <!-- 页面分为左中右三部分 -->
    <div class="main">
      <div class="leftmain fl">
        <div class="leftmain-col">
          <div class="widthd fl">YCF_A_SPB</div>
          <div
            class="valuex fl"
            @click="
              toCompon(
                2,
                'YCF_A_SPB2',
                infoList.YCF_A_SPB2_node_string,
                'YCF_A_SPB_BM',
                '1#一次风设定值偏置'
              )
            "
            @dblclick="
              Cclick(
                infoList.YCF_A_SPB2,
                'YCF_A_SPB2',
                infoList.YCF_A_SPB2_node_string,
                'YCF_A_SPB_BM'
              )
            "
          >
            {{ 'YCF_A_SPB2' in infoList?infoList.YCF_A_SPB2 :0}}
          </div>
        </div>
        <!-- <div class="fybutton"  @click="toCompon(13,'ZQFS','MFL_','最小风量折线','FM')">风压SP</div> -->
        <div
          class="fybutton"
          @click="toCompon(13, 'ZQFS', '最小风量折线', 'GML', 'FM')"
        >
          风压SP
        </div>
        <div class="leftmain-col leftmain-jg">
          <div class="widthd fl">YCF_A_SP</div>
          <div
            class="valuex fl"
            @click="
              toCompon(2, 'YCF_A_SP', infoList.YCF_A_SP_node_string, 'YCF_A_SP_BM', '1#一次风设定值')
            "
            @dblclick="Cclick(infoList.YCF_A_SP, 'YCF_A_SP', infoList.YCF_A_SP_node_string, 'YCF_A_SP_BM')"
          >
            {{ 'YCF_A_SP' in infoList?infoList.YCF_A_SP :0}}
          </div>
        </div>
        <div class="leftmain-col leftmain-jg">
          <div class="widthd fl">YCF_SPH</div>
          <div
            class="valuex fl colorl"
            @click="
              toIpt(infoList.YCF_SP_HL, 'YCF_SPH', 'YCF_SP_HL', infoList.YCF_SP_HL_node_string)
            "
          >
            {{ 'YCF_SP_HL' in infoList?infoList.YCF_SP_HL :0}}
          </div>
        </div>
        <div class="leftmain-col">
          <div class="widthd fl">YCF_SPL</div>
          <div
            class="valuex fl colorl"
            @click="
              toIpt(infoList.YCF_SP_LL, 'YCF_SPL', 'YCF_SP_LL', infoList.YCF_SP_LL_node_string)
            "
          >
            {{ 'YCF_SP_LL' in infoList?infoList.YCF_SP_LL :0}}
          </div>
        </div>
      </div>
      <!-- 中间部分，也就是插入的位图 -->
      <div class="centermain fl">
        <!-- 中间部分又分为2部分，左右 -->
        <div class="fl centmian-left">
          <div class="leftmain-col centmian-jg">
            <div class="widthd fl">YCI_PVA_HL</div>
            <div
              class="valuex fl colorl"
              @click="
                toIpt(
                  infoList.YCI_PVA_HL,
                  'YCI_PVA_HL',
                  'YCI_PVA_HL',
                  infoList.YCI_PVA_HL_node_string
                )
              "
            >
              {{ 'YCI_PVA_HL' in infoList?infoList.YCI_PVA_HL :0}}
            </div>
          </div>
          <div class="leftmain-col centmian-jg1">
            <div class="widthd fl">YCI_PVB_HL</div>
            <div
              class="valuex fl colorl"
              @click="
                toIpt(
                  infoList.YCI_PVA_LL,
                  'YCI_PVA_LL',
                  'YCI_PVA_LL',
                  infoList.YCI_PVA_LL_node_string
                )
              "
            >
              {{ 'YCI_PVA_LL' in infoList?infoList.YCI_PVA_LL :0}}
            </div>
          </div>
          <div>
            <div
              class="bottonx fl"
              :style="{
                background: !infoList.YCF_XK1.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.YCF_XK1
                  ? toDetail(
                      1,
                      'YCF_XK1',
                      infoList.YCF_XK1_node_string,
                      '',
                      '一次风压先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div class="leftmain-colx centmian-jg2 fl">
              <div class="widthd fl">BP-AV</div>
              <div
                class="valuex fl"
                @click="
                  toCompon(
                    2,
                    'AV',
                    infoList.YCF_XK1_node_string,
                    'YCF_XK1_AV_BM',
                    '一次风压先控调节',
					'YCF_XK1'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.YCF_XK1.AV,
                    'AV',
                    infoList.YCF_XK1_node_string,
                    'YCF_XK1_AV_BM'
                  )
                "
              >
                {{ 'YCF_XK1' in infoList?infoList.YCF_XK1.AV :0}}
              </div>
            </div>
          </div>
          <div class="leftmain-col centmian-jg3">
            <div class="widthd fl">YCF_TC</div>
            <div
              class="valuex fl colorl"
              @click="toIpt(infoList.YCF_TC, 'YCF_TC', 'YCF_TC', infoList.YCF_TC_node_string)"
            >
              {{ 'YCF_TC' in infoList?infoList.YCF_TC :0}}
            </div>
          </div>
          <div class="leftmain-col centmian-jg4">
            <div class="widthd fl">YCF_A_PVL</div>
            <div
              class="valuex fl"
              @click="
                toCompon(
                  2,
                  'YCF_A_PVL',
                  infoList.YCF_A_PVL_node_string,
                  'YCF_A_PVL_BM',
                  'A一次风压滤波值'
                )
              "
              @dblclick="
                Cclick(
                  infoList.YCF_A_PVL,
                  'YCF_A_PVL',
                  infoList.YCF_A_PVL_node_string,
                  'YCF_A_PVL_BM'
                )
              "
            >
              {{ 'YCF_A_PVL' in infoList?infoList.YCF_A_PVL :0}}
            </div>
          </div>
        </div>
        <div class="fl centmian-right">
          <div
            class="bottonx centmian-jg5"
            :style="{
              background: !infoList.YCF_RSF1.TS ? '#2AFC30' : 'red',
            }"
            @click="
              infoList.YCF_RSF1
                ? toDetail(
                    3,
                    'YCF_RSF1',
                    infoList.YCF_RSF1_node_string,
                    '',
                    '一次风机电流纠偏'
                  )
                : ''
            "
          >
            R
          </div>
          <div class="leftmain-col1 centmian-jg6">
            <div class="widthd1 fl">YCF_MVBA_B</div>
            <div
              class="valuex fl colorl"
              @click="
                toIpt(infoList.YCF_MVA_B, 'YCF_MVA_B', 'YCF_MVA_B', infoList.YCF_MVA_B_node_string)
              "
            >
              {{ 'YCF_MVA_B' in infoList?infoList.YCF_MVA_B :0}}
            </div>
          </div>
          <div
            class="bottonx centmian-jg7"
            :style="{
              background:
			  'YCF_MAN1'||'YCF_MAN2' in infoList?
                infoList.YCF_MAN1.RM == 1 ||
                infoList.YCF_MAN2.RM == 1
                  ? '#2AFC30'
                  : 'red': 'red',
            }"
            @click="toCompon(0, 'MFL_MANYCF', 2)"
          >
            A
          </div>
          <div class="leftmain-col1 centmian-jg8">
            <div class="widthd1 fl">YCF_MVBB_B</div>
            <div
              class="valuex fl colorl"
              @click="
                toIpt(infoList.YCF_MVB_B, 'YCF_MVB_B', 'YCF_MVB_B', infoList.YCF_MVB_B_node_string)
              "
            >
              {{ 'YCF_MVB_B' in infoList?infoList.YCF_MVB_B :0}}
            </div>
          </div>
          <div class="centmian-jg9">
            <div
              class="bottonx fl"
              :style="{
                background: !infoList.YCF_QK1.TS ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.YCF_QK1
                  ? toDetail(2, 'YCF_QK1', infoList.YCF_QK1_node_string, '', '一次风前馈')
                  : ''
              "
            >
              Q
            </div>
            <div class="leftmain-col centmian-jg10 fl">
              <div class="widthd fl">ZQF_SP_TC</div>
              <div
                class="valuex fl colorl"
                @click="
                  toIpt(infoList.ZQF_SP_TC, 'ZQF_SP_TC', 'ZQF_SP_TC', infoList.ZQF_SP_TC_node_string)
                "
              >
                {{ 'ZQF_SP_TC' in infoList?infoList.ZQF_SP_TC :0}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightmain fl">
        <div class="leftmain-col1">
          <div class="widthd1 fl">YCFI_JPPC</div>
          <div
            class="valuex fl colorl"
            @click="
              toIpt(infoList.YCJI_JPPC, 'YCJI_JPPC', 'YCJI_JPPC', infoList.YCJI_JPPC_node_string)
            "
          >
            {{ 'YCJI_JPPC' in infoList?infoList.YCJI_JPPC :0}}
          </div>
        </div>
        <div class="leftmain-col1 rightmain-jg1">
          <div class="widthd1 fl">YCF_MAN1</div>
          <div
            class="valuex fl"
            @click="
              toCompon(
                2,
                'AV',
                infoList.YCF_MAN1_node_string,
                'YCF_MAN1_AV_BM',
                '1#一次风机手操器',
				'YCF_MAN1'
              )
            "
            @dblclick="
              Cclick(
                infoList.YCF_MAN1.AV,
                'AV',
                infoList.YCF_MAN1_node_string,
                'YCF_MAN1_AV_BM'
              )
            "
          >
            {{ 'YCF_MAN1' in infoList?infoList.YCF_MAN1.AV :0}}
          </div>
        </div>
        <div class="leftmain-col1 rightmain-jg2">
          <div class="widthd1 fl">YCF-MVA-HL</div>
          <div
            class="valuex fl colorl"
            @click="
              toIpt(
                infoList.YCF_MAN1.OutT,
                'YCF-MVA-HL',
                'OutT',
                infoList.YCF_MAN1_node_string
              )
            "
          >
            {{ 'YCF_MAN1' in infoList?infoList.YCF_MAN1.OutT :0}}
          </div>
        </div>
        <div class="leftmain-col1">
          <div class="widthd1 fl">YCF-MVA-LL</div>
          <div
            class="valuex fl colorl"
            @click="
              toIpt(
                infoList.YCF_MAN1.OutB,
                'YCF-MVA-LL',
                'OutB',
                infoList.YCF_MAN1_node_string
              )
            "
          >
            {{ 'YCF_MAN1' in infoList?infoList.YCF_MAN1.OutB :0}}
          </div>
        </div>
        <div class="leftmain-col1 rightmain-jg3">
          <div class="widthd1 fl">YCF-MAN2</div>
          <div
            class="valuex fl"
            @click="
              toCompon(
                2,
                'AV',
                infoList.YCF_MAN2_node_string,
                'YCF_MAN2_AV_BM',
                '2#一次风机手操器',
				'YCF_MAN2'
              )
            "
            @dblclick="
              Cclick(
                infoList.YCF_MAN2.AV,
                'AV',
                infoList.YCF_MAN2_node_string,
                'YCF_MAN2_AV_BM'
              )
            "
          >
            {{ 'YCF_MAN2' in infoList?infoList.YCF_MAN2.AV :0}}
          </div>
        </div>
        <div class="leftmain-col1 rightmain-jg2">
          <div class="widthd1 fl">YCF-MVB-HL</div>
          <div
            class="valuex fl colorl"
            @click="
              toIpt(
                infoList.YCF_MAN2.OutT,
                'YCF-MVB-HL',
                'OutT',
                infoList.YCF_MAN2_node_string
              )
            "
          >
            {{ 'YCF_MAN2' in infoList?infoList.YCF_MAN2.OutT :0}}
          </div>
        </div>
        <div class="leftmain-col1">
          <div class="widthd1 fl">YCF-MVB-LL</div>
          <div
            class="valuex fl colorl"
            @click="
              toIpt(
                infoList.YCF_MAN2.OutB,
                'YCF-MVB-LL',
                'OutB',
                infoList.YCF_MAN2_node_string
              )
            "
          >
            {{ 'YCF_MAN2' in infoList?infoList.YCF_MAN2.OutB :0}}
          </div>
        </div>
      </div>
    </div>
    <mflmanyManual
      v-if="mflmanyManual"
      :titname="Manualtitname"
      :numM="Manualnode"
      :mark="Manualmark"
      :manvar="Manualvar"
      @sendStatus="isClose"
      :infoList="infoList"
    ></mflmanyManual>
    <MfZQFOZX
      v-if="MfZQFOZX"
      @sendStatus="isClose"
      :infoList="infoList"
      :MfZQFOZXCS1="MfZQFOZXCS1"
      :MfZQFOZXCS2="MfZQFOZXCS2"
      :MfZQFOZXCS3="MfZQFOZXCS3"
      :MfZQFOZXCS4="MfZQFOZXCS4"
    ></MfZQFOZX>

     <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="node"
      :node1="node1"
      :Lkname="Lkname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal"></inputVal>
  </div>
</template>
<script>
import Historical from "@/components/Historicaljrl";
import inputVal from "@/components/inputVal"; //输入框组件
import mflmanyManual from "@/components/MflmanyManual1.vue"; //多手操器
import MfZQFOZX from "@/views/MfBoiler/MFZQFO_ZX/index.vue";
export default {
  name: "MFL_MAC",
  components: {
    mflmanyManual,
    Historical,
    inputVal,
    MfZQFOZX,
  },
  props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
  },
  data: () => {
    return {
      chName: "",
      mflmanyManual: false,
      Manualmark: "",
      Manualvar: "",
      headerStyle: {
        "font-weight": "500",
        "font-size": "1.8vh",
        height: "1vh",
        borderColor: "#0f3747",
        background: "#205365",
        padding: "0px",
        border: "none",
        color: "#5ca4a6",
      },
      MfZQFOZXCS1: "",
      MfZQFOZXCS2: "",
      MfZQFOZXCS3: "",
      MfZQFOZXCS4: "",
      MfZQFOZX: "",
      isMshow: false,
      flag: 0,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      screenWidth: document.body.clientWidth,
      screeHeight: "",
      conHeight: "",
      leftHeight: "",
      leftcon: "",
      tabline: "",
      tabline1: "",
      tabstyle: "",
      tablist: "",
      tabdatawidth: "",
      tabdatawidth1: "",
      Aname: "",
      changValue: null,
      config: {
        data: [10],
        shape: "round",
        colors: ["#43ff44", "#43ff44"],
        waveOpacity: "1",
        waveNum: "50",
        formatter: "{value}",
      },
      rightHeight: "",
      spotArr: [],
      authInfo: [],
	  node1:''
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
  },
  computed: {},
  mounted() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }
        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    // 打开先控多窗口
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
			case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "mfkfirstcontol";
            break;
          case 3:
            pathname = "mfRfirstcontol";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    closeCompon() {
      this.$emit("sendStatus", "isPshow05", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
        case "MfZQFOZX":
          return (this.MfZQFOZX = val1);
        case "mflmanyManual":
          return (this.mflmanyManual = val1);
      }
    },

    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    toCompon(key, name, name2, name3, name4, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      switch (key) {
        case 0:
          return (
            (this.mflmanyManual = true),
            (this.Manualtitname = name),
            (this.Manualnode = name2),
            (this.Manualmark = name3),
            (this.Manualvar = name4)
          );
        case 1:
          return (
            (this.isFshow = true),
            (this.Firstcontrolname = name),
            (this.Firstcontrolnode = name2),
            (this.Firstcontroltitname = titname)
          );
        case 2:
          return (this.isHshow = true), (this.chName = name4),this.node=titname;
        case 13:
        //   console.log("whjjjjj");
          return (
            (this.MfZQFOZX = true),
            (this.MfZQFOZXCS1 = name),
            (this.MfZQFOZXCS2 = name2),
            (this.MfZQFOZXCS3 = name3),
            (this.MfZQFOZXCS4 = titname)
          );
      }
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.mflmac {
  width: 53vw;
  height: 67vh;
  //  background: #e9f7ff;
  background-image: url("~@/assets/images/jrl-param.png");
  background-size: 100% 100%;
  //  cursor:move;
  border-radius: 5px;

  .zhuangshi1 {
    width: 30vw;
    height: 10vh;
    background-color: #0ef7ff;
    opacity: 0.66;
  }

  .main-top {
    margin-left: 3.5vw;
    font-size: 0.9vw;
  }

  .left-span1 {
    width: 10vw;
    height: 30vh;
    // border: 1px saddlebrown solid;
  }

  .fl {
    float: left;
  }

  .fr {
    float: right;
  }

  .title-hang {
    height: 5vh;
    width: 53vw;
    font-family: MicrosoftYaHei;
    font-size: 0.9vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    // padding-top: 0.5vh;
    // margin-top:vh;
  }

  .title1 {
    width: 8vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #ffffff;
    margin-top: 1vh;
    margin-left: 1vw;
  }

  .title-hang {
    width: 100%;
    height: 6vh;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #0ef7ff;
  }

  .lable1 {
    font-family: PingFang-SC-Regular;
    font-size: 1.2vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2.5vh;
    letter-spacing: 0vh;
    color: #d5fffe;
    margin-left: 2.5vw;
    margin-top: 1vh;
  }

  .titlelable {
    width: 53vw;
    height: 4vh;
    font-family: PingFang-SC-Regular;
    font-size: 3vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0.3vw;
    color: #d5fffe;
    text-align: center;
    margin-top: 4vh;
  }

  .param11-header-icon {
    width: 2vw;
    height: 2vh;
    right: 1vw;
    margin-top: -5vh;
    cursor: pointer;
    margin-left: 50vw;
  }

  .bottonx {
    width: 1vw;
    height: 2vh;
    text-align: center;
    line-height: 2vh;
    border: 2px solid rgb(217, 243, 145);
    font-size: 0.9vw;
    // margin-right: 0.1vw;
    color: #fff;
    margin: 1.5vh 0 0 7.8vw;
    cursor: pointer;
  }

  .main {
    margin-top: 4vh;
    color: #8aeaff;
    font-size: 1vw;
  }

  .leftmain {
    width: 9vw;
    height: 40vh;
    margin: 0 0 0 1vw;
  }

  .leftmain-col {
    height: 3vh;
    line-height: 3vh;
    width: 9vw;
    background-color: #001b2860;
    border-radius: 0vw;
    border: solid 0vw #236f8d;
  }

  .leftmain-colx {
    height: 3vh;
    line-height: 3vh;
    width: 9vw;
    background-color: #063d58;
    border-radius: 0vw;
    border: solid 0vw #236f8d;
  }

  .leftmain-col1 {
    height: 3vh;
    line-height: 3vh;
    width: 10vw;
    background-color: #001b2860;
    border-radius: 0vw;
    border: solid 0vw #236f8d;
  }

  .centermain {
    width: 37vw;
    height: 25vh;
    //  background: #e9f7ff;
    background-image: url("~@/assets/images/MFL_ycfy.png");
    background-size: 100% 100%;
    //  cursor:move;
    border-radius: 5px;
    margin: 3vh 0 0 -5vw;
  }

  .widthd {
    width: 6vw;
    height: 3vh;
    line-height: 3vh;
    text-align: center;
    color: #8aeaff;
  }

  .widthd1 {
    width: 7vw;
    height: 3vh;
    line-height: 3vh;
    text-align: center;
    color: #8aeaff;
  }

  .valuex {
    width: 3vw;
    cursor: pointer;
    text-align: center;
    position: relative;
    z-index: 100;
    //
  }

  .colorl {
    color: #00ffb4;
  }

  .fybutton {
    width: 4vw;
    height: 3vh;
    line-height: 3vh;
    background-color: #22fff7;
    border-radius: 0vw;
    color: #000;
    text-align: center;
    margin: 2.3vh 0 0 2.9vw;
    z-index: 999;
    position: absolute;
    cursor: pointer;
  }

  .leftmain-jg {
    margin-top: 8vh;
  }

  .centmian-jg {
    margin: 1vh 0 0 9.2vw;
  }

  .centmian-jg1 {
    margin: 0 0 0 9.2vw;
  }

  .centmian-jg2 {
    margin: 1vh 0 0 0.2vw;
  }

  .centmian-jg3 {
    margin: 10.5vh 0 0 6vw;
  }

  .centmian-jg4 {
    margin: 0vh 0 0 6vw;
  }

  .centmian-jg5 {
    margin: -3vh 0 0 15vw;
  }

  .centmian-jg6 {
    margin: 1.5vh 0 0 3vw;
  }

  .centmian-jg7 {
    margin: 4vh 0 0 16vw;
  }

  .centmian-jg8 {
    margin: 6vh 0 0 3vw;
  }

  .centmian-jg9 {
    margin: 4vh 0 0 -7vw;
  }

  .centmian-jg10 {
    margin: 1.2vh 0 0 0.5vw;
  }

  .rightmain-jg1 {
    margin: 1vh 0 0 0;
  }

  .rightmain-jg2 {
    margin: 2vh 0 0 0;
  }

  .rightmain-jg3 {
    margin: 4.5vh 0 0 0;
  }
}
</style>
