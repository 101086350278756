var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mflindex"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.projectData.project)+" ")]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"leftmain fl"},[_c('div',{staticClass:"left-widthx1 color1 left-jg"},[_vm._v("正平衡")]),_c('div',{staticClass:"left-widthx1 color1 left-jg1"},[_vm._v("反平衡")]),_c('div',{staticClass:"left-widthx1 color1 left-jg2"},[_vm._v("汽压SP")]),_c('div',{staticClass:"left-widthx1 color1 left-jg3"},[_vm._v("汽压PV")]),_c('div',{staticClass:"left-widthx1 color1 left-jg4"},[_vm._v("负荷PV")]),_c('div',{staticClass:"left-widthx1 color1 left-jg4"},[_vm._v("分配负荷")]),_c('div',{staticClass:"left-widthx2 left-jg5",on:{"click":function($event){return _vm.toCompon(5, 'E', '5')}}},[_vm._v(" E磨 ")]),_c('div',[_c('div',{staticClass:"bottonx fl",style:({
              background:
                _vm.infoList.GMJ_XK5.RM && !_vm.infoList.GMJ_XK5.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.GMJ_XK5
                ? _vm.toDetail(
                    1,
                    'GMJ_XK5',
                    _vm.infoList.GMJ_XK5_node_string,
                    '',
                    'E给煤机先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.MFL_XK5.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.MFL_XK5
                ? _vm.toDetail(
                    1,
                    'MFL_XK5',
                    _vm.infoList.MFL_XK5_node_string,
                    '',
                    'E磨风量先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.CKT_XK5.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.CKT_XK5
                ? _vm.toDetail(
                    1,
                    'CKT_XK5',
                    _vm.infoList.CKT_XK5_node_string,
                    '',
                    'E磨出口温度先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background:
                'GMJ_MAN5' || 'MFL_MAN5' || 'CKT_MAN5' in _vm.infoList
                  ? _vm.infoList.GMJ_MAN5.RM == 1 ||
                    _vm.infoList.MFL_MAN5.RM == 1 ||
                    _vm.infoList.CKT_MAN5.RM == 1
                    ? '#2AFC30'
                    : 'red'
                  : 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANEM', 3, 'E', '5')}}},[_vm._v(" A ")])]),_c('div',{staticClass:"left-widthx2 left-jg6",on:{"click":function($event){return _vm.toCompon(5, 'D', '4', '油压', 'MYP')}}},[_vm._v(" D磨 ")]),_c('div',[_c('div',{staticClass:"bottonx fl",style:({
              background: !_vm.infoList.GMJ_XK4.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.GMJ_XK4
                ? _vm.toDetail(
                    1,
                    'GMJ_XK4',
                    _vm.infoList.GMJ_XK4_node_string,
                    '',
                    'D给煤机先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.MFL_XK4.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.MFL_XK4
                ? _vm.toDetail(
                    1,
                    'MFL_XK4',
                    _vm.infoList.MFL_XK4_node_string,
                    '',
                    'D磨风量先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.CKT_XK4.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.CKT_XK4
                ? _vm.toDetail(
                    1,
                    'CKT_XK4',
                    _vm.infoList.CKT_XK4_node_string,
                    '',
                    'D磨出口温度先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background:
                'GMJ_MAN4' || 'MFL_MAN4' || 'CKT_MAN4' in _vm.infoList
                  ? _vm.infoList.GMJ_MAN4.RM == 1 ||
                    _vm.infoList.MFL_MAN4.RM == 1 ||
                    _vm.infoList.CKT_MAN4.RM == 1
                    ? '#2AFC30'
                    : 'red'
                  : 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANDM', 3, 'D', '4')}}},[_vm._v(" A ")])]),_c('div',{staticClass:"left-widthx2 left-jg6",on:{"click":function($event){return _vm.toCompon(5, 'C', '3')}}},[_vm._v(" C磨 ")]),_c('div',[_c('div',{staticClass:"bottonx fl",style:({
              background: !_vm.infoList.GMJ_XK3.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.GMJ_XK3
                ? _vm.toDetail(
                    1,
                    'GMJ_XK3',
                    _vm.infoList.GMJ_XK3_node_string,
                    '',
                    'C给煤机先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.MFL_XK3.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.MFL_XK3
                ? _vm.toDetail(
                    1,
                    'MFL_XK3',
                    _vm.infoList.MFL_XK3_node_string,
                    '',
                    'C磨风量先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.CKT_XK3.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.CKT_XK3
                ? _vm.toDetail(
                    1,
                    'CKT_XK3',
                    _vm.infoList.CKT_XK3_node_string,
                    '',
                    'C磨出口温度先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background:
                'GMJ_MAN3' || 'MFL_MAN3' || 'CKT_MAN3' in _vm.infoList
                  ? _vm.infoList.GMJ_MAN3.RM == 1 ||
                    _vm.infoList.MFL_MAN3.RM == 1 ||
                    _vm.infoList.CKT_MAN3.RM == 1
                    ? '#2AFC30'
                    : 'red'
                  : 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANCM', 3, 'C', '3')}}},[_vm._v(" A ")])]),_c('div',{staticClass:"left-widthx2 left-jg6",on:{"click":function($event){return _vm.toCompon(5, 'B', '2')}}},[_vm._v(" B磨 ")]),_c('div',[_c('div',{staticClass:"bottonx fl",style:({
              background: !_vm.infoList.GMJ_XK2.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.GMJ_XK2
                ? _vm.toDetail(
                    1,
                    'GMJ_XK2',
                    _vm.infoList.GMJ_XK2_node_string,
                    '',
                    'B给煤机先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.MFL_XK2.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.MFL_XK2
                ? _vm.toDetail(
                    1,
                    'MFL_XK2',
                    _vm.infoList.MFL_XK2_node_string,
                    '',
                    'B磨风量先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.CKT_XK2.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.CKT_XK2
                ? _vm.toDetail(
                    1,
                    'CKT_XK2',
                    _vm.infoList.CKT_XK2_node_string,
                    '',
                    'B磨出口温度先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background:
                'GMJ_MAN2' || 'MFL_MAN2' || 'CKT_MAN2' in _vm.infoList
                  ? _vm.infoList.GMJ_MAN2.RM == 1 ||
                    _vm.infoList.MFL_MAN2.RM == 1 ||
                    _vm.infoList.CKT_MAN2.RM == 1
                    ? '#2AFC30'
                    : 'red'
                  : 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANBM', 3, 'B', '2')}}},[_vm._v(" A ")])]),_c('div',{staticClass:"left-widthx2 left-jg6",on:{"click":function($event){return _vm.toCompon(5, 'A', '1')}}},[_vm._v(" A磨 ")]),_c('div',[_c('div',{staticClass:"bottonx fl",style:({
              background: !_vm.infoList.GMJ_XK1.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.GMJ_XK1
                ? _vm.toDetail(
                    1,
                    'GMJ_XK1',
                    _vm.infoList.GMJ_XK1_node_string,
                    '',
                    'A给煤机先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.MFL_XK1.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.MFL_XK1
                ? _vm.toDetail(
                    1,
                    'MFL_XK1',
                    _vm.infoList.MFL_XK1_node_string,
                    '',
                    'A磨风量先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.CKT_XK1.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.CKT_XK1
                ? _vm.toDetail(
                    1,
                    'CKT_XK1',
                    _vm.infoList.CKT_XK1_node_string,
                    '',
                    'A磨出口温度先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background:
                'GMJ_MAN1' || 'MFL_MAN1' || 'CKT_MAN1' in _vm.infoList
                  ? _vm.infoList.GMJ_MAN1.RM == 1 ||
                    _vm.infoList.MFL_MAN1.RM == 1 ||
                    _vm.infoList.CKT_MAN1.RM == 1
                    ? '#2AFC30'
                    : 'red'
                  : 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANAM', 3, 'A', '1')}}},[_vm._v(" A ")])]),_c('div',{staticClass:"left-widthx2 left-jg6",staticStyle:{"margin-top":"5vh"},on:{"click":function($event){return _vm.toCompon(14)}}},[_vm._v(" 再热汽温 ")]),_c('div',[_c('div',{staticClass:"bottonx fl",style:({
              background: !_vm.infoList.ZQT_XK9.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.ZQT_XK9
                ? _vm.toDetail(
                    1,
                    'ZQT_XK9',
                    _vm.infoList.ZQT_XK9_node_string,
                    '',
                    '再热汽烟气挡板'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.ZQT_XK10.RM ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.ZQT_XK10
                ? _vm.toDetail(
                    1,
                    'ZQT_XK10',
                    _vm.infoList.ZQT_XK10_node_string,
                    '',
                    '再热汽流量控制'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background: !_vm.infoList.ZRQ_RSF1.TS ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.ZRQ_RSF1
                ? _vm.toDetail(
                    3,
                    'ZRQ_RSF1',
                    _vm.infoList.ZRQ_RSF1_node_string,
                    '',
                    '再热器流量补补偿'
                  )
                : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
              background:
                'ZQT_MAN5' || 'ZQT_MAN6' in _vm.infoList
                  ? _vm.infoList.ZQT_MAN5.RM == 1 || _vm.infoList.ZQT_MAN6.RM == 1
                    ? '#2AFC30'
                    : 'red'
                  : 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANZR', 2)}}},[_vm._v(" A ")])])]),_c('div',{staticClass:"fl maincenter"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"waterline"},[_c('div',{staticClass:"shuiweisp"},[_c('div',{staticClass:"fl widthx1 color1 col1jg"},[_vm._v("水位SP")]),_c('div',{staticClass:"fl valuex color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'QBW_SP',
                    _vm.infoList.QBW_SP_node_string,
                    'QBW_SP_BM',
                    '水位设定值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.QBW_SP,
                    'QBW_SP',
                    _vm.infoList.QBW_SP_node_string,
                    'QBW_SP_BM'
                  )}}},[_vm._v(" "+_vm._s("QBW_SP" in _vm.infoList ? _vm.infoList.QBW_SP : 0)+"mm ")])]),_c('div',{staticClass:"col1jg1"},[_c('div',{staticClass:"fl widthxx",staticStyle:{"margin-left":"0.8vw"},style:({
                  background: _vm.infoList.QBW_SEL == 1 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt(
                    '1',
                    '水位1',
                    'QBW_SEL',
                    _vm.infoList.QBW_SEL_node_string,
                    'true'
                  )}}},[_vm._v(" 水位1 ")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'QBW_A_PV',
                    _vm.infoList.QBW_A_PV_node_string,
                    'QBW_A_PV_BM',
                    '汽包水位过程值1'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.QBW_A_PV,
                    'QBW_A_PV',
                    _vm.infoList.QBW_A_PV_node_string,
                    'QBW_A_PV_BM'
                  )}}},[_vm._v(" "+_vm._s("QBW_A_PV" in _vm.infoList ? _vm.infoList.QBW_A_PV : 0)+"mm ")])]),_c('div',{staticClass:"col1jg2"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'RSXLSS',
                    _vm.infoList.RSXLSS_node_string,
                    'RSXLSS_BM',
                    '燃烧效率'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.RSXLSS,
                    'RSXLSS',
                    _vm.infoList.RSXLSS_node_string,
                    'RSXLSS_BM'
                  )}}},[_vm._v(" "+_vm._s("RSXLSS" in _vm.infoList ? _vm.infoList.RSXLSS : 0)+" ")]),_c('div',{staticClass:"fl widthxx",staticStyle:{"margin-left":"0.8vw"},style:({
                  background: !_vm.infoList.QBW_SEL == 2 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt(
                    '2',
                    '水位2',
                    'QBW_SEL',
                    _vm.infoList.QBW_SEL_node_string,
                    'true'
                  )}}},[_vm._v(" 水位2 ")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'QBW_B_PV',
                    _vm.infoList.QBW_B_PV_node_string,
                    'QBW_B_PV_BM',
                    '汽包水位过程值2'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.QBW_B_PV,
                    'QBW_B_PV',
                    _vm.infoList.QBW_B_PV_node_string,
                    'QBW_B_PV_BM'
                  )}}},[_vm._v(" "+_vm._s("QBW_B_PV" in _vm.infoList ? _vm.infoList.QBW_B_PV : 0)+"mm ")])]),_c('div',{staticClass:"col1jg2"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'q1',
                    _vm.infoList.q1_node_string,
                    'q1_BM',
                    '锅炉效率'
                  )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.q1, 'q1', _vm.infoList.q1_node_string, 'q1_BM')}}},[_vm._v(" "+_vm._s("q1" in _vm.infoList ? _vm.infoList.q1 : 0)+" ")]),_c('div',{staticClass:"fl widthxx",staticStyle:{"margin-left":"0.8vw"},style:({
                  background: !_vm.infoList.QBW_SEL == 3 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt(
                    '3',
                    '水位3',
                    'QBW_SEL',
                    _vm.infoList.QBW_SEL_node_string,
                    'true'
                  )}}},[_vm._v(" 水位3 ")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'QBW_C_PV',
                    _vm.infoList.QBW_C_PV_node_string,
                    'QBW_C_PV_BM',
                    '汽包水位过程值3'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.QBW_C_PV,
                    'QBW_C_PV',
                    _vm.infoList.QBW_C_PV_node_string,
                    'QBW_C_PV_BM'
                  )}}},[_vm._v(" "+_vm._s("QBW_C_PV" in _vm.infoList ? _vm.infoList.QBW_C_PV : 0)+"mm ")])])]),_c('div',{staticClass:"control-model control-jg4"},[_c('div',{staticStyle:{"height":"3vh","line-height":"3vh"}},[_c('div',{staticClass:"control-col1 fl color1",on:{"click":function($event){return _vm.toCompon(8)}}},[_vm._v(" 控制模式 ")]),_c('div',{staticClass:"control-col1x fl color3",style:({
                  background: !_vm.infoList.MGM_SE ? '#2AFC30' : 'red',
                })},[_vm._v(" "+_vm._s("MGM_SE" in _vm.infoList ? _vm.infoList.MGM_SEL ? "母管模式" : "单炉模式" : '单炉模式')+" ")])]),_c('div',{staticClass:"control-col2"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'ZQP_SP',
                    _vm.infoList.ZQP_SP_node_string,
                    'ZQP_SP_BM',
                    '主汽压力控制点'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.ZQP_SP,
                    'ZQP_SP',
                    _vm.infoList.ZQP_SP_node_string,
                    'ZQP_SP_BM'
                  )}}},[_vm._v(" "+_vm._s("ZQP_SP" in _vm.infoList ? _vm.infoList.ZQP_SP : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("MPa")]),_c('div',{staticClass:"bottonx fl control-jg",style:({
                  background: !_vm.infoList.ZQP_QK1.TS ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.ZQP_QK1
                    ? _vm.toDetail(
                        2,
                        'ZQP_QK1',
                        _vm.infoList.ZQP_QK1_node_string,
                        '',
                        '负荷前馈给煤'
                      )
                    : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"bottonx fl control-jg1",style:({
                  background: !_vm.infoList.ZQP_QK2.TS ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.ZQP_QK2
                    ? _vm.toDetail(
                        2,
                        'ZQP_QK2',
                        _vm.infoList.ZQP_QK2_node_string,
                        '',
                        '管网负荷前馈'
                      )
                    : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"bottonx fl control-jg1",style:({
                  background: !_vm.infoList.ZQP_QK3.TS ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.ZQP_QK3
                    ? _vm.toDetail(
                        2,
                        'ZQP_QK3',
                        _vm.infoList.ZQP_QK3_node_string,
                        '',
                        '燃料气前馈'
                      )
                    : ''}}},[_vm._v(" Q ")])]),_c('div',{staticClass:"control-col2"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'ZQP_PV',
                    _vm.infoList.ZQP_PV_node_string,
                    'ZQP_PV_BM',
                    '主汽压力测量值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.ZQP_PV,
                    'ZQP_PV',
                    _vm.infoList.ZQP_PV_node_string,
                    'ZQP_PV_BM'
                  )}}},[_vm._v(" "+_vm._s("ZQP_PV" in _vm.infoList ? _vm.infoList.ZQP_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("MPa")]),_c('div',{staticClass:"bottonx fl control-jg",style:({
                  background: !_vm.infoList.ZQP_XK2.RM ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.ZQP_XK2
                    ? _vm.toDetail(
                        1,
                        'ZQP_XK2',
                        _vm.infoList.ZQP_XK2_node_string,
                        '',
                        '母管速率动态调节'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl control-jg1",style:({
                  background: !_vm.infoList.ZQP_XK1.RM ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.ZQP_XK1
                    ? _vm.toDetail(
                        1,
                        'ZQP_XK1',
                        _vm.infoList.ZQP_XK1_node_string,
                        '',
                        '主汽压力静态调节'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl control-jg1",style:({
                  background: !_vm.infoList.GMJ_XK1.RM ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.ZQF_XK1
                    ? _vm.toDetail(
                        1,
                        'ZQF_XK1',
                        _vm.infoList.GMJ_XK1_node_string,
                        '',
                        '主汽流量先控调节'
                      )
                    : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"control-col2"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'ZQF_PV',
                    _vm.infoList.ZQF_PV_node_string,
                    'ZQF_PV_BM',
                    '蒸汽流量'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.ZQF_PV,
                    'ZQF_PV',
                    _vm.infoList.ZQF_PV_node_string,
                    'ZQF_PV_BM'
                  )}}},[_vm._v(" "+_vm._s("ZQF_PV" in _vm.infoList ? _vm.infoList.ZQF_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")]),_c('div',{staticClass:"fl valuex control-jg2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'ZQP_MV',
                    _vm.infoList.ZQP_MV_node_string,
                    'ZQP_MV_BM',
                    '给煤层操指令'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.ZQP_MV,
                    'ZQP_MV',
                    _vm.infoList.ZQP_MV_node_string,
                    'ZQF_PV_BM'
                  )}}},[_vm._v(" "+_vm._s("ZQP_MV" in _vm.infoList ? _vm.infoList.ZQP_MV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"control-col2"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'MGF_SP',
                    _vm.infoList.MGF_SP_node_string,
                    'MGF_SP_BM',
                    '母管分配负荷量'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MGF_SP,
                    'MGF_SP',
                    _vm.infoList.MGF_SP_node_string,
                    'MGF_SP_BM'
                  )}}},[_vm._v(" "+_vm._s("MGF_SP" in _vm.infoList ? _vm.infoList.MGF_SP : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")]),_c('div',{staticClass:"fl valuex control-jg2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'MGP_SL',
                    _vm.infoList.MGP_SL_node_string,
                    'MGP_SL_SM',
                    '母管压力速率变化'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MGP_SL,
                    'MGP_SL',
                    _vm.infoList.MGP_SL_node_string,
                    'MGP_SL_SM'
                  )}}},[_vm._v(" "+_vm._s("MGP_SL" in _vm.infoList ? _vm.infoList.MGP_SL : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])])]),_c('div',{staticClass:"molgd"},[_c('div',{staticClass:"molgd-col1"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'GML_E_PV',
                    _vm.infoList.GML_E_PV_node_string,
                    'GML_E_PV_BM',
                    '5#给煤量过程值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.GML_E_PV,
                    'GML_E_PV',
                    _vm.infoList.GML_E_PV_node_string,
                    'GML_E_PV_BM'
                  )}}},[_vm._v(" "+_vm._s("GML_E_PV" in _vm.infoList ? _vm.infoList.GML_E_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"molgd-col1 molgd-jg"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'GML_D_PV',
                    _vm.infoList.GML_D_PV_node_string,
                    'GML_D_PV_BM',
                    '4#给煤量过程值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.GML_D_PV,
                    'GML_D_PV',
                    _vm.infoList.GML_D_PV_node_string,
                    'GML_D_PV_BM'
                  )}}},[_vm._v(" "+_vm._s("GML_D_PV" in _vm.infoList ? _vm.infoList.GML_D_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"molgd-col1 molgd-jg"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'GML_C_PV',
                    _vm.infoList.GML_C_PV_node_string,
                    'GML_C_PV_BM',
                    '3#给煤量过程值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.GML_C_PV,
                    'GML_C_PV',
                    _vm.infoList.GML_C_PV_node_string,
                    'GML_C_PV_BM'
                  )}}},[_vm._v(" "+_vm._s("GML_C_PV" in _vm.infoList ? _vm.infoList.GML_C_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"molgd-col1 molgd-jg"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'GML_B_PV',
                    _vm.infoList.GML_B_PV_node_string,
                    'GML_B_PV_BM',
                    '2#给煤量过程值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.GML_B_PV,
                    'GML_B_PV',
                    _vm.infoList.GML_B_PV_node_string,
                    'GML_B_PV_BM'
                  )}}},[_vm._v(" "+_vm._s("GML_B_PV" in _vm.infoList ? _vm.infoList.GML_B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"molgd-col1 molgd-jg"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'GML_A_PV',
                    _vm.infoList.GML_A_PV_node_string,
                    'GML_A_PV_BM',
                    '1#给煤量过程值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.GML_A_PV,
                    'GML_A_PV',
                    _vm.infoList.GML_A_PV_node_string,
                    'GML_A_PV_BM'
                  )}}},[_vm._v(" "+_vm._s("GML_A_PV" in _vm.infoList ? _vm.infoList.GML_A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])])])]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"qbmain"},[_c('div',{staticClass:"fl qb-col2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'QBW_PV',
                  _vm.infoList.QBW_PV_node_string,
                  'QBW_PV_BM',
                  '水位过程值'
                )}}},[_c('dv-water-level-pond',{staticStyle:{"width":"110px","height":"110px"},attrs:{"config":_vm.config}})],1)]),_c('div',{staticClass:"lutang"},[_c('div',{staticClass:"lutang-col1"},[_c('div',{staticClass:"fl widthx1"},[_vm._v("负压 SP")]),_c('div',{staticClass:"fl valuex color2 lutang-jg2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'LTP_SP',
                    _vm.infoList.LTP_SP_node_string,
                    'LTP_SP_BM',
                    '炉膛压力设定值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.LTP_SP,
                    'LTP_SP',
                    _vm.infoList.LTP_SP_node_string,
                    'LTP_SP_BM'
                  )}}},[_vm._v(" "+_vm._s("LTP_SP" in _vm.infoList ? _vm.infoList.LTP_SP : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("Pa")])]),_c('div',{staticClass:"lutang-col1 lutang-jg1"},[_c('div',{staticClass:"fl valuex fu-color",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'LTP_A_PV',
                    _vm.infoList.LTP_A_PV_node_string,
                    'LTP_A_PV_BM',
                    '炉膛压力过程值1'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.LTP_A_PV,
                    'LTP_A_PV',
                    _vm.infoList.LTP_A_PV_node_string,
                    'LTP_A_PV_BM'
                  )}}},[_vm._v(" "+_vm._s("LTP_A_PV" in _vm.infoList ? _vm.infoList.LTP_A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei fu-color"},[_vm._v("Pa")]),_c('div',{staticClass:"fl valuex fu-color lutang-jg",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'LTP_B_PV',
                    _vm.infoList.LTP_B_PV_node_string,
                    'LTP_B_PV_BM',
                    '炉膛压力过程值2'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.LTP_B_PV,
                    'LTP_B_PV',
                    _vm.infoList.LTP_B_PV_node_string,
                    'LTP_B_PV_BM'
                  )}}},[_vm._v(" "+_vm._s("LTP_B_PV" in _vm.infoList ? _vm.infoList.LTP_B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei fu-color"},[_vm._v("Pa")])]),_c('div',{staticClass:"lutang-col1 lutang-jg1"},[_c('div',{staticClass:"fl widthx1",style:({
                  background: _vm.infoList.LTP_SEL == 1 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt(
                    '1',
                    '负压1',
                    'LTP_SEL',
                    _vm.infoList.LTP_SEL_node_string,
                    'true'
                  )}}},[_vm._v(" 负压1 ")]),_c('div',{staticClass:"fl widthx1 lutang-jg",style:({
                  background: _vm.infoList.LTP_SEL == 2 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt(
                    '2',
                    '负压2',
                    'LTP_SEL',
                    _vm.infoList.LTP_SEL_node_string,
                    'true'
                  )}}},[_vm._v(" 负压2 ")])]),_c('div',{staticClass:"lutang-col1 lutang-jg1"},[_c('div',{staticClass:"fl valuex fu-color",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'LTP_C_PV',
                    _vm.infoList.LTP_C_PV_node_string,
                    'LTP_C_PV_BM',
                    '炉膛压力过程值3'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.LTP_C_PV,
                    'LTP_C_PV',
                    _vm.infoList.LTP_C_PV_node_string,
                    'LTP_C_PV_BM'
                  )}}},[_vm._v(" "+_vm._s("LTP_C_PV" in _vm.infoList ? _vm.infoList.LTP_C_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei fu-color"},[_vm._v("Pa")]),_c('div',{staticClass:"fl valuex fu-color lutang-jg",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'LTP_D_PV',
                    _vm.infoList.LTP_D_PV_node_string,
                    'LTP_D_PV_BM',
                    '炉膛压力过程值4'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.LTP_D_PV,
                    'LTP_D_PV',
                    _vm.infoList.LTP_D_PV_node_string,
                    'LTP_D_PV_BM'
                  )}}},[_vm._v(" "+_vm._s("LTP_D_PV" in _vm.infoList ? _vm.infoList.LTP_D_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei fu-color"},[_vm._v("Pa")])]),_c('div',{staticClass:"lutang-col1 lutang-jg1"},[_c('div',{staticClass:"fl widthx1",style:({
                  background: _vm.infoList.LTP_SEL == 3 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt(
                    '3',
                    '负压3',
                    'LTP_SEL',
                    _vm.infoList.LTP_SEL_node_string,
                    'true'
                  )}}},[_vm._v(" 负压3 ")]),_c('div',{staticClass:"fl widthx1 lutang-jg",style:({
                  background: _vm.infoList.LTP_SEL == 4 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt(
                    '4',
                    '负压4',
                    'LTP_SEL',
                    _vm.infoList.LTP_SEL_node_string,
                    'true'
                  )}}},[_vm._v(" 负压4 ")])])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"glqmain"},[_c('div',{staticClass:"glqtop flex"},[_c('div',{staticClass:"pingbi glqtop-jg"},[_c('div',{staticStyle:{"height":"2.5vh"}},[_c('div',{staticClass:"fl valuex lutang-jg2",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'PGCKT_A_PV',
                        _vm.infoList.PGCKT_A_PV_node_string,
                        'PGCKT_A_PV_BM',
                        '屏过出口烟气温度左'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.PGCKT_A_PV,
                        'PGCKT_A_PV',
                        _vm.infoList.PGCKT_A_PV_node_string,
                        'PGCKT_A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("PGCKT_A_PV" in _vm.infoList ? _vm.infoList.PGCKT_A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',[_c('div',{staticClass:"fl valuex lutang-jg2",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'PGCKT_B_PV',
                        _vm.infoList.PGCKT_B_PV_node_string,
                        'PGCKT_B_PV_BM',
                        '屏过出口烟气温度右'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.PGCKT_B_PV,
                        'PGCKT_B_PV',
                        _vm.infoList.PGCKT_B_PV_node_string,
                        'PGCKT_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("PGCKT_B_PV" in _vm.infoList ? _vm.infoList.PGCKT_B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])])]),_c('div',{staticClass:"gaowen glqtop-jg1"},[_c('div',{staticStyle:{"height":"2.5vh"}},[_c('div',{staticClass:"fl valuex lutang-jg2",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'GGRQT_A_PV',
                        _vm.infoList.GGRQT_A_PV_node_string,
                        'GGRQT_A_PV_BM',
                        '高过出口烟气温度左'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.GGRQT_A_PV,
                        'GGRQT_A_PV',
                        _vm.infoList.GGRQT_A_PV_node_string,
                        'GGRQT_A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("GGRQT_A_PV" in _vm.infoList ? _vm.infoList.GGRQT_A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',{staticClass:"heighthx"},[_c('div',{staticClass:"fl valuex lutang-jg2",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'GGRQT_B_PV',
                        _vm.infoList.GGRQT_B_PV_node_string,
                        'GGRQT_B_PV_BM',
                        '高过出口烟气温度右'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.GGRQT_B_PV,
                        'GGRQT_B_PV',
                        _vm.infoList.GGRQT_B_PV_node_string,
                        'GGRQT_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("GGRQT_B_PV" in _vm.infoList ? _vm.infoList.GGRQT_B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])])]),_c('div',{staticClass:"diwen glqtop-jg2"},[_c('div',{staticStyle:{"height":"2.5vh"}},[_c('div',{staticClass:"fl valuex lutang-jg2",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'DGRQT_A_PV',
                        _vm.infoList.DGRQT_A_PV_node_string,
                        'DGRQT_A_PV_BM',
                        '低过出口烟气温度左'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.GGRQT_A_PV,
                        'DGRQT_A_PV',
                        _vm.infoList.DGRQT_A_PV_node_string,
                        'DGRQT_A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("DGRQT_A_PV" in _vm.infoList ? _vm.infoList.DGRQT_A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',[_c('div',{staticClass:"fl valuex lutang-jg2",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'DGRQT_B_PV',
                        _vm.infoList.DGRQT_B_PV_node_string,
                        'DGRQT_B_PV_BM',
                        '低过出口烟气温度右'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.DGRQT_B_PV,
                        'DGRQT_B_PV',
                        _vm.infoList.DGRQT_B_PV_node_string,
                        'DGRQT_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("DGRQT_B_PV" in _vm.infoList ? _vm.infoList.DGRQT_B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])])])]),_c('div',{staticClass:"yangliang"},[_c('div',{staticStyle:{"height":"3vh"}},[_c('div',{staticClass:"fl widthx1"},[_vm._v("氧量SP")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YQO_SP',
                      _vm.infoList.YQO_SP_node_string,
                      'YQO_SP_BM',
                      '烟气氧量设定值'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.YQO_SP,
                      'YQO_SP',
                      _vm.infoList.YQO_SP_node_string,
                      'YQO_SP_BM'
                    )}}},[_vm._v(" "+_vm._s("YQO_SP" in _vm.infoList ? _vm.infoList.YQO_SP : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")])]),_c('div',{staticStyle:{"height":"3vh"}},[_c('div',{staticClass:"fl widthx1",staticStyle:{"cursor":"pointer"}},[_vm._v("氧量1")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YQO_A_PV',
                      _vm.infoList.YQO_A_PV_node_string,
                      'YQO_A_PV_BM',
                      '烟气氧量过程值1'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.YQO_A_PV,
                      'YQO_A_PV',
                      _vm.infoList.YQO_A_PV_node_string,
                      'YQO_A_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("YQO_A_PV" in _vm.infoList ? _vm.infoList.YQO_A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")])]),_c('div',{staticStyle:{"height":"3vh"}},[_c('div',{staticClass:"fl widthx1",staticStyle:{"cursor":"pointer"}},[_vm._v("氧量2")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YQO_B_PV',
                      _vm.infoList.YQO_B_PV_node_string,
                      'YQO_B_PV_BM',
                      '烟气氧量过程值2'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.YQO_B_PV,
                      'YQO_B_PV',
                      _vm.infoList.YQO_B_PV_node_string,
                      'YQO_B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("YQO_B_PV" in _vm.infoList ? _vm.infoList.YQO_B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")])])]),_c('div',{staticClass:"lutangall flex"},[_c('div',{staticClass:"ltbottom"},[_c('div',{staticClass:"ltbottom-row1"},[_c('div',{staticClass:"fl valuex12 fu-color",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'RQYL1_PV',
                        _vm.infoList.RQYL1_PV_node_string,
                        'RQYL1_PV_BM',
                        '阀前压力'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.RQYL1_PV,
                        'RQYL1_PV',
                        _vm.infoList.RQYL1_PV_node_string,
                        'RQYL1_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("RQYL1_PV" in _vm.infoList ? _vm.infoList.RQYL1_PV : 0)+" ")])]),_c('div',{staticClass:"ltbottom-row2"},[_c('div',{staticClass:"fl valuex12 fu-color",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'RQYL2_PV',
                        _vm.infoList.RQYL2_PV_node_string,
                        'RQYL2_PV_BM',
                        '阀后压力'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.RQYL2_PV,
                        'RQYL2_PV',
                        _vm.infoList.RQYL2_PV_node_string,
                        'RQYL2_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("RQYL2_PV" in _vm.infoList ? _vm.infoList.RQYL2_PV : 0)+" ")])]),_c('div',{staticClass:"ltbottom-row3"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("燃气流量")]),_c('div',{staticClass:"fl valuex12 fu-color",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'RQLL_PV',
                        _vm.infoList.RQLL_PV_node_string,
                        'RQLL_PV_BM',
                        '燃气流量'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.RQLL_PV,
                        'RQLL_PV',
                        _vm.infoList.RQLL_PV_node_string,
                        'RQLL_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("RQLL_PV" in _vm.infoList ? _vm.infoList.RQLL_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("Nm³/h")])]),_c('div',{staticClass:"ltbottom-row3 ltbottm-jg"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("总给煤量SP")]),_c('div',{staticClass:"fl valuex12 color2",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'GML_SP',
                        _vm.infoList.GML_SP_node_string,
                        'GML_SP_BM',
                        '总给煤指令'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.GML_SP,
                        'GML_SP',
                        _vm.infoList.GML_SP_node_string,
                        'GML_SP_BM'
                      )}}},[_vm._v(" "+_vm._s("GML_SP" in _vm.infoList ? _vm.infoList.GML_SP : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"ltbottom-row3"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("总给煤量PV")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'GML_PV',
                        _vm.infoList.GML_PV_node_string,
                        'GML_PV_BM',
                        '总给煤量'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.GML_PV,
                        'GML_PV',
                        _vm.infoList.GML_PV_node_string,
                        'GML_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("GML_PV" in _vm.infoList ? _vm.infoList.GML_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"ltbottom-row3"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("总风量SP")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SFF_SP',
                        _vm.infoList.SFF_SP_node_string,
                        'SFF_SP_BM',
                        '送风流量设定值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.SFF_SP,
                        'SFF_SP',
                        _vm.infoList.SFF_SP_node_string,
                        'SFF_SP_BM'
                      )}}},[_vm._v(" "+_vm._s("SFF_SP" in _vm.infoList ? _vm.infoList.SFF_SP : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"ltbottom-row3"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("总风量PV")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SFF_PV',
                        _vm.infoList.SFF_PV_node_string,
                        'SFF_PV_BM',
                        '送风量过程值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.SFF_PV,
                        'SFF_PV',
                        _vm.infoList.SFF_PV_node_string,
                        'SFF_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("SFF_PV" in _vm.infoList ? _vm.infoList.SFF_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',[_c('div',{staticClass:"ltbottom-row4 fl"},[_c('div',{staticClass:"bottonx fl ltbottm-jg2",style:({
                        background:
                          'ECF_MAN4' || 'ECF_MAN5' in _vm.infoList
                            ? _vm.infoList.ECF_MAN4.RM == 1 ||
                              _vm.infoList.ECF_MAN5.RM == 1
                              ? '#2AFC30'
                              : 'red'
                            : 'red',
                      }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANECFS', 2)}}},[_vm._v(" A ")]),_c('div',{staticClass:"fl widthx2"},[_vm._v("一次风压SP")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'YCF_A_SP',
                          _vm.infoList.YCF_A_SP_node_string,
                          'YCF_A_SP_BM',
                          '1#一次风设定值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.YCF_A_SP,
                          'SFF_PV',
                          _vm.infoList.YCF_A_SP_node_string,
                          'YCF_A_SP_BM'
                        )}}},[_vm._v(" "+_vm._s("YCF_A_SP" in _vm.infoList ? _vm.infoList.YCF_A_SP : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("KPa")])])]),_c('div',{staticClass:"ltbottom-row3 ltbottm-jg3"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("再热温度SP")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'ZRQT_A_SP',
                        _vm.infoList.ZRQT_A_SP_node_string,
                        'ZRQT_A_SP_BM',
                        '甲再热汽温设定值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.ZRQT_A_SP,
                        'ZRQT_A_SP',
                        _vm.infoList.ZRQT_A_SP_node_string,
                        'ZRQT_A_SP_BM'
                      )}}},[_vm._v(" "+_vm._s("ZRQT_A_SP" in _vm.infoList ? _vm.infoList.ZRQT_A_SP : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',{staticClass:"ltbottom-row3"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("再热温度PV")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'ZRQT_A_PV',
                        _vm.infoList.ZRQT_A_PV_node_string,
                        'ZRQT_A_PV_BM',
                        '甲再热汽温过程值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.ZRQT_A_PV,
                        'ZRQT_A_PV',
                        _vm.infoList.ZRQT_A_PV_node_string,
                        'ZRQT_A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("ZRQT_A_PV" in _vm.infoList ? _vm.infoList.ZRQT_A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',{staticClass:"ltbottom-row3"},[_c('div',{staticClass:"fl widthx2"},[_vm._v("再热流量SP")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'ZRQT_B_SPCL',
                        _vm.infoList.ZRQT_B_SPCL_node_string,
                        'ZRQT_B_SPCL_BM',
                        '再热器流量计算设定值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.ZRQT_B_SPCL,
                        'ZRQT_B_SPCL',
                        _vm.infoList.ZRQT_B_SPCL_node_string,
                        'ZRQT_B_SPCL_BM'
                      )}}},[_vm._v(" "+_vm._s("ZRQT_B_SPCL" in _vm.infoList ? _vm.infoList.ZRQT_B_SPCL : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',[_c('div',{staticClass:"ltbottom-row4 fl"},[_c('div',{staticClass:"bottonx fl ltbottm-jg2",style:({
                        background:
                          'ECF_MAN1' || 'ECF_MAN2' || 'ECF_MAN3' in _vm.infoList
                            ? _vm.infoList.ECF_MAN1.RM == 1 ||
                              _vm.infoList.ECF_MAN2.RM == 1 ||
                              _vm.infoList.ECF_MAN3.RM == 1
                              ? '#2AFC30'
                              : 'red'
                            : 'red',
                      }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANECFX', 3)}}},[_vm._v(" A ")]),_c('div',{staticClass:"fl widthx2"},[_vm._v("再热流量PV")]),_c('div',{staticClass:"fl valuex12",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'ZRQT_B_PV',
                          _vm.infoList.ZRQT_B_PV_node_string,
                          'ZRQT_B_PV_BM',
                          '乙再热汽温过程值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.ZRQT_B_PV,
                          'ZRQT_B_PV',
                          _vm.infoList.ZRQT_B_PV_node_string,
                          'ZRQT_B_PV_BM'
                        )}}},[_vm._v(" "+_vm._s("ZRQT_B_PV" in _vm.infoList ? _vm.infoList.ZRQT_B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])])])]),_c('div',{staticClass:"shengmq"},[_c('div',{staticClass:"shengmq-row1"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SMQT_1A_PV',
                        _vm.infoList.SMQT_1A_PV_node_string,
                        'SMQT_1A_PV_BM',
                        '一级省煤器烟气温度左'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.SMQT_1A_PV,
                        'SMQT_1A_PV',
                        _vm.infoList.SMQT_1A_PV_node_string,
                        'SMQT_1A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("SMQT_1A_PV" in _vm.infoList ? _vm.infoList.SMQT_1A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex shengmq-jg",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SMQT_1B_PV',
                        _vm.infoList.SMQT_1B_PV_node_string,
                        'SMQT_1B_PV_BM',
                        '一级省煤器烟气温度右'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.SMQT_1B_PV,
                        'SMQT_1B_PV',
                        _vm.infoList.SMQT_1B_PV_node_string,
                        'SMQT_1B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("SMQT_1B_PV" in _vm.infoList ? _vm.infoList.SMQT_1B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',{staticClass:"shengmq-row1 shengmq-jg1"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SMQT_2A_PV',
                        _vm.infoList.SMQT_2A_PV_node_string,
                        'SMQT_2A_PV_BM',
                        '二级省煤器烟气温度左'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.SMQT_2A_PV,
                        'SMQT_2A_PV',
                        _vm.infoList.SMQT_2A_PV_node_string,
                        'SMQT_2A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("SMQT_2A_PV" in _vm.infoList ? _vm.infoList.SMQT_2A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex shengmq-jg",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SMQT_2B_PV',
                        _vm.infoList.SMQT_2B_PV_node_string,
                        'SMQT_2B_PV_BM',
                        '二级省煤器烟气温度右'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.SMQT_2B_PV,
                        'SMQT_2B_PV',
                        _vm.infoList.SMQT_2B_PV_node_string,
                        'SMQT_2B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("SMQT_2B_PV" in _vm.infoList ? _vm.infoList.SMQT_2B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',{staticClass:"shengmq-row1 shengmq-jg1"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'YRQT_1A_PV',
                        _vm.infoList.YRQT_1A_PV_node_string,
                        'YRQT_1A_PV_BM',
                        '一级预热器烟气温度左'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.YRQT_1A_PV,
                        'YRQT_1A_PV',
                        _vm.infoList.YRQT_1A_PV_node_string,
                        'YRQT_1A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("YRQT_1A_PV" in _vm.infoList ? _vm.infoList.YRQT_1A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex shengmq-jg",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'YRQT_1B_PV',
                        _vm.infoList.YRQT_1B_PV_node_string,
                        'YRQT_1B_PV_BM',
                        '一级预热器烟气温度右'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.YRQT_1B_PV,
                        'YRQT_1B_PV',
                        _vm.infoList.YRQT_1B_PV_node_string,
                        'YRQT_1B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("YRQT_1B_PV" in _vm.infoList ? _vm.infoList.YRQT_1B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',{staticClass:"shengmq-row1 shengmq-jg2"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'PYT_A_PV',
                        _vm.infoList.PYT_A_PV_node_string,
                        'PYT_A_PV_BM',
                        '排烟温度左'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.PYT_A_PV,
                        'PYT_A_PV',
                        _vm.infoList.PYT_A_PV_node_string,
                        'PYT_A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("PYT_A_PV" in _vm.infoList ? _vm.infoList.PYT_A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex shengmq-jg",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'PYT_B_PV',
                        _vm.infoList.PYT_B_PV_node_string,
                        'PYT_B_PV_BM',
                        '排烟温度右'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.PYT_B_PV,
                        'PYT_B_PV',
                        _vm.infoList.PYT_B_PV_node_string,
                        'PYT_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("PYT_B_PV" in _vm.infoList ? _vm.infoList.PYT_B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])])])])]),_c('div',{staticClass:"rightmain"},[_c('div',{staticClass:"geishui"},[_c('div',{staticClass:"rightmain-row1"},[_c('div',{staticClass:"rightmain-row1-col color1 rightmain-jg fl"},[_vm._v(" 减温后 ")]),_c('div',{staticClass:"rightmain-row1-col color1 rightmain-jg fl"},[_vm._v(" 开度 ")]),_c('div',{staticClass:"rightmain-row1-col color1 rightmain-jg fl"},[_vm._v(" 减温前 ")]),_c('div',{staticClass:"fl valuex rightmain-jg",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_2A_PV',
                      _vm.infoList.ZQT_2A_PV_node_string,
                      'ZQT_2A_PV_BM',
                      '甲主汽温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.ZQT_2A_PV,
                      'ZQT_2A_PV',
                      _vm.infoList.ZQT_2A_PV_node_string,
                      'ZQT_2A_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("ZQT_2A_PV" in _vm.infoList ? _vm.infoList.ZQT_2A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei rightmain-jg"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex rightmain-jg",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_2B_PV',
                      _vm.infoList.ZQT_2B_PV_node_string,
                      'ZQT_2B_PV_BM',
                      '乙主汽温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.ZQT_2B_PV,
                      'ZQT_2B_PV',
                      _vm.infoList.ZQT_2B_PV_node_string,
                      'ZQT_2B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("ZQT_2B_PV" in _vm.infoList ? _vm.infoList.ZQT_2B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei rightmain-jg"},[_vm._v("℃")])]),_c('div',{staticClass:"rightmain-row1 rightmain-jg2 fl"},[_c('div',{staticClass:"Kclick",on:{"click":function($event){return _vm.toCompon(7)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWHT_2A_PV',
                      _vm.infoList.JWHT_2A_PV_node_string,
                      'JWHT_2A_PV_BM',
                      '甲二级减温后温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.JWHT_2A_PV,
                      'JWHT_2A_PV',
                      _vm.infoList.JWHT_2A_PV_node_string,
                      'JWHT_2A_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("JWHT_2A_PV" in _vm.infoList ? _vm.infoList.JWHT_2A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex rightmain-jg2",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_2A_FV',
                      _vm.infoList.ZQT_2A_FV_node_string,
                      'ZQT_2A_FV_BM',
                      '甲二级减温阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.ZQT_2A_FV,
                      'ZQT_2A_FV',
                      _vm.infoList.ZQT_2A_FV_node_string,
                      'ZQT_2A_FV_BM'
                    )}}},[_vm._v(" "+_vm._s("ZQT_2A_FV" in _vm.infoList ? _vm.infoList.ZQT_2A_FV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")]),_c('div',{staticClass:"fl valuex rightmain-jg3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWQT_2A_PV',
                      _vm.infoList.JWQT_2A_PV_node_string,
                      'JWQT_2A_PV_BM',
                      '甲二级减温前温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.JWQT_2A_PV,
                      'JWQT_2A_PV',
                      _vm.infoList.JWQT_2A_PV_node_string,
                      'ZQT_2A_FV_BM'
                    )}}},[_vm._v(" "+_vm._s("JWQT_2A_PV" in _vm.infoList ? _vm.infoList.JWQT_2A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl widthx3 color1 fl"},[_vm._v("二级")]),_c('div',{staticClass:"bottonx fl",style:({
                    background: !_vm.infoList.ZQT_XK5.RM ? '#2AFC30' : 'red',
                  }),on:{"click":function($event){_vm.infoList.ZQT_XK5
                      ? _vm.toDetail(
                          1,
                          'ZQT_XK5',
                          _vm.infoList.ZQT_XK5_node_string,
                          '',
                          '二级甲减温先控调节'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.ZQT_QK3.TS ? '#2AFC30' : 'red',
                  }),on:{"click":function($event){_vm.infoList.ZQT_QK3
                      ? _vm.toDetail(
                          2,
                          'ZQT_QK3',
                          _vm.infoList.ZQT_QK3_node_string,
                          '',
                          '二级减温左前馈'
                        )
                      : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.ZQT_QK5.TS ? '#2AFC30' : 'red',
                  }),on:{"click":function($event){_vm.infoList.ZQT_QK5
                      ? _vm.toDetail(
                          2,
                          'ZQT_QK5',
                          _vm.infoList.ZQT_QK5_node_string,
                          '',
                          '负荷前馈'
                        )
                      : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"fl widthx4 color1"},[_vm._v("左二级减温SP")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_2A_SP',
                      _vm.infoList.ZQT_2A_SP_node_string,
                      'ZQT_2A_SP_BM',
                      '甲二级减温控制点'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.ZQT_2A_SP,
                      'ZQT_2A_SP',
                      _vm.infoList.ZQT_2A_SP_node_string,
                      'ZQT_2A_FV_BM'
                    )}}},[_vm._v(" "+_vm._s("ZQT_2A_SP" in _vm.infoList ? _vm.infoList.ZQT_2A_SP : 0)+"℃ ")])]),_c('div',{staticClass:"rightmain-row1 rightmain-jg2 rightmain-jg5 fl"},[_c('div',{staticClass:"Kclick",on:{"click":function($event){return _vm.toCompon(7)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWHT_2B_PV',
                      _vm.infoList.JWHT_2B_PV_node_string,
                      'JWHT_2B_PV_BM',
                      '乙二级减温后温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.JWHT_2B_PV,
                      'JWHT_2B_PV',
                      _vm.infoList.JWHT_2B_PV_node_string,
                      'JWHT_2B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("JWHT_2B_PV" in _vm.infoList ? _vm.infoList.JWHT_2B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex rightmain-jg2",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_2B_FV',
                      _vm.infoList.ZQT_2B_FV_node_string,
                      'ZQT_2B_FV_BM',
                      '乙二级减温阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.ZQT_2B_FV,
                      'ZQT_2B_FV',
                      _vm.infoList.ZQT_2B_FV_node_string,
                      'ZQT_2B_FV_BM'
                    )}}},[_vm._v(" "+_vm._s("ZQT_2B_FV" in _vm.infoList ? _vm.infoList.ZQT_2B_FV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")]),_c('div',{staticClass:"fl valuex rightmain-jg3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWQT_2B_PV',
                      _vm.infoList.JWQT_2B_PV_node_string,
                      'JWQT_2B_PV_BM',
                      '乙二级减温前温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.JWQT_2B_PV,
                      'JWQT_2B_PV',
                      _vm.infoList.JWQT_2B_PV_node_string,
                      'JWQT_2B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("JWQT_2B_PV" in _vm.infoList ? _vm.infoList.JWQT_2B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl widthx3 color1 fl"}),_c('div',{staticClass:"bottonx fl",style:({
                    background: !_vm.infoList.ZQT_XK7.RM ? '#2AFC30' : 'red',
                  }),on:{"click":function($event){_vm.infoList.ZQT_XK7
                      ? _vm.toDetail(
                          1,
                          'ZQT_XK7',
                          _vm.infoList.ZQT_XK7_node_string,
                          '',
                          '二级乙减温先控调节'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.ZQT_QK4.TS ? '#2AFC30' : 'red',
                  }),on:{"click":function($event){_vm.infoList.ZQT_QK4
                      ? _vm.toDetail(
                          2,
                          'ZQT_QK4',
                          _vm.infoList.ZQT_QK4_node_string,
                          '',
                          '二级减温右前馈'
                        )
                      : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"widthx31 fl buttonjg"}),_c('div',{staticClass:"fl widthx4 color1"},[_vm._v("右二级减温SP")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_2B_SP',
                      _vm.infoList.ZQT_2B_SP_node_string,
                      'ZQT_2B_SP_BM',
                      '乙一级减温控制点'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.ZQT_2B_SP,
                      'ZQT_2B_SP',
                      _vm.infoList.ZQT_2B_SP_node_string,
                      'ZQT_2B_SP_BM'
                    )}}},[_vm._v(" "+_vm._s("ZQT_2B_SP" in _vm.infoList ? _vm.infoList.ZQT_2B_SP : 0)+"℃ ")])]),_c('div',{staticClass:"rightmain-row1 rightmain-jg2 rightmain-jg4 fl"},[_c('div',{staticClass:"Kclick",on:{"click":function($event){return _vm.toCompon(7)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWHT_1A_PV',
                      _vm.infoList.JWHT_1A_PV_node_string,
                      'JWHT_1A_PV_BM',
                      '甲一级减温后温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.JWHT_1A_PV,
                      'JWHT_1A_PV',
                      _vm.infoList.JWHT_1A_PV_node_string,
                      'JWHT_1A_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("JWHT_1A_PV" in _vm.infoList ? _vm.infoList.JWHT_1A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex rightmain-jg2",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_1A_FV',
                      _vm.infoList.ZQT_1A_FV_node_string,
                      'ZQT_1A_FV_BM',
                      '甲一级减温阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.ZQT_1A_FV,
                      'ZQT_1A_FV',
                      _vm.infoList.ZQT_1A_FV_node_string,
                      'ZQT_1A_FV_BM'
                    )}}},[_vm._v(" "+_vm._s("ZQT_1A_FV" in _vm.infoList ? _vm.infoList.ZQT_1A_FV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")]),_c('div',{staticClass:"fl valuex rightmain-jg3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWQT_1A_PV',
                      _vm.infoList.JWQT_1A_PV_node_string,
                      'JWQT_1A_PV_BM',
                      '甲一级减温前温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.JWQT_1A_PV,
                      'JWQT_1A_PV',
                      _vm.infoList.JWQT_1A_PV_node_string,
                      'JWQT_1A_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("JWQT_1A_PV" in _vm.infoList ? _vm.infoList.JWQT_1A_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl widthx3 color1 fl"},[_vm._v("一级")]),_c('div',{staticClass:"bottonx fl",style:({
                    background: !_vm.infoList.ZQT_XK1.RM ? '#2AFC30' : 'red',
                  }),on:{"click":function($event){_vm.infoList.ZQT_XK1
                      ? _vm.toDetail(
                          1,
                          'ZQT_XK1',
                          _vm.infoList.ZQT_XK1_node_string,
                          '',
                          '一级甲主汽温度'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.ZQT_QK1.TS ? '#2AFC30' : 'red',
                  }),on:{"click":function($event){_vm.infoList.ZQT_QK1
                      ? _vm.toDetail(
                          2,
                          'ZQT_QK1',
                          _vm.infoList.ZQT_QK1_node_string,
                          '',
                          '一级减温左前馈'
                        )
                      : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"widthx31 fl buttonjg"}),_c('div',{staticClass:"fl widthx4 color1"},[_vm._v("左一级减温SP")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_1A_SP',
                      _vm.infoList.ZQT_1A_SP_node_string,
                      'ZQT_1A_SP_BM',
                      '甲一级减温控制点'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.ZQT_1A_SP,
                      'ZQT_1A_SP',
                      _vm.infoList.ZQT_1A_SP_node_string,
                      'ZQT_1A_SP_BM'
                    )}}},[_vm._v(" "+_vm._s("ZQT_1A_SP" in _vm.infoList ? _vm.infoList.ZQT_1A_SP : 0)+"℃ ")])]),_c('div',{staticClass:"rightmain-row1 rightmain-jg2 rightmain-jg4 fl"},[_c('div',{staticClass:"Kclick",on:{"click":function($event){return _vm.toCompon(7)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWHT_1B_PV',
                      _vm.infoList.JWHT_1B_PV_node_string,
                      'JWHT_1B_PV_BM',
                      '乙一级减温后温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.JWHT_1B_PV,
                      'JWHT_1B_PV',
                      _vm.infoList.JWHT_1B_PV_node_string,
                      'JWHT_1B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("JWHT_1B_PV" in _vm.infoList ? _vm.infoList.JWHT_1B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl valuex rightmain-jg2",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_1B_FV',
                      _vm.infoList.ZQT_1B_FV_node_string,
                      'ZQT_1B_FV_BM',
                      '乙一级减温阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.ZQT_1B_FV,
                      'ZQT_1B_FV',
                      _vm.infoList.ZQT_1B_FV_node_string,
                      'ZQT_1B_FV_BM'
                    )}}},[_vm._v(" "+_vm._s("ZQT_1B_FV" in _vm.infoList ? _vm.infoList.ZQT_1B_FV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")]),_c('div',{staticClass:"fl valuex rightmain-jg3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JWQT_1B_PV',
                      _vm.infoList.JWQT_1B_PV_node_string,
                      'JWQT_1B_PV_BM',
                      '乙一级减温前温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.JWQT_1B_PV,
                      'JWQT_1B_PV',
                      _vm.infoList.JWQT_1B_PV_node_string,
                      'JWQT_1B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("JWQT_1B_PV" in _vm.infoList ? _vm.infoList.JWQT_1B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")]),_c('div',{staticClass:"fl widthx3 color1 fl"}),_c('div',{staticClass:"bottonx fl",style:({
                    background: !_vm.infoList.ZQT_XK3.RM ? '#2AFC30' : 'red',
                  }),on:{"click":function($event){_vm.infoList.ZQT_XK3
                      ? _vm.toDetail(
                          1,
                          'ZQT_XK3',
                          _vm.infoList.ZQT_XK3_node_string,
                          '',
                          '一级乙主汽温度'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.ZQT_QK2.TS ? '#2AFC30' : 'red',
                  }),on:{"click":function($event){_vm.infoList.ZQT_QK2
                      ? _vm.toDetail(
                          2,
                          'ZQT_QK2',
                          _vm.infoList.ZQT_QK2_node_string,
                          '',
                          '一级减温右前馈'
                        )
                      : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background:
                    'ZQT_MAN1'||'ZQT_MAN2'||'ZQT_MAN3'||'ZQT_MAN4' in _vm.infoList?
                      _vm.infoList.ZQT_MAN1.RM == 1 ||
                      _vm.infoList.ZQT_MAN2.RM == 1 ||
                      _vm.infoList.ZQT_MAN3.RM == 1 ||
                      _vm.infoList.ZQT_MAN4.RM == 1
                        ? '#2AFC30'
                        : 'red': 'red',
                  }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANQW', 4)}}},[_vm._v(" A ")]),_c('div',{staticClass:"fl widthx4 color1"},[_vm._v("右一级减温SP")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZQT_1B_SP',
                      _vm.infoList.ZQT_1B_SP_node_string,
                      'ZQT_1B_SP_BM',
                      '乙一级减温控制点'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.ZQT_1B_SP,
                      'ZQT_1B_SP',
                      _vm.infoList.ZQT_1B_SP_node_string,
                      'ZQT_1B_SP'
                    )}}},[_vm._v(" "+_vm._s("ZQT_1B_SP" in _vm.infoList ? _vm.infoList.ZQT_1B_SP : 0)+"℃ ")])]),_c('div',{staticClass:"rightmain-row1 rightmain-jg2 rightmain-jg4 fl"},[_c('div',{staticClass:"Kclick Kclick1",on:{"click":function($event){return _vm.toCompon(9)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'GST_PV',
                      _vm.infoList.GST_PV_node_string,
                      'GST_PV_BM',
                      '给水温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.GST_PV,
                      'GST_PV',
                      _vm.infoList.GST_PV_node_string,
                      'GST_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("GST_PV" in _vm.infoList ? _vm.infoList.GST_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("℃")])]),_c('div',{staticClass:"rightmain-row1 rightmain-jg2 fl"},[_c('div',{staticClass:"Kclick Kclick2",on:{"click":function($event){return _vm.toCompon(9)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'GSP_B_PV',
                      _vm.infoList.GSP_B_PV_node_string,
                      'GSP_B_PV_BM',
                      '2#给水压力'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.GSP_B_PV,
                      'GSP_B_PV',
                      _vm.infoList.GSP_B_PV_node_string,
                      'GSP_B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("GSP_B_PV" in _vm.infoList ? _vm.infoList.GSP_B_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("MPa")]),_c('div',{staticClass:"fl valuex rightmain-jg6",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'QBW_A_FV',
                      _vm.infoList.QBW_A_FV_node_string,
                      'QBW_A_FV_BM',
                      '主给水阀门反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.QBW_A_FV,
                      'QBW_A_FV',
                      _vm.infoList.QBW_A_FV_node_string,
                      'GSP_B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("QBW_A_FV" in _vm.infoList ? _vm.infoList.QBW_A_FV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("%")]),_c('div',{staticClass:"fl widthx1 color1 fl rightmain-jg7"},[_vm._v("主给水")])]),_c('div',[_c('div',{staticClass:"rightmain-row1 rightmain-jg2 fl"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'GSF_PV',
                        _vm.infoList.GSF_PV_node_string,
                        'GSF_PV_BM',
                        '给水流量'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.GSF_PV,
                        'GSF_PV',
                        _vm.infoList.GSF_PV_node_string,
                        'GSP_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("GSF_PV" in _vm.infoList ? _vm.infoList.GSF_PV : 0)+" ")]),_c('div',{staticClass:"fl danwei"},[_vm._v("t/h")])]),_c('div',{staticClass:"rightmain-row1 rightmain-jg8 fl"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'QBW_B_FV',
                        _vm.infoList.QBW_B_FV_node_string,
                        'QBW_B_FV_BM',
                        '副给水阀门反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.QBW_B_FV,
                        'QBW_B_FV',
                        _vm.infoList.QBW_B_FV_node_string,
                        'QBW_B_FV_BM'
                      )}}},[_vm._v(" "+_vm._s("QBW_B_FV" in _vm.infoList ? _vm.infoList.QBW_B_FV : 0)+" ")]),_c('div',{staticClass:"fl danwei rightmain-jg31"},[_vm._v("℃")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background: !_vm.infoList.QBW_XK1.RM ? '#2AFC30' : 'red',
                    }),on:{"click":function($event){_vm.infoList.QBW_XK1
                        ? _vm.toDetail(
                            1,
                            'QBW_XK1',
                            _vm.infoList.QBW_XK1_node_string,
                            '',
                            '汽包水位主调节先控'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background: !_vm.infoList.QBW_XK2.RM ? '#2AFC30' : 'red',
                    }),on:{"click":function($event){_vm.infoList.QBW_XK2
                        ? _vm.toDetail(
                            1,
                            'QBW_XK2',
                            _vm.infoList.QBW_XK2_node_string,
                            '',
                            '主给水副调节'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background: !_vm.infoList.QBW_XK3.RM ? '#2AFC30' : 'red',
                    }),on:{"click":function($event){_vm.infoList.QBW_XK3
                        ? _vm.toDetail(
                            1,
                            'QBW_XK3',
                            _vm.infoList.QBW_XK3_node_string,
                            '',
                            '副给水副调节'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background:
                      'QBW_MAN1'||'QBW_MAN2' in _vm.infoList?
                        _vm.infoList.QBW_MAN1.RM == 1 || _vm.infoList.QBW_MAN2.RM == 1
                          ? '#2AFC30'
                          : 'red': 'red',
                    }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANGS', 2)}}},[_vm._v(" A ")])])])]),_c('div',{staticClass:"songfeng"},[_c('div',{staticClass:"fl yinfen-jg"},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'SFF_B_PV',
                      _vm.infoList.SFF_B_PV_node_string,
                      'SFF_B_PV_BM',
                      '送风流量过程值2'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.SFF_B_PV,
                      'SFF_B_PV',
                      _vm.infoList.SFF_B_PV_node_string,
                      'SFF_B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("SFF_B_PV" in _vm.infoList ? _vm.infoList.SFF_B_PV : 0)+"t/h ")])]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"yinfen-jg2",staticStyle:{"margin-left":"7.7vw"}},[_c('div',{staticClass:"Kclick Kclick3",on:{"click":function($event){return _vm.toCompon(10)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SFI_A_PV',
                        _vm.infoList.SFI_A_PV_node_string,
                        'SFI_A_PV_BM',
                        '1#送风机电流'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.SFI_A_PV,
                        'SFI_A_PV',
                        _vm.infoList.SFI_A_PV_node_string,
                        'SFF_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("SFI_A_PV" in _vm.infoList ? _vm.infoList.SFI_A_PV : 0)+"A ")]),_c('div',{staticClass:"fl widthx5 color1",staticStyle:{"margin-top":"-2vh"}},[_vm._v(" 送风机1# ")])]),_c('div',{staticClass:"yinfen-jg2",staticStyle:{"margin-left":"7.7vw","margin-top":"9vh"}},[_c('div',{staticClass:"Kclick Kclick3",on:{"click":function($event){return _vm.toCompon(10)}}}),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SFI_B_PV',
                        _vm.infoList.SFI_B_PV_node_string,
                        'SFI_B_PV_BM',
                        '2#送风机电流'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.SFI_B_PV,
                        'SFI_B_PV',
                        _vm.infoList.SFI_B_PV_node_string,
                        'SFI_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("SFI_B_PV" in _vm.infoList ? _vm.infoList.SFI_B_PV : 0)+"A ")]),_c('div',{staticClass:"fl widthx5 color1",staticStyle:{"margin-top":"-2vh"}},[_vm._v(" 送风机2# ")])])]),_c('div',{staticClass:"fl yinfen-jg4"},[_c('div',[_c('div',{staticClass:"valuexzr",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'YQO_BA_MV',
                        _vm.infoList.YQO_BA_MV_node_string,
                        'YQO_BA_MV_BM',
                        '1#送风挡板XK输出'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.YQO_BA_MV,
                        'YQO_BA_MV',
                        _vm.infoList.YQO_BA_MV_node_string,
                        'YQO_BA_MV_BM'
                      )}}},[_vm._v(" "+_vm._s("YQO_BA_MV" in _vm.infoList ? _vm.infoList.YQO_BA_MV : 0)+"% ")]),_c('div',{staticClass:"valuexzr",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'YQO_BA_FV',
                        _vm.infoList.YQO_BA_FV_node_string,
                        'YQO_BA_FV_BM',
                        '1#送风反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.YQO_BA_FV,
                        'YQO_BA_FV',
                        _vm.infoList.YQO_BA_FV_node_string,
                        'YQO_BA_FV_BM'
                      )}}},[_vm._v(" "+_vm._s("YQO_BA_FV" in _vm.infoList ? _vm.infoList.YQO_BA_FV : 0)+"% ")])]),_c('div',{staticClass:"yinfen-jg5"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"valuexzr",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'YQO_BB_MV',
                          _vm.infoList.YQO_BB_MV_node_string,
                          'YQO_BB_MV_BM',
                          '2#送风挡板XK输出'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.YQO_BB_MV,
                          'YQO_BB_MV',
                          _vm.infoList.YQO_BB_MV_node_string,
                          'YQO_BB_MV_BM'
                        )}}},[_vm._v(" "+_vm._s("YQO_BB_MV" in _vm.infoList ? _vm.infoList.YQO_BB_MV : 0)+"% ")]),_c('div',{staticClass:"valuexzr",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'YQO_BB_FV',
                          _vm.infoList.YQO_BB_FV_node_string,
                          'YQO_BB_FV_BM',
                          '2#送风反馈'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.YQO_BB_FV,
                          'YQO_BB_FV',
                          _vm.infoList.YQO_BB_FV_node_string,
                          'YQO_BB_FV_BM'
                        )}}},[_vm._v(" "+_vm._s("YQO_BB_FV" in _vm.infoList ? _vm.infoList.YQO_BB_FV : 0)+"% ")])]),_c('div',{staticClass:"fl yinfen-jg6"},[_c('div',{staticClass:"bottonx fl buttonjg",style:({
                        background: !_vm.infoList.YQO_RSF1.TS ? '#2AFC30' : 'red',
                      }),on:{"click":function($event){_vm.infoList.YQO_RSF1
                          ? _vm.toDetail(
                              3,
                              'YQO_RSF1',
                              _vm.infoList.YQO_RSF1_node_string,
                              '',
                              '送风机电流纠偏'
                            )
                          : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                        background: !_vm.infoList.YQO_XK1.RM ? '#2AFC30' : 'red',
                      }),on:{"click":function($event){_vm.infoList.YQO_XK1
                          ? _vm.toDetail(
                              1,
                              'YQO_XK1',
                              _vm.infoList.YQO_XK1_node_string,
                              '',
                              '氧量先控调节'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                        background: !_vm.infoList.YQO_XK2.RM ? '#2AFC30' : 'red',
                      }),on:{"click":function($event){_vm.infoList.YQO_XK2
                          ? _vm.toDetail(
                              1,
                              'YQO_XK2',
                              _vm.infoList.YQO_XK2_node_string,
                              '',
                              '送风变频先控调节'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                        background:
                        'YQO_MAN1'||'YQO_MAN2' in _vm.infoList?
                          _vm.infoList.YQO_MAN1.RM == 1 ||
                          _vm.infoList.YQO_MAN2.RM == 1
                            ? '#2AFC30'
                            : 'red': 'red',
                      }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANSF', 2)}}},[_vm._v(" A ")])])])])]),_c('div',{staticClass:"yicfeng"},[_c('div',{staticClass:"Kclick Kclick4",on:{"click":function($event){return _vm.toCompon(11)}}}),_c('div',{staticClass:"Kclick Kclick5",on:{"click":function($event){return _vm.toCompon(11)}}}),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"yinfen-jg2",staticStyle:{"margin-left":"4.7vw"}},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'YCF_A_PV',
                        _vm.infoList.YCF_A_PV_node_string,
                        'YCF_A_PV_BM',
                        '1#一次风过程值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.YCF_A_PV,
                        'YCF_A_PV',
                        _vm.infoList.YCF_A_PV_node_string,
                        'YCF_A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("YCF_A_PV" in _vm.infoList ? _vm.infoList.YCF_A_PV : 0)+"KPa ")]),_c('div',{staticClass:"fl widthx5 color1"},[_vm._v("一次风机1")])]),_c('div',{staticClass:"yinfen-jg2",staticStyle:{"margin-left":"4.7vw","margin-top":"9vh"}},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'YCF_B_PV',
                        _vm.infoList.YCF_B_PV_node_string,
                        'YCF_B_PV_BM',
                        '2#一次风过程值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.YCF_B_PV,
                        'YCF_B_PV',
                        _vm.infoList.YCF_B_PV_node_string,
                        'YCF_B_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("YCF_B_PV" in _vm.infoList ? _vm.infoList.YCF_B_PV : 0)+"KPa ")]),_c('div',{staticClass:"fl widthx5 color1"},[_vm._v("一次风机2")])])]),_c('div',{staticClass:"fl yinfen-jg4"},[_c('div',[_c('div',{staticClass:"valuex yinfen-jg7",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SFI_A_PV',
                        _vm.infoList.SFI_A_PV_node_string,
                        'SFI_A_PV_BM',
                        '1#送风机电流'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.SFI_A_PV,
                        'SFI_A_PV',
                        _vm.infoList.SFI_A_PV_node_string,
                        'SFI_A_PV_BM'
                      )}}},[_vm._v(" "+_vm._s("SFI_A_PV" in _vm.infoList ? _vm.infoList.SFI_A_PV : 0)+"A ")])]),_c('div',{staticClass:"yinfen-jg5"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"valuex yinfen-jg7",staticStyle:{"margin-top":"4vh"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'SFI_B_PV',
                          _vm.infoList.SFI_B_PV_node_string,
                          'SFI_B_PV_BM',
                          '2#送风机电流'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.SFI_B_PV,
                          'SFI_B_PV',
                          _vm.infoList.SFI_B_PV_node_string,
                          'SFI_B_PV_BM'
                        )}}},[_vm._v(" "+_vm._s("SFI_B_PV" in _vm.infoList ? _vm.infoList.SFI_B_PV : 0)+"A ")])])])]),_c('div',{staticClass:"fl yinfen-jg9"},[_c('div',{staticClass:"valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YCF_A_MV',
                      _vm.infoList.YCF_A_MV_node_string,
                      'YCF_A_MV_BM',
                      '1#一次风XK输出'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.YCF_A_MV,
                      'YCF_A_MV',
                      _vm.infoList.YCF_A_MV_node_string,
                      'YCF_A_MV_BM'
                    )}}},[_vm._v(" "+_vm._s("YCF_A_MV" in _vm.infoList ? _vm.infoList.YCF_A_MV : 0)+"% ")]),_c('div',{staticClass:"valuex yinfen-jg10",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YCF_A_FV',
                      _vm.infoList.YCF_A_FV_node_string,
                      'YCF_A_FV_BM',
                      '1#一次风开度反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.YCF_A_FV,
                      'YCF_A_FV',
                      _vm.infoList.YCF_A_FV_node_string,
                      'YCF_A_FV_BM'
                    )}}},[_vm._v(" "+_vm._s("YCF_A_FV" in _vm.infoList ? _vm.infoList.YCF_A_FV : 0)+"% ")]),_c('div',{staticClass:"valuex yinfen-jg2",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YCF_B_MV',
                      _vm.infoList.YCF_B_MV_node_string,
                      'YCF_B_MV_BM',
                      '2#一次风XK输出'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.YCF_B_MV,
                      'YCF_B_MV',
                      _vm.infoList.YCF_B_MV_node_string,
                      'YCF_B_MV_BM'
                    )}}},[_vm._v(" "+_vm._s("YCF_B_MV" in _vm.infoList ? _vm.infoList.YCF_B_MV : 0)+"% ")]),_c('div',{staticClass:"valuex yinfen-jg11",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YCF_B_FV',
                      _vm.infoList.YCF_B_FV_node_string,
                      'YCF_B_FV_BM',
                      '2#一次风开度反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.YCF_B_FV,
                      'YCF_B_FV',
                      _vm.infoList.YCF_B_FV_node_string,
                      'YCF_B_FV_BM'
                    )}}},[_vm._v(" "+_vm._s("YCF_B_FV" in _vm.infoList ? _vm.infoList.YCF_B_FV : 0)+"% ")])]),_c('div',{staticClass:"fl yinfen-jg8"},[_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.YCF_QK1.SP ? '#2AFC30' : 'red',
                  }),on:{"click":function($event){_vm.infoList.YCF_QK1
                      ? _vm.toDetail(
                          2,
                          'YCF_QK1',
                          _vm.infoList.YCF_QK1_node_string,
                          '',
                          '一次风前馈'
                        )
                      : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.YCF_RSF1.TS ? '#2AFC30' : 'red',
                  }),on:{"click":function($event){_vm.infoList.YCF_RSF1
                      ? _vm.toDetail(
                          3,
                          'YCF_RSF1',
                          _vm.infoList.YCF_RSF1_node_string,
                          '',
                          '一次风机电流纠偏'
                        )
                      : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background: !_vm.infoList.YCF_XK1.RM ? '#2AFC30' : 'red',
                  }),on:{"click":function($event){_vm.infoList.YCF_XK1
                      ? _vm.toDetail(
                          1,
                          'YCF_XK1',
                          _vm.infoList.YCF_XK1_node_string,
                          '',
                          '一次风压先控调节'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                    background:
                    'YCF_MAN1'||'YCF_MAN2' in  _vm.infoList?
                      _vm.infoList.YCF_MAN1.RM == 1 || _vm.infoList.YCF_MAN2.RM == 1
                        ? '#2AFC30'
                        : 'red': 'red',
                  }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANYCF', 2)}}},[_vm._v(" A ")])])]),_c('div',{staticClass:"yinfeng"},[_c('div',{staticClass:"Kclick Kclick6",on:{"click":function($event){return _vm.toCompon(12)}}}),_c('div',{staticClass:"Kclick Kclick7",on:{"click":function($event){return _vm.toCompon(12)}}}),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"yinfen-jg2",staticStyle:{"margin-left":"4vw"}},[_c('div',{staticClass:"fl valuexzr",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'LTP_DA_FV',
                        _vm.infoList.LTP_DA_FV_node_string,
                        'LTP_DA_FV_BM',
                        '1#引风挡板反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.LTP_DA_FV,
                        'LTP_DA_FV',
                        _vm.infoList.LTP_DA_FV_node_string,
                        'LTP_DA_FV_BM'
                      )}}},[_vm._v(" "+_vm._s("LTP_DA_FV" in _vm.infoList ? _vm.infoList.LTP_DA_FV : 0)+"% ")])]),_c('div',{staticClass:"yinfen-jg2",staticStyle:{"margin-left":"4vw","margin-top":"13vh"}},[_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'LTP_DB_FV',
                        _vm.infoList.LTP_DB_FV_node_string,
                        'LTP_DB_FV_BM',
                        '2#引风挡板反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.LTP_DB_FV,
                        'LTP_DB_FV',
                        _vm.infoList.LTP_DB_FV_node_string,
                        'LTP_DB_FV_BM'
                      )}}},[_vm._v(" "+_vm._s("LTP_DB_FV" in _vm.infoList ? _vm.infoList.LTP_DB_FV : 0)+"% ")])])]),_c('div',{staticClass:"fl yinfeng-jg2",staticStyle:{"margin-left":"1.8vw"}},[_c('div',{staticClass:"valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'LTP_BA_FV',
                      _vm.infoList.LTP_BA_FV_node_string,
                      'LTP_BA_FV_BM',
                      '1#引风液耦反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.LTP_BA_FV,
                      'LTP_BA_FV',
                      _vm.infoList.LTP_BA_FV_node_string,
                      'YCF_A_MV_BM'
                    )}}},[_vm._v(" "+_vm._s("LTP_BA_FV" in _vm.infoList ? _vm.infoList.LTP_BA_FV : 0)+"% ")]),_c('div',{staticClass:"valuex",staticStyle:{"margin-top":"0.5vh"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'LTP_BA_MV',
                      _vm.infoList.LTP_BA_MV_node_string,
                      'LTP_BA_MV_BM'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.LTP_BA_MV,
                      'LTP_BA_MV',
                      _vm.infoList.LTP_BA_MV_node_string,
                      'LTP_BA_MV_BM',
                      '1#引风液耦XK输出'
                    )}}},[_vm._v(" "+_vm._s("LTP_BA_MV" in _vm.infoList ? _vm.infoList.LTP_BA_MV : 0)+"% ")]),_c('div',{staticClass:"valuex yinfen-jg2 yinfeng-jg1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'LTP_BB_FV',
                      _vm.infoList.LTP_BB_FV_node_string,
                      'LTP_BB_FV_BM'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.LTP_BB_FV,
                      'LTP_BB_FV',
                      _vm.infoList.LTP_BB_FV_node_string,
                      'LTP_BB_FV_BM',
                      '2#引风液耦反馈'
                    )}}},[_vm._v(" "+_vm._s("LTP_BB_FV" in _vm.infoList ? _vm.infoList.LTP_BB_FV : 0)+"% ")]),_c('div',{staticClass:"valuex",staticStyle:{"margin-top":"0.8vh"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'LTP_BB_MV',
                      _vm.infoList.LTP_BB_MV_node_string,
                      'LTP_BB_MV_BM',
                      '2#引风液耦XK输出'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.LTP_BB_MV,
                      'LTP_BB_MV',
                      _vm.infoList.LTP_BB_MV_node_string,
                      'LTP_BB_MV_BM'
                    )}}},[_vm._v(" "+_vm._s("LTP_BB_MV" in _vm.infoList ? _vm.infoList.LTP_BB_MV : 0)+"% ")])]),_c('div',{staticClass:"fl yinfeng-jg2 yinfeng-jg3"},[_c('div',{staticClass:"valuex",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YFI_A_PV',
                      _vm.infoList.YFI_A_PV_node_string,
                      'YFI_A_PV_BM',
                      '1#引风机电流'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.YFI_A_PV,
                      'YFI_A_PV',
                      _vm.infoList.YFI_A_PV_node_string,
                      'YCF_A_MV_BM'
                    )}}},[_vm._v(" "+_vm._s("YFI_A_PV" in _vm.infoList ? _vm.infoList.YFI_A_PV : 0)+"A ")]),_c('div',{staticClass:"valuex yinfeng-jg4",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YFI_B_PV',
                      _vm.infoList.YFI_B_PV_node_string,
                      'YFI_B_PV_BM',
                      '2#引风机电流'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.YFI_B_PV,
                      'YFI_B_PV',
                      _vm.infoList.YFI_B_PV_node_string,
                      'YFI_B_PV_BM'
                    )}}},[_vm._v(" "+_vm._s("YFI_B_PV" in _vm.infoList ? _vm.infoList.YFI_B_PV : 0)+"A ")])]),_vm._m(0),_c('div',{staticClass:"fl yinfeng-jg5"},[_c('div',[_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background: !_vm.infoList.LTP_QK1.TS ? '#2AFC30' : 'red',
                    }),on:{"click":function($event){_vm.infoList.LTP_QK1
                        ? _vm.toDetail(
                            2,
                            'LTP_QK1',
                            _vm.infoList.LTP_QK1_node_string,
                            '',
                            '炉膛负压前馈'
                          )
                        : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background: !_vm.infoList.LTP_RSF1.TS ? '#2AFC30' : 'red',
                    }),on:{"click":function($event){_vm.infoList.LTP_RSF1
                        ? _vm.toDetail(
                            3,
                            'LTP_RSF1',
                            _vm.infoList.LTP_RSF1_node_string,
                            '',
                            '引风机电流纠偏'
                          )
                        : ''}}},[_vm._v(" R ")])]),_c('div',{staticStyle:{"margin-top":"3vh"}},[_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background: !_vm.infoList.LTP_XK1.RM ? '#2AFC30' : 'red',
                    }),on:{"click":function($event){_vm.infoList.LTP_XK1
                        ? _vm.toDetail(
                            1,
                            'LTP_XK1',
                            _vm.infoList.LTP_XK1_node_string,
                            '',
                            '引风先控调节'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl buttonjg",style:({
                      background:
                      'LTP_MAN1'||'LTP_MAN2' in _vm.infoList?
                        _vm.infoList.LTP_MAN1.RM == 1 || _vm.infoList.LTP_MAN2.RM == 1
                          ? '#2AFC30'
                          : 'red': 'red',
                    }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANYF', 2)}}},[_vm._v(" A ")])])])])])])])])]),(_vm.mflmanyManual)?_c('mflmanyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"mark":_vm.Manualmark,"manvar":_vm.Manualvar,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.nodename,"Lkname":_vm.Aname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),_c('inputVal',{ref:"inputVal"}),(_vm.isPshow01)?_c('Param01',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow02)?_c('Param02',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow03)?_c('Param03',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow04)?_c('Param04',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.iszqwdshow)?_c('MfZRQTC',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow05)?_c('Param05',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isPshow06)?_c('Param06',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.MfMAC)?_c('MfMAC',{attrs:{"infoList":_vm.infoList,"MfMACCS1":_vm.MfMACCS1,"MfMACCS2":_vm.MfMACCS2,"MfMACCS3":_vm.MfMACCS3,"MfMACCS4":_vm.MfMACCS4},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fl yinfeng-jg5"},[_c('div',{staticClass:"widthx5 color1"},[_vm._v("引风机1#")]),_c('div',{staticClass:"widthx5 yinfeng-jg4 color1"},[_vm._v("引风机2#")])])}]

export { render, staticRenderFns }