<template>
  <div class="jrlmqzg drag" id="ZQPC" v-draw>
    <!-- 标题部分 -->
    <div>
      <div class="title-hang" fl>{{ projectData.project }}_ZQP_C</div>
      <div class="param11-header-icon" fr @click="closeCompon">
        <img
          src="~@/assets/images/close.png"
          style="width: 100%; height: auto"
        />
      </div>
    </div>
    <div class="titlelable">主汽压力控制模型</div>
    <div class="main">
      <!-- 多行的 -->
      <div class="GFQWYZ">
        <div class="row">
          <div class="col1 fl">GFQWYZ</div>
          <div
            class="col3 fl"
            @click="toIpt(infoList.GFQWYZ, 'GFQWYZ', 'GFQWYZ', infoList.GFQWYZ_node_string)"
          >
            {{ 'GFQWYZ' in infoList? infoList.GFQWYZ :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">ZQT_PV</div>
          <div
            class="col2 fl"
            @click="
              toCompon(2, 'ZQT_PV', infoList.ZQT_PV_node_string, 'ZQT_PV_BM', '主汽温度过程平均值')
            "
            @dblclick="
              Cclick(infoList.ZQT_PV, 'ZQT_PV', infoList.ZQT_PV_node_string, 'ZQT_PV_BM')
            "
          >
            {{ 'ZQT_PV' in infoList? infoList.ZQT_PV :0}}
          </div>
        </div>
      </div>
      <div class="RQLL_PV">
        <div class="row">
          <div class="col1 fl">RQLL_PV</div>
          <div
            class="col2 fl"
            @click="toCompon(2, 'RQLL_PV', infoList.RQLL_PV_node_string, 'RQLL_PV_BM', '燃气流量')"
            @dblclick="
              Cclick(infoList.RQLL_PV, 'RQLL_PV', infoList.RQLL_PV_node_string, 'RQLL_PV_BM')
            "
          >
            {{ 'RQLL_PV' in infoList? infoList.RQLL_PV :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">RQLL_TC</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.RQLL_PV_TC, 'RQLL_TC', 'RQLL_PV_TC', infoList.RQLL_PV_TC_node_string)
            "
          >
            {{ 'RQLL_PV_TC' in infoList? infoList.RQLL_PV_TC :0}}
          </div>
        </div>
      </div>
      <div class="ZQP_SP_HL">
        <div class="row">
          <div class="col1 fl">ZQP_SP_HL</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.ZQP_SP_HL, 'ZQP_SP_HL', 'ZQP_SP_HL', infoList.ZQP_SP_HL_node_string)
            "
          >
            {{ 'ZQP_SP_HL' in infoList? infoList.ZQP_SP_HL :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">ZQP_SP_LL</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.ZQP_SP_LL, 'ZQP_SP_LL', 'ZQP_SP_LL', infoList.ZQP_SP_LL_node_string)
            "
          >
            {{ 'ZQP_SP_LL' in infoList? infoList.ZQP_SP_LL :0}}
          </div>
        </div>
      </div>
      <div class="ZQP_PV">
        <div class="row">
          <div class="col1 fl">ZQP_PV</div>
          <div
            class="col2 fl"
            @click="toCompon(2, 'ZQP_PV', infoList.ZQP_PV_node_string, 'ZQP_PV_BM', '主汽压力测量值')"
            @dblclick="
              Cclick(infoList.ZQP_PV, 'ZQP_PV', infoList.ZQP_PV_node_string, 'ZQP_PV_BM')
            "
          >
            {{ 'ZQP_PV' in infoList? infoList.ZQP_PV :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">ZQP_TC</div>
          <div
            class="col3 fl"
            @click="toIpt(infoList.ZQP_TC, 'ZQP_TC', 'ZQP_TC', infoList.ZQP_TC_node_string)"
          >
            {{ 'ZQP_TC' in infoList? infoList.ZQP_TC :0}}
          </div>
        </div>
        <div style="margin-top: 2vh">单回路压力</div>
        <div style="margin-top: 2vh">
          <div
            class="float:left"
            style="text-align: center;cursor: pointer;"
            :style="{
              background: infoList.MS_SEL == 1 ? '#2AFC30' : 'red',
            }"
            @click="
              toIpt(!infoList.MS_SEL, '单/串按钮', 'MS_SEL', infoList.MS_SEL_node_string, 'true')
            "
          >
            单/串
          </div>
          <div style="text-align: center">+</div>
          <div
            class="float:left"
            style="text-align: center;cursor: pointer;"
            :style="{
              background: infoList.PF_SEL == 1 ? '#2AFC30' : 'red',
            }"
            @click="
              toIpt(!infoList.PF_SEL, 'P/FM按钮', 'PF_SEL', infoList.PF_SEL_node_string, 'true')
            "
          >
            P/FM
          </div>
        </div>
      </div>
      <div class="ZQF_DSP">
        <div class="row">
          <div class="col1 fl">ZQF_DSP</div>
          <div
            class="col2 fl"
            @click="
              toCompon(2, 'ZQF_DSP', infoList.ZQF_DSP_node_string, 'ZQF_DSP_BM', '主汽流量DCS设定值')
            "
            @dblclick="
              Cclick(infoList.ZQF_DSP, 'ZQF_DSP', infoList.ZQF_DSP_node_string, 'ZQF_DSP_BM')
            "
          >
            {{ 'ZQF_DSP' in infoList? infoList.ZQF_DSP :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">ZQF_PSP</div>
          <div
            class="col2 fl"
            @click="
              toCompon(2, 'ZQF_PSP', infoList.ZQF_PSP_node_string, 'ZQF_PSP_BM', '主汽流量压力控制点')
            "
            @dblclick="
              Cclick(infoList.ZQF_PSP, 'ZQF_PSP', infoList.ZQF_PSP_node_string, 'ZQF_PSP_BM')
            "
          >
            {{ 'ZQF_PSP' in infoList? infoList.ZQF_PSP :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">ZQF_MSP</div>
          <div
            class="col2 fl"
            @click="
              toCompon(2, 'ZQF_MSP', infoList.ZQF_MSP_node_string, 'ZQF_MSP_BM', '主汽流量母管控制点')
            "
            @dblclick="
              Cclick(infoList.ZQF_MSP, 'ZQF_MSP', infoList.ZQF_MSP_node_string, 'ZQF_MSP_BM')
            "
          >
            {{ 'ZQF_MSP' in infoList? infoList.ZQF_MSP :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">ZQF_SP</div>
          <div
            class="col2 fl"
            @click="toCompon(2, 'ZQF_SP', infoList.ZQF_SP_node_string, 'ZQF_SP_BM', '主汽流量设定值')"
            @dblclick="
              Cclick(infoList.ZQF_SP, 'ZQF_SP', infoList.ZQF_SP_node_string, 'ZQF_MSP_BM')
            "
          >
            {{ 'ZQF_SP' in infoList? infoList.ZQF_SP :0}}
          </div>
        </div>
      </div>
      <div class="ZQP_MV">
        <div class="row">
          <div class="col1 fl">ZQP_MV</div>
          <div
            class="col2 fl"
            @click="toCompon(2, 'ZQP_MV', infoList.ZQP_MV_node_string, 'ZQP_MV_BM', '给煤层操指令')"
            @dblclick="
              Cclick(infoList.ZQP_MV, 'ZQP_MV', infoList.ZQP_MV_node_string, 'ZQP_MV_BM')
            "
          >
            {{ 'ZQP_MV' in infoList? infoList.ZQP_MV :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">NUM</div>
          <div
            class="col2 fl"
            @click="
              toCompon(2, 'AUTONUM', infoList.AUTONUM_node_string, 'AUTONUM_BM', '给煤机投自动个数')
            "
            @dblclick="
              Cclick(infoList.AUTONUM, 'AUTONUM', infoList.AUTONUM_node_string, 'AUTONUM_BM')
            "
          >
            {{ 'AUTONUM' in infoList? infoList.AUTONUM :0}}
          </div>
        </div>
      </div>
      <!-- 单行的 -->
      <div class="ZQT_2A_SP">
        <div class="row">
          <div class="col1 fl">ZQT_2A_SP</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.ZQT_2A_SP, 'ZQT_2A_SP', 'ZQT_2A_SP', infoList.ZQT_2A_SP_node_string)
            "
          >
            {{ 'ZQT_2A_SP' in infoList? infoList.ZQT_2A_SP :0}}
          </div>
        </div>
      </div>
      <div class="ZQP_SP">
        <div class="row">
          <div class="col1 fl">ZQP_SP</div>
          <div
            class="col2 fl"
            @click="toCompon(2, 'ZQP_YC', infoList.ZQP_YC_node_string, 'ZQP_YC_BM', '主汽压预测值')"
            @dblclick="
              Cclick(infoList.ZQP_YC, 'ZQP_YC', infoList.ZQP_YC_node_string, 'ZQP_YC_BM')
            "
          >
            {{ 'ZQP_YC' in infoList? infoList.ZQP_YC :0}}
          </div>
        </div>
      </div>
      <div class="ZQP_XK6_AV">
        <div class="row">
          <div class="col1 fl">ZQP_XK6_AV</div>
          <div
            class="col2 fl"
            @click="
              toCompon(
                2,
                'AV',
                infoList.GMJ_XK6_node_string,
                'GMJ_XK6_AV_BM',
                '主汽温度补偿',
                'GMJ_XK6'
              )
            "
            @dblclick="
              Cclick(
                infoList.GMJ_XK6.AV,
                'AV',
                infoList.GMJ_XK6_node_string,
                'GMJ_XK6_AV_BM'
              )
            "
          >
            {{ 'GMJ_XK6' in infoList? infoList.GMJ_XK6.AV :0}}
          </div>
        </div>
      </div>
      <div class="ZQF_TC">
        <div class="row">
          <div class="col1 fl">ZQF_TC</div>
          <div
            class="col3 fl"
            @click="toIpt(infoList.ZQF_TC, 'ZQF_TC', 'ZQF_TC', infoList.ZQF_TC_node_string)"
          >
            {{ 'ZQF_TC' in infoList? infoList.ZQF_TC :0}}
          </div>
        </div>
      </div>
      <div class="ZQF_PVL">
        <div class="row">
          <div class="col1 fl">ZQF_PVL</div>
          <div
            class="col2 fl"
            @click="
              toCompon(2, 'ZQF_PVL', infoList.ZQF_PVL_node_string, 'ZQF_PVL_BM', '主汽流量滤波值')
            "
            @dblclick="
              Cclick(infoList.ZQF_PVL, 'ZQF_PVL', infoList.ZQF_PVL_node_string, 'ZQF_PV_BM')
            "
          >
            {{ 'ZQF_PVL' in infoList? infoList.ZQF_PVL :0}}
          </div>
        </div>
      </div>
      <div class="QBP_TC">
        <div class="row">
          <div class="col1 fl">QBP_TC</div>
          <div
            class="col3 fl"
            @click="toIpt(infoList.QBP_PV_TC, 'QBP_TC', 'QBP_PV_TC', infoList.QBP_PV_TC_node_string)"
          >
            {{ 'QBP_PV_TC' in infoList? infoList.QBP_PV_TC :0}}
          </div>
        </div>
      </div>
      <div class="GML_E_PVB">
        <div class="row">
          <div class="col1 fl">GML_E_PVB</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.GML_E_PVB, 'GML_E_PVB', 'GML_E_PVB', infoList.GML_E_PVB_node_string)
            "
          >
            {{ 'GML_E_PVB' in infoList? infoList.GML_E_PVB :0}}
          </div>
        </div>
      </div>
      <div class="GML_D_PVB">
        <div class="row">
          <div class="col1 fl">GML_D_PVB</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.GML_D_PVB, 'GML_D_PVB', 'GML_D_PVB', infoList.GML_D_PVB_node_string)
            "
          >
            {{ 'GML_D_PVB' in infoList? infoList.GML_D_PVB :0}}
          </div>
        </div>
      </div>
      <div class="GML_C_PVB">
        <div class="row">
          <div class="col1 fl">GML_C_PVB</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.GML_C_PVB, 'GML_C_PVB', 'GML_C_PVB', infoList.GML_C_PVB_node_string)
            "
          >
            {{ 'GML_C_PVB' in infoList? infoList.GML_C_PVB :0}}
          </div>
        </div>
      </div>
      <div class="GML_B_PVB">
        <div class="row">
          <div class="col1 fl">GML_B_PVB</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.GML_B_PVB, 'GML_B_PVB', 'GML_B_PVB', infoList.GML_B_PVB_node_string)
            "
          >
            {{ 'GML_B_PVB' in infoList? infoList.GML_B_PVB :0}}
          </div>
        </div>
      </div>
      <div class="GML_A_PVB">
        <div class="row">
          <div class="col1 fl">GML_A_PVB</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.GML_A_PVB, 'GML_A_PVB', 'GML_A_PVB', infoList.GML_A_PVB_node_string)
            "
          >
            {{ 'GML_A_PVB' in infoList? infoList.GML_A_PVB :0}}
          </div>
        </div>
      </div>
      <div class="GML_E_SP">
        <div class="row">
          <div class="col1 fl">GML_E_SP</div>
          <div
            class="col2 fl"
            @click="
              toCompon(2, 'GML_E_SP', infoList.GML_E_SP_node_string, 'GML_E_SP_BM', '5#给煤量设定值')
            "
            @dblclick="
              Cclick(infoList.GML_E_SP, 'GML_E_SP', infoList.GML_E_SP_node_string, 'GML_E_SP_BM')
            "
          >
            {{ 'GML_E_SP' in infoList? infoList.GML_E_SP :0}}
          </div>
        </div>
      </div>
      <div class="GML_D_SP">
        <div class="row">
          <div class="col1 fl">GML_D_SP</div>
          <div
            class="col2 fl"
            @click="
              toCompon(2, 'GML_D_SP', infoList.GML_D_SP_node_string, 'GML_D_SP_BM', '4#给煤量设定值')
            "
            @dblclick="
              Cclick(infoList.GML_D_SP, 'GML_D_SP', infoList.GML_D_SP_node_string, 'GML_D_SP_BM')
            "
          >
            {{ 'GML_D_SP' in infoList? infoList.GML_D_SP :0}}
          </div>
        </div>
      </div>
      <div class="GML_C_SP">
        <div class="row">
          <div class="col1 fl">GML_C_SP</div>
          <div
            class="col2 fl"
            @click="
              toCompon(2, 'GML_C_SP', infoList.GML_C_SP_node_string, 'GML_C_SP_BM', '3#给煤量设定值')
            "
            @dblclick="
              Cclick(infoList.GML_C_SP, 'GML_C_SP', infoList.GML_C_SP_node_string, 'GML_C_SP_BM')
            "
          >
            {{ 'GML_C_SP' in infoList? infoList.GML_C_SP :0}}
          </div>
        </div>
      </div>
      <div class="GML_B_SP">
        <div class="row">
          <div class="col1 fl">GML_B_SP</div>
          <div
            class="col2 fl"
            @click="
              toCompon(2, 'GML_B_SP', infoList.GML_B_SP_node_string, 'GML_B_SP_BM', '2#给煤量设定值')
            "
            @dblclick="
              Cclick(infoList.GML_B_SP, 'GML_B_SP', infoList.GML_B_SP_node_string, 'GML_B_SP_BM')
            "
          >
            {{ 'GML_B_SP' in infoList? infoList.GML_B_SP :0}}
          </div>
        </div>
      </div>
      <div class="GML_A_SP">
        <div class="row">
          <div class="col1 fl">GML_A_SP</div>
          <div
            class="col2 fl"
            @click="
              toCompon(2, 'GML_A_SP', infoList.GML_A_SP_node_string, 'GML_A_SP_BM', '1#给煤量设定值')
            "
            @dblclick="
              Cclick(infoList.GML_A_SP, 'GML_A_SP', infoList.GML_A_SP_node_string, 'GML_A_SP_BM')
            "
          >
            {{ 'GML_A_SP' in infoList? infoList.GML_A_SP :0}}
          </div>
        </div>
      </div>
      <!-- 按钮 -->
      <div
        class="bottonx fl x_1"
        :style="{
          background: !infoList.GMJ_XK5.RM ? '#2AFC30' : 'red',
        }"
        @click="
          infoList
            ? toDetail(1, 'GMJ_XK6', infoList.GMJ_XK5_node_string, '', 'E给煤机先控调节')
            : ''
        "
      >
        X
      </div>
      <div
        class="bottonx fl x_2"
        :style="{
          background: !infoList.ZQP_XK1.RM ? '#2AFC30' : 'red',
        }"
        @click="
          infoList
            ? toDetail(1, 'ZQP_XK1', infoList.ZQP_XK1_node_string, '', '主汽压力先控调节')
            : ''
        "
      >
        X
      </div>
      <div
        class="bottonx fl x_3"
        :style="{
          background: !infoList.ZQP_XK2.RM ? '#2AFC30' : 'red',
        }"
        @click="
          infoList
            ? toDetail(1, 'ZQP_XK2', infoList.ZQP_XK2_node_string, '', '母管速率动态调节')
            : ''
        "
      >
        X
      </div>
      <div
        class="bottonx fl x_4"
        :style="{
          background: !infoList.ZQF_XK1.RM ? '#2AFC30' : 'red',
        }"
        @click="
          infoList
            ? toDetail(1, 'ZQF_XK1', infoList.ZQF_XK1_node_string, '', '主汽流量先控调节')
            : ''
        "
      >
        X
      </div>
      <!-- <div class="bottonx fl x_5"
            :style="{
              background:infoList==1 || infoList==2? '#2AFC30': 'red',
      }"
      @click="infoList ? toDetail(1,'XK13','XK__p__CYQ_XK13','','煤气总管压力控制XK'): ''" >              
            X4
    </div> -->
      <div
        class="bottonx fl q_1"
        :style="{
          background: !infoList.ZQP_QK3.TS ? '#2AFC30' : 'red',
        }"
        @click="
          infoList
            ? toDetail(2, 'ZQP_QK3', infoList.ZQP_QK3_node_string, '', '燃料器前馈')
            : ''
        "
      >
        Q
      </div>
      <div
        class="bottonx fl q_2"
        :style="{
          background: !infoList.ZQP_QK1.TS ? '#2AFC30' : 'red',
        }"
        @click="
          infoList
            ? toDetail(2, 'ZQP_QK1', infoList.ZQP_QK1_node_string, '', '负荷前馈给煤')
            : ''
        "
      >
        Q
      </div>
      <div
        class="bottonx fl q_3"
        :style="{
          background: !infoList.ZQP_QK2.TS ? '#2AFC30' : 'red',
        }"
        @click="
          infoList
            ? toDetail(2, 'ZQP_QK2', infoList.ZQP_QK1_node_string, '', '管网负荷前馈')
            : ''
        "
      >
        Q
      </div>
    </div>

    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>

     <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="node"
      :node1="node1"
      :Lkname="Lkname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal"></inputVal>
  </div>
</template>


<script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Rsf from "@/components/RSF.vue"; //阮伺服组件
import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
import Manual from "@/components/Manual1.vue"; //手操器组件
export default {
  name: "Param11",
  props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
  },
  components: { inputVal, Historical, Firstcontrol, Rsf, ParameterYh, Manual },
  data: () => {
    return {
      chName: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      isHshow: false,
      isMshow: false,
      isRshow: false,
      historyname: "",
      node: "",
      Lkname: "",
      isIndex: "",
      Firstcontrolname: "",
      Firstcontroltitname: "",
      Firstcontrolnode: "",
      isFshow: false,
      Rsfname: "",
      Rsfnode: "",
      Rsftitname: "",
      projectData: "",
      grouptime: null,
      spotArr: [],
      authInfo: [],
      UserInfo: [],
      node1:''
    };
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }
        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
          case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "mfkfirstcontol";
            break;
          case 3:
            pathname = "mfRfirstcontol";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    isshowfase() {
      this.isComShow = false;
    },
    closeCompon() {
      this.$emit("sendStatus", "isPshow02", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
      }
    },
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    toCompon(key, name, name2, name3, name4, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            return (
              (this.isMshow = true),
              (this.Manualname = name),
              (this.Manualnode = name2),
              (this.ManualAname = name3),
              (this.Manualtitname = titname)
            );
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (this.isHshow = true), (this.chName = name4),this.node1=titname;
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = name2),
              (this.Rsftitname = titname)
            );
        }
      }, 300);
    },
  },
};
</script>


<style lang="scss" scoped>
.jrlmqzg {
  width: 83vw;
  height: 78vh;
  //  background: #e9f7ff;
  background-image: url("~@/assets/images/jrl-param.png");
  background-size: 100% 100%;
  //  cursor:move;
  border-radius: 5px;
  color: #ffffff;

  .zhuangshi1 {
    width: 30vw;
    height: 10vh;
    background-color: #0ef7ff;
    opacity: 0.66;
  }
  // 上半模型
  .main {
    width: 56vw;
    height: 52.3vh;
    //  background: #e9f7ff;
    background-image: url("~@/assets/images/MFL_主汽压力切图.png");
    background-size: 100% 100%;
    //  cursor:move;
    border-radius: 5px;
    color: #ffffff;
    position: absolute;
    left: 12vw;
    top: 17vh;
    // 多行的
    .GFQWYZ {
      width: 8vw;
      height: 5.2vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: -8.2vw;
      top: -2.6vh;
      .row {
        width: 8.3vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .RQLL_PV {
      width: 8.3vw;
      height: 5.2vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: -8.2vw;
      top: 10.1vh;
      .row {
        width: 8.3vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .ZQP_SP_HL {
      width: 9vw;
      height: 5.2vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: -9vw;
      top: 20vh;
      .row {
        width: 9vw;
        height: 2.6vh;
        .col1 {
          width: 5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .ZQP_PV {
      width: 8.3vw;
      height: 5.2vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: -8.2vw;
      top: 27vh;
      .row {
        width: 8.3vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .ZQP_MV {
      width: 8.3vw;
      height: 5.2vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 30.5vw;
      top: 23vh;
      .row {
        width: 8.3vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .ZQF_DSP {
      width: 8.3vw;
      height: 10.4vh;
      background-color: #14384a;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 8vw;
      top: 38vh;
      .row {
        width: 8.3vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    // 单行的
    .ZQT_2A_SP {
      width: 9vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: -9.2vw;
      top: 4vh;
      .row {
        width: 9vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;
          margin-left: 1.4vw;cursor: pointer;
        }
      }
    }
    .ZQP_SP {
      width: 8.3vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: -8.2vw;
      top: 15.8vh;
      .row {
        width: 8.3vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .ZQP_XK6_AV {
      width: 9.8vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 11vw;
      top: 4vh;
      .row {
        width: 10vw;
        height: 2.6vh;
        .col1 {
          width: 5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 4.8vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 4.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .ZQF_TC {
      width: 8.3vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 8vw;
      top: 25vh;
      .row {
        width: 8.3vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .ZQF_PVL {
      width: 8.3vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 8vw;
      top: 32.7vh;
      .row {
        width: 8.3vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .QBP_TC {
      width: 8.3vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 17vw;
      top: 27vh;
      .row {
        width: 8.3vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .GML_E_PVB {
      width: 8vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 44vw;
      top: -2.2vh;
      .row {
        width: 8.5vw;
        height: 2.6vh;
        .col1 {
          width: 4.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .GML_D_PVB {
      width: 8vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 44vw;
      top: 15.8vh;
      .row {
        width: 8.5vw;
        height: 2.6vh;
        .col1 {
          width: 4.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .GML_C_PVB {
      width: 8vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 44vw;
      top: 26.7vh;
      .row {
        width: 8.5vw;
        height: 2.6vh;
        .col1 {
          width: 4.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .GML_B_PVB {
      width: 8vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 44vw;
      top: 41.1vh;
      .row {
        width: 8.5vw;
        height: 2.6vh;
        .col1 {
          width: 4.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .GML_A_PVB {
      width: 8vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 44vw;
      top: 52.4vh;
      .row {
        width: 8.5vw;
        height: 2.6vh;
        .col1 {
          width: 4.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .GML_E_SP {
      width: 8vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 56vw;
      top: 4vh;
      .row {
        width: 8.5vw;
        height: 2.6vh;
        .col1 {
          width: 4.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .GML_D_SP {
      width: 8vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 56vw;
      top: 10vh;
      .row {
        width: 8.5vw;
        height: 2.6vh;
        .col1 {
          width: 4.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .GML_C_SP {
      width: 8vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 56vw;
      top: 21vh;
      .row {
        width: 8.5vw;
        height: 2.6vh;
        .col1 {
          width: 4.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .GML_B_SP {
      width: 8vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 56vw;
      top: 35vh;
      .row {
        width: 8.5vw;
        height: 2.6vh;
        .col1 {
          width: 4.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    .GML_A_SP {
      width: 8vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 56vw;
      top: 46vh;
      .row {
        width: 8.5vw;
        height: 2.6vh;
        .col1 {
          width: 4.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;cursor: pointer;
        }
        .col3 {
          width: 3.5vw;
          font-family: PingFang-SC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;cursor: pointer;
        }
      }
    }
    // 按钮
    .x_1 {
      position: absolute;
      left: 2vw;
      top: 4vh;
    }
    .x_2 {
      position: absolute;
      left: 2vw;
      top: 16vh;
    }
    .x_3 {
      position: absolute;
      left: 2vw;
      top: 42vh;
    }
    .x_4 {
      position: absolute;
      left: 19.5vw;
      top: 42vh;
    }
    .x_5 {
      position: absolute;
      left: 44.7vw;
      top: 4.2vh;
    }
    .q_1 {
      position: absolute;
      left: 2vw;
      top: 11.5vh;
    }
    .q_2 {
      position: absolute;
      left: 6.9vw;
      top: 21.4vh;
    }
    .q_3 {
      position: absolute;
      left: 24.8vw;
      top: 28vh;
    }
  }

  // 按钮样式
  .bottonx {
    width: 1vw;
    height: 2vh;
    text-align: center;
    line-height: 2vh;
    border: 2px solid rgb(217, 243, 145);
    font-size: 1.7vh;
    cursor: pointer;
    // margin-right: 0.1vw;
    // color: #000000;
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .title-hang {
    height: 5vh;
    width: 53vw;
    font-family: MicrosoftYaHei;
    font-size: 1.8vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    padding-top: 0.5vh;
    // margin-top:vh;
  }
  .title-hang {
    width: 100%;
    height: 4vh;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-size: 3vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 4vh;
    letter-spacing: 0vh;
    color: #0ef7ff;
  }
  .titlelable {
    width: 83vw;
    height: 3vh;
    font-family: PingFang-SC-Regular;
    font-size: 23px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0.3vw;
    color: #d5fffe;
    text-align: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  .param11-header-icon {
    width: 2vw;
    height: 2vh;
    right: 1vw;
    margin-top: -5vh;
    cursor: pointer;
    position: absolute;
    left: 80vw;
    top: 6vh;
  }
}
</style>