var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"mflzrqt drag",attrs:{"id":"ZQTC"}},[_c('div',[_c('div',{staticClass:"title-hang",attrs:{"fl":""}},[_vm._v(_vm._s(_vm.projectData.project)+"_ZRQT_C")]),_c('div',{staticClass:"param11-header-icon",attrs:{"fr":""},on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"titlelable"},[_vm._v("再热汽温优化控制模型")]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"fl leftmain"},[_c('div',{staticClass:"row1"},[_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd"},[_vm._v("ZRQT_A_PV")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZRQT_A_PV',
                  _vm.infoList.ZRQT_A_PV_node_string,
                  'ZRQT_A_PV_BM',
                  '甲再热汽温过程值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZRQT_A_PV,
                  'ZRQT_A_PV',
                  _vm.infoList.ZRQT_A_PV_node_string,
                  'ZRQT_A_PV_BM'
                )}}},[_vm._v(" "+_vm._s('ZRQT_A_PV' in _vm.infoList? _vm.infoList.ZRQT_A_PV :0)+" ")])]),_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd"},[_vm._v("ZRQT_A_TC")]),_c('div',{staticClass:"fl valuex down",on:{"click":function($event){return _vm.toIpt(_vm.infoList.ZRQT_A_TC, 'ZRQT_A_TC', 'ZRQT_A_TC', _vm.infoList.ZRQT_A_TC_node_string)}}},[_vm._v(" "+_vm._s('ZRQT_A_TC' in _vm.infoList? _vm.infoList.ZRQT_A_TC :0)+" ")])]),_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd"},[_vm._v("ZRQT_A_PVL")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZRQT_A_PVL',
                  _vm.infoList.ZRQT_A_PVL_node_string,
                  'ZRQT_A_PVL_BM',
                  '再热汽A温度过程滤波值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZRQT_A_PVL,
                  'ZRQT_A_PVL',
                  _vm.infoList.ZRQT_A_PVL_node_string,
                  'ZRQT_A_PVL_BM'
                )}}},[_vm._v(" "+_vm._s('ZRQT_A_PVL' in _vm.infoList? _vm.infoList.ZRQT_A_PVL :0)+" ")])])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd"},[_vm._v("ZRQT_B_PV")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZRQT_B_PV',
                  _vm.infoList.ZRQT_B_PV_node_string,
                  'ZRQT_B_PV_BM',
                  '乙再热汽温过程值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZRQT_B_PV,
                  'ZRQT_B_PV',
                  _vm.infoList.ZRQT_B_PV_node_string,
                  'ZRQT_B_PV_BM'
                )}}},[_vm._v(" "+_vm._s('ZRQT_B_PV' in _vm.infoList? _vm.infoList.ZRQT_B_PV :0)+" ")])]),_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd"},[_vm._v("ZRQT_B_TC")]),_c('div',{staticClass:"fl valuex down",on:{"click":function($event){return _vm.toIpt(_vm.infoList.ZRQT_B_TC, 'ZRQT_B_TC', 'ZRQT_B_TC', _vm.infoList.ZRQT_B_TC_node_string)}}},[_vm._v(" "+_vm._s('ZRQT_B_TC' in _vm.infoList? _vm.infoList.ZRQT_B_TC :0)+" ")])]),_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd"},[_vm._v("ZRQT_B_PVL")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZRQT_B_PVL',
                  _vm.infoList.ZRQT_B_PVL_node_string,
                  'ZRQT_B_PVL_BM',
                  '再热汽B温度过程滤波值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZRQT_B_PVL,
                  'ZRQT_B_PVL',
                  _vm.infoList.ZRQT_B_PVL_node_string,
                  'ZRQT_B_PVL_BM'
                )}}},[_vm._v(" "+_vm._s('ZRQT_B_PVL' in _vm.infoList? _vm.infoList.ZRQT_B_PVL :0)+" ")])])]),_c('div',{staticClass:"row3"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"fl widthd1"},[_vm._v("ZRQT_SPB")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZRQT_SPB2',
                  _vm.infoList.ZRQT_SPB2_node_string,
                  'ZRQT_SPB_BM',
                  '再热器蒸汽流量SP'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZRQT_SPB2,
                  'ZRQT_SPB2',
                  _vm.infoList.ZRQT_SPB2_node_string,
                  'ZRQT_SPB_BM'
                )}}},[_vm._v(" "+_vm._s('ZRQT_SPB2' in _vm.infoList? _vm.infoList.ZRQT_SPB2 :0)+" ")])]),_c('div',{staticClass:"fl bottonx",style:({
              background: !_vm.infoList.ZRQ_RSF1.TS ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.ZRQ_RSF1
                ? _vm.toDetail(
                    3,
                    'ZRQ_RSF1',
                    _vm.infoList.ZRQ_RSF1_node_string,
                    '',
                    '再热器流量补补偿'
                  )
                : ''}}},[_vm._v(" R ")])])]),_c('div',{staticClass:"centermain fl"},[_c('div',{staticClass:"bottonx centerjg1",style:({
            background: !_vm.infoList.ZQT_XK9.RM ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.ZQT_XK9
              ? _vm.toDetail(1, 'ZQT_XK9', _vm.infoList.ZQT_XK9_node_string, '', '再热汽烟气挡板')
              : ''}}},[_vm._v(" X ")]),_c('div',{staticStyle:{"width":"20vw","height":"13.5vh"}},[_c('div',{staticClass:"row1 centerjg2 fl"},[_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd"},[_vm._v("ZRQT_A_SP")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'ZRQT_A_SP',
                    _vm.infoList.ZRQT_A_SP_node_string,
                    'ZRQT_A_SP_BM',
                    '甲再热汽温设定值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.ZRQT_A_SP,
                    'ZRQT_A_SP',
                    _vm.infoList.ZRQT_A_SP_node_string,
                    'ZRQT_A_SP_BM'
                  )}}},[_vm._v(" "+_vm._s('ZRQT_A_SP' in _vm.infoList? _vm.infoList.ZRQT_A_SP :0)+" ")])]),_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd"},[_vm._v("ZRQT_A_SPH")]),_c('div',{staticClass:"fl valuex down",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.ZRQT_A_SP_HL,
                    'ZRQT_A_SPH',
                    'ZRQT_A_SP_HL',
                    _vm.infoList.ZRQT_A_SP_HL_node_string
                  )}}},[_vm._v(" "+_vm._s('ZRQT_A_SP_HL' in _vm.infoList? _vm.infoList.ZRQT_A_SP_HL :0)+" ")])]),_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd"},[_vm._v("ZRQT_A_SPL")]),_c('div',{staticClass:"fl valuex down",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.ZRQT_A_SP_LL,
                    'ZRQT_A_SPL',
                    'ZRQT_A_SP_LL',
                    _vm.infoList.ZRQT_A_SP_LL_node_string
                  )}}},[_vm._v(" "+_vm._s('ZRQT_A_SP_LL' in _vm.infoList? _vm.infoList.ZRQT_A_SP_LL :0)+" ")])])]),_c('div',{staticClass:"fl bottonx centerjg5",style:({
              background:
			  'ZQT_MAN5'||'ZQT_MAN6'||'ZQT_MAN3'||'ZQT_MAN4' in _vm.infoList?
                _vm.infoList.ZQT_MAN5.RM == 1 ||
                _vm.infoList.ZQT_MAN6.RM == 1 ||
                _vm.infoList.ZQT_MAN3.RM == 1 ||
                _vm.infoList.ZQT_MAN4.RM == 1
                  ? '#2AFC30'
                  : 'red': 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANZR', 2)}}},[_vm._v(" A ")])]),_c('div',{staticClass:"bottonx centerjg3",style:({
            background: !_vm.infoList.ZQT_XK10.RM ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.ZQT_XK10
              ? _vm.toDetail(1, 'ZQT_XK10', _vm.infoList.ZQT_XK10_node_string, '', '再热汽流量控制')
              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"row1 centerjg4"},[_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd"},[_vm._v("ZRQT_B_SP")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZRQT_B_SP',
                  _vm.infoList.ZRQT_B_SP_node_string,
                  'ZRQT_B_SP_BM',
                  '乙再热汽温设定值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZRQT_B_SP,
                  'ZRQT_B_SP',
                  _vm.infoList.ZRQT_B_SP_node_string,
                  'ZRQT_B_SP_BM'
                )}}},[_vm._v(" "+_vm._s('ZRQT_B_SP' in _vm.infoList? _vm.infoList.ZRQT_B_SP :0)+" ")])]),_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd"},[_vm._v("ZRQT_B_SPH")]),_c('div',{staticClass:"fl valuex down",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZRQT_B_SP_HL,
                  'ZRQT_B_SPH',
                  'ZRQT_B_SP_HL',
                  _vm.infoList.ZRQT_B_SP_HL_node_string
                )}}},[_vm._v(" "+_vm._s('ZRQT_B_SP_HL' in _vm.infoList? _vm.infoList.ZRQT_B_SP_HL :0)+" ")])]),_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd"},[_vm._v("ZRQT_B_SPL")]),_c('div',{staticClass:"fl valuex down",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZRQT_A_SP_LL,
                  'ZRQT_B_SPL',
                  'ZRQT_A_SP_LL',
                  _vm.infoList.ZRQT_A_SP_LL_node_string
                )}}},[_vm._v(" "+_vm._s('ZRQT_A_SP_LL' in _vm.infoList? _vm.infoList.ZRQT_A_SP_LL :0)+" ")])])])]),_c('div',{staticClass:"fl rightmain"},[_c('div',{staticClass:"row11"},[_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd2"},[_vm._v("ZRQT_MAN5")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZRQT_A_PV',
                  _vm.infoList.ZRQT_A_PV_node_string,
                  'ZRQT_A_PV_BM',
                  '甲再热汽温过程值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZRQT_A_PV,
                  'ZRQT_A_PV',
                  _vm.infoList.ZRQT_A_PV_node_string,
                  'ZRQT_A_PV_BM'
                )}}},[_vm._v(" "+_vm._s('ZRQT_A_PV' in _vm.infoList? _vm.infoList.ZRQT_A_PV :0)+" ")])]),_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd2"},[_vm._v("ZRQT_MVA_HL")]),_c('div',{staticClass:"fl valuex down",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_MAN5.OutT,
                  'ZRQT_MVA_HL',
                  'OutT',
                  _vm.infoList.ZQT_MAN5_node_string
                )}}},[_vm._v(" "+_vm._s('ZQT_MAN5' in _vm.infoList? _vm.infoList.ZQT_MAN5.OutT :0)+" ")])]),_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd2"},[_vm._v("ZRQT_MVA_LL")]),_c('div',{staticClass:"fl valuex down",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_MAN5.OutB,
                  'ZRQT_MVA_LL',
                  'OutB',
                  _vm.infoList.ZQT_MAN5_node_string
                )}}},[_vm._v(" "+_vm._s('ZQT_MAN5' in _vm.infoList? _vm.infoList.ZQT_MAN5.OutB :0)+" ")])])]),_c('div',{staticClass:"row21"},[_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd2"},[_vm._v("ZRQT_MAN6")]),_c('div',{staticClass:"fl valuex",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZRQT_B_MV',
                  _vm.infoList.ZRQT_B_MV_node_string,
                  'ZRQT_B_MV_BM',
                  '乙再热汽温XK输出'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ZRQT_B_MV,
                  'ZRQT_B_MV',
                  _vm.infoList.ZRQT_B_MV_node_string,
                  'ZRQT_B_MV_BM'
                )}}},[_vm._v(" "+_vm._s('ZRQT_B_MV' in _vm.infoList? _vm.infoList.ZRQT_B_MV :0)+" ")])]),_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd2"},[_vm._v("ZRQT_MVB_HL")]),_c('div',{staticClass:"fl valuex down",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_MAN6.OutT,
                  'ZRQT_MVB_HL',
                  'OutT',
                  _vm.infoList.ZQT_MAN6_node_string
                )}}},[_vm._v(" "+_vm._s('ZQT_MAN6' in _vm.infoList? _vm.infoList.ZQT_MAN6.OutT :0)+" ")])]),_c('div',{staticClass:"row1-spon"},[_c('div',{staticClass:"fl widthd2"},[_vm._v("ZRQT_MVB_LL")]),_c('div',{staticClass:"fl valuex down",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ZQT_MAN6.OutB,
                  'ZRQT_MVB_LL',
                  'OutB',
                  _vm.infoList.ZQT_MAN6_node_string
                )}}},[_vm._v(" "+_vm._s('ZQT_MAN6' in _vm.infoList? _vm.infoList.ZQT_MAN6.OutB :0)+" ")])])])]),_c('div')]),_c('div'),(_vm.mflmanyManual)?_c('mflmanyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"mark":_vm.Manualmark,"manvar":_vm.Manualvar,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose1}}):_vm._e(),_c('inputVal',{ref:"inputVal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }