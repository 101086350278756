<template>
  <div>
    <div class="mflindex">
      <div class="title">
        {{ projectData.project }}
        <!-- 煤粉锅炉燃烧优化控制系统 -->
      </div>
      <!-- 主体部分 -->
      <!-- 按照竖着排序的形式进行开发 -->
      <div class="main">
        <!-- 汽包左侧 -->
        <div class="leftmain fl">
          <div class="left-widthx1 color1 left-jg">正平衡</div>
          <div class="left-widthx1 color1 left-jg1">反平衡</div>
          <div class="left-widthx1 color1 left-jg2">汽压SP</div>
          <div class="left-widthx1 color1 left-jg3">汽压PV</div>
          <div class="left-widthx1 color1 left-jg4">负荷PV</div>
          <div class="left-widthx1 color1 left-jg4">分配负荷</div>
          <div class="left-widthx2 left-jg5" @click="toCompon(5, 'E', '5')">
            E磨
          </div>
          <div>
            <div
              class="bottonx fl"
              :style="{
                background:
                  infoList.GMJ_XK5.RM && !infoList.GMJ_XK5.RM == 1
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.GMJ_XK5
                  ? toDetail(
                      1,
                      'GMJ_XK5',
                      infoList.GMJ_XK5_node_string,
                      '',
                      'E给煤机先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background: !infoList.MFL_XK5.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.MFL_XK5
                  ? toDetail(
                      1,
                      'MFL_XK5',
                      infoList.MFL_XK5_node_string,
                      '',
                      'E磨风量先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background: !infoList.CKT_XK5.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.CKT_XK5
                  ? toDetail(
                      1,
                      'CKT_XK5',
                      infoList.CKT_XK5_node_string,
                      '',
                      'E磨出口温度先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background:
                  'GMJ_MAN5' || 'MFL_MAN5' || 'CKT_MAN5' in infoList
                    ? infoList.GMJ_MAN5.RM == 1 ||
                      infoList.MFL_MAN5.RM == 1 ||
                      infoList.CKT_MAN5.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }"
              @click="toCompon(0, 'MFL_MANEM', 3, 'E', '5')"
            >
              A
            </div>
          </div>
          <div
            class="left-widthx2 left-jg6"
            @click="toCompon(5, 'D', '4', '油压', 'MYP')"
          >
            D磨
          </div>
          <div>
            <div
              class="bottonx fl"
              :style="{
                background: !infoList.GMJ_XK4.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.GMJ_XK4
                  ? toDetail(
                      1,
                      'GMJ_XK4',
                      infoList.GMJ_XK4_node_string,
                      '',
                      'D给煤机先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background: !infoList.MFL_XK4.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.MFL_XK4
                  ? toDetail(
                      1,
                      'MFL_XK4',
                      infoList.MFL_XK4_node_string,
                      '',
                      'D磨风量先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background: !infoList.CKT_XK4.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.CKT_XK4
                  ? toDetail(
                      1,
                      'CKT_XK4',
                      infoList.CKT_XK4_node_string,
                      '',
                      'D磨出口温度先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background:
                  'GMJ_MAN4' || 'MFL_MAN4' || 'CKT_MAN4' in infoList
                    ? infoList.GMJ_MAN4.RM == 1 ||
                      infoList.MFL_MAN4.RM == 1 ||
                      infoList.CKT_MAN4.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }"
              @click="toCompon(0, 'MFL_MANDM', 3, 'D', '4')"
            >
              A
            </div>
          </div>
          <div class="left-widthx2 left-jg6" @click="toCompon(5, 'C', '3')">
            C磨
          </div>
          <div>
            <div
              class="bottonx fl"
              :style="{
                background: !infoList.GMJ_XK3.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.GMJ_XK3
                  ? toDetail(
                      1,
                      'GMJ_XK3',
                      infoList.GMJ_XK3_node_string,
                      '',
                      'C给煤机先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background: !infoList.MFL_XK3.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.MFL_XK3
                  ? toDetail(
                      1,
                      'MFL_XK3',
                      infoList.MFL_XK3_node_string,
                      '',
                      'C磨风量先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background: !infoList.CKT_XK3.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.CKT_XK3
                  ? toDetail(
                      1,
                      'CKT_XK3',
                      infoList.CKT_XK3_node_string,
                      '',
                      'C磨出口温度先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background:
                  'GMJ_MAN3' || 'MFL_MAN3' || 'CKT_MAN3' in infoList
                    ? infoList.GMJ_MAN3.RM == 1 ||
                      infoList.MFL_MAN3.RM == 1 ||
                      infoList.CKT_MAN3.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }"
              @click="toCompon(0, 'MFL_MANCM', 3, 'C', '3')"
            >
              A
            </div>
          </div>
          <div class="left-widthx2 left-jg6" @click="toCompon(5, 'B', '2')">
            B磨
          </div>
          <div>
            <div
              class="bottonx fl"
              :style="{
                background: !infoList.GMJ_XK2.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.GMJ_XK2
                  ? toDetail(
                      1,
                      'GMJ_XK2',
                      infoList.GMJ_XK2_node_string,
                      '',
                      'B给煤机先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background: !infoList.MFL_XK2.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.MFL_XK2
                  ? toDetail(
                      1,
                      'MFL_XK2',
                      infoList.MFL_XK2_node_string,
                      '',
                      'B磨风量先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background: !infoList.CKT_XK2.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.CKT_XK2
                  ? toDetail(
                      1,
                      'CKT_XK2',
                      infoList.CKT_XK2_node_string,
                      '',
                      'B磨出口温度先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background:
                  'GMJ_MAN2' || 'MFL_MAN2' || 'CKT_MAN2' in infoList
                    ? infoList.GMJ_MAN2.RM == 1 ||
                      infoList.MFL_MAN2.RM == 1 ||
                      infoList.CKT_MAN2.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }"
              @click="toCompon(0, 'MFL_MANBM', 3, 'B', '2')"
            >
              A
            </div>
          </div>
          <div class="left-widthx2 left-jg6" @click="toCompon(5, 'A', '1')">
            A磨
          </div>
          <div>
            <div
              class="bottonx fl"
              :style="{
                background: !infoList.GMJ_XK1.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.GMJ_XK1
                  ? toDetail(
                      1,
                      'GMJ_XK1',
                      infoList.GMJ_XK1_node_string,
                      '',
                      'A给煤机先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background: !infoList.MFL_XK1.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.MFL_XK1
                  ? toDetail(
                      1,
                      'MFL_XK1',
                      infoList.MFL_XK1_node_string,
                      '',
                      'A磨风量先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background: !infoList.CKT_XK1.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.CKT_XK1
                  ? toDetail(
                      1,
                      'CKT_XK1',
                      infoList.CKT_XK1_node_string,
                      '',
                      'A磨出口温度先控调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background:
                  'GMJ_MAN1' || 'MFL_MAN1' || 'CKT_MAN1' in infoList
                    ? infoList.GMJ_MAN1.RM == 1 ||
                      infoList.MFL_MAN1.RM == 1 ||
                      infoList.CKT_MAN1.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }"
              @click="toCompon(0, 'MFL_MANAM', 3, 'A', '1')"
            >
              A
            </div>
          </div>
          <div
            class="left-widthx2 left-jg6"
            style="margin-top: 5vh"
            @click="toCompon(14)"
          >
            再热汽温
          </div>
          <div>
            <div
              class="bottonx fl"
              :style="{
                background: !infoList.ZQT_XK9.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.ZQT_XK9
                  ? toDetail(
                      1,
                      'ZQT_XK9',
                      infoList.ZQT_XK9_node_string,
                      '',
                      '再热汽烟气挡板'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background: !infoList.ZQT_XK10.RM ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.ZQT_XK10
                  ? toDetail(
                      1,
                      'ZQT_XK10',
                      infoList.ZQT_XK10_node_string,
                      '',
                      '再热汽流量控制'
                    )
                  : ''
              "
            >
              X
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background: !infoList.ZRQ_RSF1.TS ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.ZRQ_RSF1
                  ? toDetail(
                      3,
                      'ZRQ_RSF1',
                      infoList.ZRQ_RSF1_node_string,
                      '',
                      '再热器流量补补偿'
                    )
                  : ''
              "
            >
              R
            </div>
            <div
              class="bottonx fl buttonjg"
              :style="{
                background:
                  'ZQT_MAN5' || 'ZQT_MAN6' in infoList
                    ? infoList.ZQT_MAN5.RM == 1 || infoList.ZQT_MAN6.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }"
              @click="toCompon(0, 'MFL_MANZR', 2)"
            >
              A
            </div>
          </div>
        </div>
        <!-- 中间体部分 -->
        <div class="fl maincenter">
          <div class="fl">
            <!-- 水位显示部分 -->
            <div class="waterline">
              <div class="shuiweisp">
                <div class="fl widthx1 color1 col1jg">水位SP</div>
                <div
                  class="fl valuex color2"
                  @click="
                    toCompon(
                      2,
                      'QBW_SP',
                      infoList.QBW_SP_node_string,
                      'QBW_SP_BM',
                      '水位设定值'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.QBW_SP,
                      'QBW_SP',
                      infoList.QBW_SP_node_string,
                      'QBW_SP_BM'
                    )
                  "
                >
                  {{ "QBW_SP" in infoList ? infoList.QBW_SP : 0 }}mm
                </div>
                <!-- <div class="fl danwei">mm</div> -->
              </div>
              <div class="col1jg1">
                <div
                  style="margin-left: 0.8vw"
                  class="fl widthxx"
                  :style="{
                    background: infoList.QBW_SEL == 1 ? '#2AFC30' : 'red',
                  }"
                  @click="
                    toIpt(
                      '1',
                      '水位1',
                      'QBW_SEL',
                      infoList.QBW_SEL_node_string,
                      'true'
                    )
                  "
                >
                  水位1
                </div>
                <div
                  class="fl valuex"
                  @click="
                    toCompon(
                      2,
                      'QBW_A_PV',
                      infoList.QBW_A_PV_node_string,
                      'QBW_A_PV_BM',
                      '汽包水位过程值1'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.QBW_A_PV,
                      'QBW_A_PV',
                      infoList.QBW_A_PV_node_string,
                      'QBW_A_PV_BM'
                    )
                  "
                >
                  {{ "QBW_A_PV" in infoList ? infoList.QBW_A_PV : 0 }}mm
                </div>
                <!-- <div class="fl danwei">mm</div> -->
              </div>
              <div class="col1jg2">
                <div
                  class="fl valuex"
                  @click="
                    toCompon(
                      2,
                      'RSXLSS',
                      infoList.RSXLSS_node_string,
                      'RSXLSS_BM',
                      '燃烧效率'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.RSXLSS,
                      'RSXLSS',
                      infoList.RSXLSS_node_string,
                      'RSXLSS_BM'
                    )
                  "
                >
                  {{ "RSXLSS" in infoList ? infoList.RSXLSS : 0 }}
                </div>
                <div
                  style="margin-left: 0.8vw"
                  class="fl widthxx"
                  :style="{
                    background: !infoList.QBW_SEL == 2 ? '#2AFC30' : 'red',
                  }"
                  @click="
                    toIpt(
                      '2',
                      '水位2',
                      'QBW_SEL',
                      infoList.QBW_SEL_node_string,
                      'true'
                    )
                  "
                >
                  水位2
                </div>
                <div
                  class="fl valuex"
                  @click="
                    toCompon(
                      2,
                      'QBW_B_PV',
                      infoList.QBW_B_PV_node_string,
                      'QBW_B_PV_BM',
                      '汽包水位过程值2'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.QBW_B_PV,
                      'QBW_B_PV',
                      infoList.QBW_B_PV_node_string,
                      'QBW_B_PV_BM'
                    )
                  "
                >
                  {{ "QBW_B_PV" in infoList ? infoList.QBW_B_PV : 0 }}mm
                </div>
                <!-- <div class="fl danwei">mm</div> -->
              </div>
              <div class="col1jg2">
                <div
                  class="fl valuex"
                  @click="
                    toCompon(
                      2,
                      'q1',
                      infoList.q1_node_string,
                      'q1_BM',
                      '锅炉效率'
                    )
                  "
                  @dblclick="
                    Cclick(infoList.q1, 'q1', infoList.q1_node_string, 'q1_BM')
                  "
                >
                  {{ "q1" in infoList ? infoList.q1 : 0 }}
                </div>
                <div
                  style="margin-left: 0.8vw"
                  class="fl widthxx"
                  :style="{
                    background: !infoList.QBW_SEL == 3 ? '#2AFC30' : 'red',
                  }"
                  @click="
                    toIpt(
                      '3',
                      '水位3',
                      'QBW_SEL',
                      infoList.QBW_SEL_node_string,
                      'true'
                    )
                  "
                >
                  水位3
                </div>
                <div
                  class="fl valuex"
                  @click="
                    toCompon(
                      2,
                      'QBW_C_PV',
                      infoList.QBW_C_PV_node_string,
                      'QBW_C_PV_BM',
                      '汽包水位过程值3'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.QBW_C_PV,
                      'QBW_C_PV',
                      infoList.QBW_C_PV_node_string,
                      'QBW_C_PV_BM'
                    )
                  "
                >
                  {{ "QBW_C_PV" in infoList ? infoList.QBW_C_PV : 0 }}mm
                </div>
                <!-- <div class="fl danwei">mm</div> -->
              </div>
            </div>
            <!-- 控制模式 -->
            <div class="control-model control-jg4">
              <div style="height: 3vh; line-height: 3vh">
                <div class="control-col1 fl color1" @click="toCompon(8)">
                  控制模式
                </div>
                <div
                  class="control-col1x fl color3"
                  :style="{
                    background: !infoList.MGM_SE ? '#2AFC30' : 'red',
                  }"
                >
                  {{
                    "MGM_SE" in infoList
                      ? infoList.MGM_SEL
                        ? "母管模式"
                        : "单炉模式"
                      : '单炉模式'
                  }}
                </div>
              </div>
              <div class="control-col2">
                <div
                  class="fl valuex"
                  @click="
                    toCompon(
                      2,
                      'ZQP_SP',
                      infoList.ZQP_SP_node_string,
                      'ZQP_SP_BM',
                      '主汽压力控制点'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.ZQP_SP,
                      'ZQP_SP',
                      infoList.ZQP_SP_node_string,
                      'ZQP_SP_BM'
                    )
                  "
                >
                  {{ "ZQP_SP" in infoList ? infoList.ZQP_SP : 0 }}
                </div>
                <div class="fl danwei">MPa</div>
                <div
                  class="bottonx fl control-jg"
                  :style="{
                    background: !infoList.ZQP_QK1.TS ? '#2AFC30' : 'red',
                  }"
                  @click="
                    infoList.ZQP_QK1
                      ? toDetail(
                          2,
                          'ZQP_QK1',
                          infoList.ZQP_QK1_node_string,
                          '',
                          '负荷前馈给煤'
                        )
                      : ''
                  "
                >
                  Q
                </div>
                <div
                  class="bottonx fl control-jg1"
                  :style="{
                    background: !infoList.ZQP_QK2.TS ? '#2AFC30' : 'red',
                  }"
                  @click="
                    infoList.ZQP_QK2
                      ? toDetail(
                          2,
                          'ZQP_QK2',
                          infoList.ZQP_QK2_node_string,
                          '',
                          '管网负荷前馈'
                        )
                      : ''
                  "
                >
                  Q
                </div>

                <div
                  class="bottonx fl control-jg1"
                  :style="{
                    background: !infoList.ZQP_QK3.TS ? '#2AFC30' : 'red',
                  }"
                  @click="
                    infoList.ZQP_QK3
                      ? toDetail(
                          2,
                          'ZQP_QK3',
                          infoList.ZQP_QK3_node_string,
                          '',
                          '燃料气前馈'
                        )
                      : ''
                  "
                >
                  Q
                </div>
              </div>
              <div class="control-col2">
                <div
                  class="fl valuex"
                  @click="
                    toCompon(
                      2,
                      'ZQP_PV',
                      infoList.ZQP_PV_node_string,
                      'ZQP_PV_BM',
                      '主汽压力测量值'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.ZQP_PV,
                      'ZQP_PV',
                      infoList.ZQP_PV_node_string,
                      'ZQP_PV_BM'
                    )
                  "
                >
                  {{ "ZQP_PV" in infoList ? infoList.ZQP_PV : 0 }}
                </div>
                <div class="fl danwei">MPa</div>
                <div
                  class="bottonx fl control-jg"
                  :style="{
                    background: !infoList.ZQP_XK2.RM ? '#2AFC30' : 'red',
                  }"
                  @click="
                    infoList.ZQP_XK2
                      ? toDetail(
                          1,
                          'ZQP_XK2',
                          infoList.ZQP_XK2_node_string,
                          '',
                          '母管速率动态调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx fl control-jg1"
                  :style="{
                    background: !infoList.ZQP_XK1.RM ? '#2AFC30' : 'red',
                  }"
                  @click="
                    infoList.ZQP_XK1
                      ? toDetail(
                          1,
                          'ZQP_XK1',
                          infoList.ZQP_XK1_node_string,
                          '',
                          '主汽压力静态调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx fl control-jg1"
                  :style="{
                    background: !infoList.GMJ_XK1.RM ? '#2AFC30' : 'red',
                  }"
                  @click="
                    infoList.ZQF_XK1
                      ? toDetail(
                          1,
                          'ZQF_XK1',
                          infoList.GMJ_XK1_node_string,
                          '',
                          '主汽流量先控调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
              </div>
              <div class="control-col2">
                <div
                  class="fl valuex"
                  @click="
                    toCompon(
                      2,
                      'ZQF_PV',
                      infoList.ZQF_PV_node_string,
                      'ZQF_PV_BM',
                      '蒸汽流量'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.ZQF_PV,
                      'ZQF_PV',
                      infoList.ZQF_PV_node_string,
                      'ZQF_PV_BM'
                    )
                  "
                >
                  {{ "ZQF_PV" in infoList ? infoList.ZQF_PV : 0 }}
                </div>
                <div class="fl danwei">t/h</div>
                <div
                  class="fl valuex control-jg2"
                  @click="
                    toCompon(
                      2,
                      'ZQP_MV',
                      infoList.ZQP_MV_node_string,
                      'ZQP_MV_BM',
                      '给煤层操指令'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.ZQP_MV,
                      'ZQP_MV',
                      infoList.ZQP_MV_node_string,
                      'ZQF_PV_BM'
                    )
                  "
                >
                  {{ "ZQP_MV" in infoList ? infoList.ZQP_MV : 0 }}
                </div>
                <div class="fl danwei">t/h</div>
              </div>
              <div class="control-col2">
                <div
                  class="fl valuex"
                  @click="
                    toCompon(
                      2,
                      'MGF_SP',
                      infoList.MGF_SP_node_string,
                      'MGF_SP_BM',
                      '母管分配负荷量'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.MGF_SP,
                      'MGF_SP',
                      infoList.MGF_SP_node_string,
                      'MGF_SP_BM'
                    )
                  "
                >
                  {{ "MGF_SP" in infoList ? infoList.MGF_SP : 0 }}
                </div>
                <div class="fl danwei">t/h</div>
                <div
                  class="fl valuex control-jg2"
                  @click="
                    toCompon(
                      2,
                      'MGP_SL',
                      infoList.MGP_SL_node_string,
                      'MGP_SL_SM',
                      '母管压力速率变化'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.MGP_SL,
                      'MGP_SL',
                      infoList.MGP_SL_node_string,
                      'MGP_SL_SM'
                    )
                  "
                >
                  {{ "MGP_SL" in infoList ? infoList.MGP_SL : 0 }}
                </div>
                <div class="fl danwei">t/h</div>
              </div>
            </div>
            <!-- 磨管道开关流量 -->
            <div class="molgd">
              <div class="molgd-col1">
                <div
                  class="fl valuex"
                  @click="
                    toCompon(
                      2,
                      'GML_E_PV',
                      infoList.GML_E_PV_node_string,
                      'GML_E_PV_BM',
                      '5#给煤量过程值'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.GML_E_PV,
                      'GML_E_PV',
                      infoList.GML_E_PV_node_string,
                      'GML_E_PV_BM'
                    )
                  "
                >
                  {{ "GML_E_PV" in infoList ? infoList.GML_E_PV : 0 }}
                </div>
                <div class="fl danwei">t/h</div>
              </div>
              <div class="molgd-col1 molgd-jg">
                <div
                  class="fl valuex"
                  @click="
                    toCompon(
                      2,
                      'GML_D_PV',
                      infoList.GML_D_PV_node_string,
                      'GML_D_PV_BM',
                      '4#给煤量过程值'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.GML_D_PV,
                      'GML_D_PV',
                      infoList.GML_D_PV_node_string,
                      'GML_D_PV_BM'
                    )
                  "
                >
                  {{ "GML_D_PV" in infoList ? infoList.GML_D_PV : 0 }}
                </div>
                <div class="fl danwei">t/h</div>
              </div>
              <div class="molgd-col1 molgd-jg">
                <div
                  class="fl valuex"
                  @click="
                    toCompon(
                      2,
                      'GML_C_PV',
                      infoList.GML_C_PV_node_string,
                      'GML_C_PV_BM',
                      '3#给煤量过程值'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.GML_C_PV,
                      'GML_C_PV',
                      infoList.GML_C_PV_node_string,
                      'GML_C_PV_BM'
                    )
                  "
                >
                  {{ "GML_C_PV" in infoList ? infoList.GML_C_PV : 0 }}
                </div>
                <div class="fl danwei">t/h</div>
              </div>
              <div class="molgd-col1 molgd-jg">
                <div
                  class="fl valuex"
                  @click="
                    toCompon(
                      2,
                      'GML_B_PV',
                      infoList.GML_B_PV_node_string,
                      'GML_B_PV_BM',
                      '2#给煤量过程值'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.GML_B_PV,
                      'GML_B_PV',
                      infoList.GML_B_PV_node_string,
                      'GML_B_PV_BM'
                    )
                  "
                >
                  {{ "GML_B_PV" in infoList ? infoList.GML_B_PV : 0 }}
                </div>
                <div class="fl danwei">t/h</div>
              </div>
              <div class="molgd-col1 molgd-jg">
                <div
                  class="fl valuex"
                  @click="
                    toCompon(
                      2,
                      'GML_A_PV',
                      infoList.GML_A_PV_node_string,
                      'GML_A_PV_BM',
                      '1#给煤量过程值'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.GML_A_PV,
                      'GML_A_PV',
                      infoList.GML_A_PV_node_string,
                      'GML_A_PV_BM'
                    )
                  "
                >
                  {{ "GML_A_PV" in infoList ? infoList.GML_A_PV : 0 }}
                </div>
                <div class="fl danwei">t/h</div>
              </div>
            </div>
          </div>
          <!-- 汽包和炉膛内部 -->
          <div class="fl">
            <!-- 汽包 -->
            <div class="qbmain">
              <div
                class="fl qb-col2"
                @click="
                  toCompon(
                    2,
                    'QBW_PV',
                    infoList.QBW_PV_node_string,
                    'QBW_PV_BM',
                    '水位过程值'
                  )
                "
              >
                <dv-water-level-pond
                  :config="config"
                  style="width: 110px; height: 110px"
                />
              </div>
            </div>
            <!-- 炉膛 -->
            <div class="lutang">
              <div class="lutang-col1">
                <div class="fl widthx1">负压 SP</div>
                <div
                  class="fl valuex color2 lutang-jg2"
                  @click="
                    toCompon(
                      2,
                      'LTP_SP',
                      infoList.LTP_SP_node_string,
                      'LTP_SP_BM',
                      '炉膛压力设定值'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.LTP_SP,
                      'LTP_SP',
                      infoList.LTP_SP_node_string,
                      'LTP_SP_BM'
                    )
                  "
                >
                  {{ "LTP_SP" in infoList ? infoList.LTP_SP : 0 }}
                </div>
                <div class="fl danwei">Pa</div>
              </div>
              <div class="lutang-col1 lutang-jg1">
                <div
                  class="fl valuex fu-color"
                  @click="
                    toCompon(
                      2,
                      'LTP_A_PV',
                      infoList.LTP_A_PV_node_string,
                      'LTP_A_PV_BM',
                      '炉膛压力过程值1'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.LTP_A_PV,
                      'LTP_A_PV',
                      infoList.LTP_A_PV_node_string,
                      'LTP_A_PV_BM'
                    )
                  "
                >
                  {{ "LTP_A_PV" in infoList ? infoList.LTP_A_PV : 0 }}
                </div>
                <div class="fl danwei fu-color">Pa</div>
                <div
                  class="fl valuex fu-color lutang-jg"
                  @click="
                    toCompon(
                      2,
                      'LTP_B_PV',
                      infoList.LTP_B_PV_node_string,
                      'LTP_B_PV_BM',
                      '炉膛压力过程值2'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.LTP_B_PV,
                      'LTP_B_PV',
                      infoList.LTP_B_PV_node_string,
                      'LTP_B_PV_BM'
                    )
                  "
                >
                  {{ "LTP_B_PV" in infoList ? infoList.LTP_B_PV : 0 }}
                </div>
                <div class="fl danwei fu-color">Pa</div>
              </div>
              <div class="lutang-col1 lutang-jg1">
                <div
                  class="fl widthx1"
                  :style="{
                    background: infoList.LTP_SEL == 1 ? '#2AFC30' : 'red',
                  }"
                  @click="
                    toIpt(
                      '1',
                      '负压1',
                      'LTP_SEL',
                      infoList.LTP_SEL_node_string,
                      'true'
                    )
                  "
                >
                  负压1
                </div>
                <div
                  class="fl widthx1 lutang-jg"
                  :style="{
                    background: infoList.LTP_SEL == 2 ? '#2AFC30' : 'red',
                  }"
                  @click="
                    toIpt(
                      '2',
                      '负压2',
                      'LTP_SEL',
                      infoList.LTP_SEL_node_string,
                      'true'
                    )
                  "
                >
                  负压2
                </div>
              </div>
              <div class="lutang-col1 lutang-jg1">
                <div
                  class="fl valuex fu-color"
                  @click="
                    toCompon(
                      2,
                      'LTP_C_PV',
                      infoList.LTP_C_PV_node_string,
                      'LTP_C_PV_BM',
                      '炉膛压力过程值3'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.LTP_C_PV,
                      'LTP_C_PV',
                      infoList.LTP_C_PV_node_string,
                      'LTP_C_PV_BM'
                    )
                  "
                >
                  {{ "LTP_C_PV" in infoList ? infoList.LTP_C_PV : 0 }}
                </div>
                <div class="fl danwei fu-color">Pa</div>
                <div
                  class="fl valuex fu-color lutang-jg"
                  @click="
                    toCompon(
                      2,
                      'LTP_D_PV',
                      infoList.LTP_D_PV_node_string,
                      'LTP_D_PV_BM',
                      '炉膛压力过程值4'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.LTP_D_PV,
                      'LTP_D_PV',
                      infoList.LTP_D_PV_node_string,
                      'LTP_D_PV_BM'
                    )
                  "
                >
                  {{ "LTP_D_PV" in infoList ? infoList.LTP_D_PV : 0 }}
                </div>
                <div class="fl danwei fu-color">Pa</div>
              </div>
              <div class="lutang-col1 lutang-jg1">
                <div
                  class="fl widthx1"
                  :style="{
                    background: infoList.LTP_SEL == 3 ? '#2AFC30' : 'red',
                  }"
                  @click="
                    toIpt(
                      '3',
                      '负压3',
                      'LTP_SEL',
                      infoList.LTP_SEL_node_string,
                      'true'
                    )
                  "
                >
                  负压3
                </div>
                <div
                  class="fl widthx1 lutang-jg"
                  :style="{
                    background: infoList.LTP_SEL == 4 ? '#2AFC30' : 'red',
                  }"
                  @click="
                    toIpt(
                      '4',
                      '负压4',
                      'LTP_SEL',
                      infoList.LTP_SEL_node_string,
                      'true'
                    )
                  "
                >
                  负压4
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <!-- 过滤器以及下边部分 -->
            <div class="glqmain">
              <div class="glqtop flex">
                <div class="pingbi glqtop-jg">
                  <div style="height: 2.5vh">
                    <div
                      class="fl valuex lutang-jg2"
                      @click="
                        toCompon(
                          2,
                          'PGCKT_A_PV',
                          infoList.PGCKT_A_PV_node_string,
                          'PGCKT_A_PV_BM',
                          '屏过出口烟气温度左'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.PGCKT_A_PV,
                          'PGCKT_A_PV',
                          infoList.PGCKT_A_PV_node_string,
                          'PGCKT_A_PV_BM'
                        )
                      "
                    >
                      {{ "PGCKT_A_PV" in infoList ? infoList.PGCKT_A_PV : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                  </div>
                  <div>
                    <div
                      class="fl valuex lutang-jg2"
                      @click="
                        toCompon(
                          2,
                          'PGCKT_B_PV',
                          infoList.PGCKT_B_PV_node_string,
                          'PGCKT_B_PV_BM',
                          '屏过出口烟气温度右'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.PGCKT_B_PV,
                          'PGCKT_B_PV',
                          infoList.PGCKT_B_PV_node_string,
                          'PGCKT_B_PV_BM'
                        )
                      "
                    >
                      {{ "PGCKT_B_PV" in infoList ? infoList.PGCKT_B_PV : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                  </div>
                </div>
                <div class="gaowen glqtop-jg1">
                  <div style="height: 2.5vh">
                    <div
                      class="fl valuex lutang-jg2"
                      @click="
                        toCompon(
                          2,
                          'GGRQT_A_PV',
                          infoList.GGRQT_A_PV_node_string,
                          'GGRQT_A_PV_BM',
                          '高过出口烟气温度左'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.GGRQT_A_PV,
                          'GGRQT_A_PV',
                          infoList.GGRQT_A_PV_node_string,
                          'GGRQT_A_PV_BM'
                        )
                      "
                    >
                      {{ "GGRQT_A_PV" in infoList ? infoList.GGRQT_A_PV : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                  </div>
                  <div class="heighthx">
                    <div
                      class="fl valuex lutang-jg2"
                      @click="
                        toCompon(
                          2,
                          'GGRQT_B_PV',
                          infoList.GGRQT_B_PV_node_string,
                          'GGRQT_B_PV_BM',
                          '高过出口烟气温度右'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.GGRQT_B_PV,
                          'GGRQT_B_PV',
                          infoList.GGRQT_B_PV_node_string,
                          'GGRQT_B_PV_BM'
                        )
                      "
                    >
                      {{ "GGRQT_B_PV" in infoList ? infoList.GGRQT_B_PV : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                  </div>
                </div>
                <div class="diwen glqtop-jg2">
                  <div style="height: 2.5vh">
                    <div
                      class="fl valuex lutang-jg2"
                      @click="
                        toCompon(
                          2,
                          'DGRQT_A_PV',
                          infoList.DGRQT_A_PV_node_string,
                          'DGRQT_A_PV_BM',
                          '低过出口烟气温度左'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.GGRQT_A_PV,
                          'DGRQT_A_PV',
                          infoList.DGRQT_A_PV_node_string,
                          'DGRQT_A_PV_BM'
                        )
                      "
                    >
                      {{ "DGRQT_A_PV" in infoList ? infoList.DGRQT_A_PV : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                  </div>
                  <div>
                    <div
                      class="fl valuex lutang-jg2"
                      @click="
                        toCompon(
                          2,
                          'DGRQT_B_PV',
                          infoList.DGRQT_B_PV_node_string,
                          'DGRQT_B_PV_BM',
                          '低过出口烟气温度右'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.DGRQT_B_PV,
                          'DGRQT_B_PV',
                          infoList.DGRQT_B_PV_node_string,
                          'DGRQT_B_PV_BM'
                        )
                      "
                    >
                      {{ "DGRQT_B_PV" in infoList ? infoList.DGRQT_B_PV : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                  </div>
                </div>
              </div>
              <!-- 氧量部分 -->
              <div class="yangliang">
                <div style="height: 3vh">
                  <div class="fl widthx1">氧量SP</div>
                  <div
                    class="fl valuex"
                    @click="
                      toCompon(
                        2,
                        'YQO_SP',
                        infoList.YQO_SP_node_string,
                        'YQO_SP_BM',
                        '烟气氧量设定值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.YQO_SP,
                        'YQO_SP',
                        infoList.YQO_SP_node_string,
                        'YQO_SP_BM'
                      )
                    "
                  >
                    {{ "YQO_SP" in infoList ? infoList.YQO_SP : 0 }}
                  </div>
                  <div class="fl danwei">%</div>
                </div>
                <div style="height: 3vh">
                  <div class="fl widthx1" style="cursor: pointer">氧量1</div>
                  <div
                    class="fl valuex"
                    @click="
                      toCompon(
                        2,
                        'YQO_A_PV',
                        infoList.YQO_A_PV_node_string,
                        'YQO_A_PV_BM',
                        '烟气氧量过程值1'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.YQO_A_PV,
                        'YQO_A_PV',
                        infoList.YQO_A_PV_node_string,
                        'YQO_A_PV_BM'
                      )
                    "
                  >
                    {{ "YQO_A_PV" in infoList ? infoList.YQO_A_PV : 0 }}
                  </div>
                  <div class="fl danwei">%</div>
                </div>
                <div style="height: 3vh">
                  <div class="fl widthx1" style="cursor: pointer">氧量2</div>
                  <div
                    class="fl valuex"
                    @click="
                      toCompon(
                        2,
                        'YQO_B_PV',
                        infoList.YQO_B_PV_node_string,
                        'YQO_B_PV_BM',
                        '烟气氧量过程值2'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.YQO_B_PV,
                        'YQO_B_PV',
                        infoList.YQO_B_PV_node_string,
                        'YQO_B_PV_BM'
                      )
                    "
                  >
                    {{ "YQO_B_PV" in infoList ? infoList.YQO_B_PV : 0 }}
                  </div>
                  <div class="fl danwei">%</div>
                </div>
              </div>
              <!-- 炉膛下边及省煤器那部分 -->
              <div class="lutangall flex">
                <!-- 炉膛下边 -->
                <div class="ltbottom">
                  <div class="ltbottom-row1">
                    <div
                      class="fl valuex12 fu-color"
                      @click="
                        toCompon(
                          2,
                          'RQYL1_PV',
                          infoList.RQYL1_PV_node_string,
                          'RQYL1_PV_BM',
                          '阀前压力'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.RQYL1_PV,
                          'RQYL1_PV',
                          infoList.RQYL1_PV_node_string,
                          'RQYL1_PV_BM'
                        )
                      "
                    >
                      {{ "RQYL1_PV" in infoList ? infoList.RQYL1_PV : 0 }}
                    </div>
                  </div>
                  <div class="ltbottom-row2">
                    <div
                      class="fl valuex12 fu-color"
                      @click="
                        toCompon(
                          2,
                          'RQYL2_PV',
                          infoList.RQYL2_PV_node_string,
                          'RQYL2_PV_BM',
                          '阀后压力'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.RQYL2_PV,
                          'RQYL2_PV',
                          infoList.RQYL2_PV_node_string,
                          'RQYL2_PV_BM'
                        )
                      "
                    >
                      {{ "RQYL2_PV" in infoList ? infoList.RQYL2_PV : 0 }}
                    </div>
                  </div>
                  <div class="ltbottom-row3">
                    <div class="fl widthx2">燃气流量</div>
                    <div
                      class="fl valuex12 fu-color"
                      @click="
                        toCompon(
                          2,
                          'RQLL_PV',
                          infoList.RQLL_PV_node_string,
                          'RQLL_PV_BM',
                          '燃气流量'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.RQLL_PV,
                          'RQLL_PV',
                          infoList.RQLL_PV_node_string,
                          'RQLL_PV_BM'
                        )
                      "
                    >
                      {{ "RQLL_PV" in infoList ? infoList.RQLL_PV : 0 }}
                    </div>
                    <div class="fl danwei">Nm³/h</div>
                  </div>
                  <div class="ltbottom-row3 ltbottm-jg">
                    <div class="fl widthx2">总给煤量SP</div>
                    <div
                      class="fl valuex12 color2"
                      @click="
                        toCompon(
                          2,
                          'GML_SP',
                          infoList.GML_SP_node_string,
                          'GML_SP_BM',
                          '总给煤指令'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.GML_SP,
                          'GML_SP',
                          infoList.GML_SP_node_string,
                          'GML_SP_BM'
                        )
                      "
                    >
                      {{ "GML_SP" in infoList ? infoList.GML_SP : 0 }}
                    </div>
                    <div class="fl danwei">t/h</div>
                  </div>
                  <div class="ltbottom-row3">
                    <div class="fl widthx2">总给煤量PV</div>
                    <div
                      class="fl valuex12"
                      @click="
                        toCompon(
                          2,
                          'GML_PV',
                          infoList.GML_PV_node_string,
                          'GML_PV_BM',
                          '总给煤量'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.GML_PV,
                          'GML_PV',
                          infoList.GML_PV_node_string,
                          'GML_PV_BM'
                        )
                      "
                    >
                      {{ "GML_PV" in infoList ? infoList.GML_PV : 0 }}
                    </div>
                    <div class="fl danwei">t/h</div>
                  </div>
                  <div class="ltbottom-row3">
                    <div class="fl widthx2">总风量SP</div>
                    <div
                      class="fl valuex12"
                      @click="
                        toCompon(
                          2,
                          'SFF_SP',
                          infoList.SFF_SP_node_string,
                          'SFF_SP_BM',
                          '送风流量设定值'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.SFF_SP,
                          'SFF_SP',
                          infoList.SFF_SP_node_string,
                          'SFF_SP_BM'
                        )
                      "
                    >
                      {{ "SFF_SP" in infoList ? infoList.SFF_SP : 0 }}
                    </div>
                    <div class="fl danwei">t/h</div>
                  </div>
                  <div class="ltbottom-row3">
                    <div class="fl widthx2">总风量PV</div>
                    <div
                      class="fl valuex12"
                      @click="
                        toCompon(
                          2,
                          'SFF_PV',
                          infoList.SFF_PV_node_string,
                          'SFF_PV_BM',
                          '送风量过程值'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.SFF_PV,
                          'SFF_PV',
                          infoList.SFF_PV_node_string,
                          'SFF_PV_BM'
                        )
                      "
                    >
                      {{ "SFF_PV" in infoList ? infoList.SFF_PV : 0 }}
                    </div>
                    <div class="fl danwei">t/h</div>
                  </div>
                  <div>
                    <div class="ltbottom-row4 fl">
                      <div
                        class="bottonx fl ltbottm-jg2"
                        :style="{
                          background:
                            'ECF_MAN4' || 'ECF_MAN5' in infoList
                              ? infoList.ECF_MAN4.RM == 1 ||
                                infoList.ECF_MAN5.RM == 1
                                ? '#2AFC30'
                                : 'red'
                              : 'red',
                        }"
                        @click="toCompon(0, 'MFL_MANECFS', 2)"
                      >
                        A
                      </div>
                      <div class="fl widthx2">一次风压SP</div>
                      <div
                        class="fl valuex12"
                        @click="
                          toCompon(
                            2,
                            'YCF_A_SP',
                            infoList.YCF_A_SP_node_string,
                            'YCF_A_SP_BM',
                            '1#一次风设定值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.YCF_A_SP,
                            'SFF_PV',
                            infoList.YCF_A_SP_node_string,
                            'YCF_A_SP_BM'
                          )
                        "
                      >
                        {{ "YCF_A_SP" in infoList ? infoList.YCF_A_SP : 0 }}
                      </div>
                      <div class="fl danwei">KPa</div>
                    </div>
                  </div>
                  <div class="ltbottom-row3 ltbottm-jg3">
                    <div class="fl widthx2">再热温度SP</div>
                    <div
                      class="fl valuex12"
                      @click="
                        toCompon(
                          2,
                          'ZRQT_A_SP',
                          infoList.ZRQT_A_SP_node_string,
                          'ZRQT_A_SP_BM',
                          '甲再热汽温设定值'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.ZRQT_A_SP,
                          'ZRQT_A_SP',
                          infoList.ZRQT_A_SP_node_string,
                          'ZRQT_A_SP_BM'
                        )
                      "
                    >
                      {{ "ZRQT_A_SP" in infoList ? infoList.ZRQT_A_SP : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                  </div>
                  <div class="ltbottom-row3">
                    <div class="fl widthx2">再热温度PV</div>
                    <div
                      class="fl valuex12"
                      @click="
                        toCompon(
                          2,
                          'ZRQT_A_PV',
                          infoList.ZRQT_A_PV_node_string,
                          'ZRQT_A_PV_BM',
                          '甲再热汽温过程值'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.ZRQT_A_PV,
                          'ZRQT_A_PV',
                          infoList.ZRQT_A_PV_node_string,
                          'ZRQT_A_PV_BM'
                        )
                      "
                    >
                      {{ "ZRQT_A_PV" in infoList ? infoList.ZRQT_A_PV : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                  </div>
                  <div class="ltbottom-row3">
                    <div class="fl widthx2">再热流量SP</div>
                    <div
                      class="fl valuex12"
                      @click="
                        toCompon(
                          2,
                          'ZRQT_B_SPCL',
                          infoList.ZRQT_B_SPCL_node_string,
                          'ZRQT_B_SPCL_BM',
                          '再热器流量计算设定值'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.ZRQT_B_SPCL,
                          'ZRQT_B_SPCL',
                          infoList.ZRQT_B_SPCL_node_string,
                          'ZRQT_B_SPCL_BM'
                        )
                      "
                    >
                      {{ "ZRQT_B_SPCL" in infoList ? infoList.ZRQT_B_SPCL : 0 }}
                    </div>
                    <div class="fl danwei">t/h</div>
                  </div>

                  <div>
                    <div class="ltbottom-row4 fl">
                      <div
                        class="bottonx fl ltbottm-jg2"
                        :style="{
                          background:
                            'ECF_MAN1' || 'ECF_MAN2' || 'ECF_MAN3' in infoList
                              ? infoList.ECF_MAN1.RM == 1 ||
                                infoList.ECF_MAN2.RM == 1 ||
                                infoList.ECF_MAN3.RM == 1
                                ? '#2AFC30'
                                : 'red'
                              : 'red',
                        }"
                        @click="toCompon(0, 'MFL_MANECFX', 3)"
                      >
                        A
                      </div>
                      <div class="fl widthx2">再热流量PV</div>
                      <div
                        class="fl valuex12"
                        @click="
                          toCompon(
                            2,
                            'ZRQT_B_PV',
                            infoList.ZRQT_B_PV_node_string,
                            'ZRQT_B_PV_BM',
                            '乙再热汽温过程值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.ZRQT_B_PV,
                            'ZRQT_B_PV',
                            infoList.ZRQT_B_PV_node_string,
                            'ZRQT_B_PV_BM'
                          )
                        "
                      >
                        {{ "ZRQT_B_PV" in infoList ? infoList.ZRQT_B_PV : 0 }}
                      </div>
                      <div class="fl danwei">t/h</div>
                    </div>
                  </div>
                </div>
                <!-- 省煤器部分 -->
                <div class="shengmq">
                  <div class="shengmq-row1">
                    <div
                      class="fl valuex"
                      @click="
                        toCompon(
                          2,
                          'SMQT_1A_PV',
                          infoList.SMQT_1A_PV_node_string,
                          'SMQT_1A_PV_BM',
                          '一级省煤器烟气温度左'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.SMQT_1A_PV,
                          'SMQT_1A_PV',
                          infoList.SMQT_1A_PV_node_string,
                          'SMQT_1A_PV_BM'
                        )
                      "
                    >
                      {{ "SMQT_1A_PV" in infoList ? infoList.SMQT_1A_PV : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                    <div
                      class="fl valuex shengmq-jg"
                      @click="
                        toCompon(
                          2,
                          'SMQT_1B_PV',
                          infoList.SMQT_1B_PV_node_string,
                          'SMQT_1B_PV_BM',
                          '一级省煤器烟气温度右'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.SMQT_1B_PV,
                          'SMQT_1B_PV',
                          infoList.SMQT_1B_PV_node_string,
                          'SMQT_1B_PV_BM'
                        )
                      "
                    >
                      {{ "SMQT_1B_PV" in infoList ? infoList.SMQT_1B_PV : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                  </div>
                  <div class="shengmq-row1 shengmq-jg1">
                    <div
                      class="fl valuex"
                      @click="
                        toCompon(
                          2,
                          'SMQT_2A_PV',
                          infoList.SMQT_2A_PV_node_string,
                          'SMQT_2A_PV_BM',
                          '二级省煤器烟气温度左'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.SMQT_2A_PV,
                          'SMQT_2A_PV',
                          infoList.SMQT_2A_PV_node_string,
                          'SMQT_2A_PV_BM'
                        )
                      "
                    >
                      {{ "SMQT_2A_PV" in infoList ? infoList.SMQT_2A_PV : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                    <div
                      class="fl valuex shengmq-jg"
                      @click="
                        toCompon(
                          2,
                          'SMQT_2B_PV',
                          infoList.SMQT_2B_PV_node_string,
                          'SMQT_2B_PV_BM',
                          '二级省煤器烟气温度右'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.SMQT_2B_PV,
                          'SMQT_2B_PV',
                          infoList.SMQT_2B_PV_node_string,
                          'SMQT_2B_PV_BM'
                        )
                      "
                    >
                      {{ "SMQT_2B_PV" in infoList ? infoList.SMQT_2B_PV : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                  </div>
                  <div class="shengmq-row1 shengmq-jg1">
                    <div
                      class="fl valuex"
                      @click="
                        toCompon(
                          2,
                          'YRQT_1A_PV',
                          infoList.YRQT_1A_PV_node_string,
                          'YRQT_1A_PV_BM',
                          '一级预热器烟气温度左'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.YRQT_1A_PV,
                          'YRQT_1A_PV',
                          infoList.YRQT_1A_PV_node_string,
                          'YRQT_1A_PV_BM'
                        )
                      "
                    >
                      {{ "YRQT_1A_PV" in infoList ? infoList.YRQT_1A_PV : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                    <div
                      class="fl valuex shengmq-jg"
                      @click="
                        toCompon(
                          2,
                          'YRQT_1B_PV',
                          infoList.YRQT_1B_PV_node_string,
                          'YRQT_1B_PV_BM',
                          '一级预热器烟气温度右'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.YRQT_1B_PV,
                          'YRQT_1B_PV',
                          infoList.YRQT_1B_PV_node_string,
                          'YRQT_1B_PV_BM'
                        )
                      "
                    >
                      {{ "YRQT_1B_PV" in infoList ? infoList.YRQT_1B_PV : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                  </div>
                  <div class="shengmq-row1 shengmq-jg2">
                    <div
                      class="fl valuex"
                      @click="
                        toCompon(
                          2,
                          'PYT_A_PV',
                          infoList.PYT_A_PV_node_string,
                          'PYT_A_PV_BM',
                          '排烟温度左'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.PYT_A_PV,
                          'PYT_A_PV',
                          infoList.PYT_A_PV_node_string,
                          'PYT_A_PV_BM'
                        )
                      "
                    >
                      {{ "PYT_A_PV" in infoList ? infoList.PYT_A_PV : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                    <div
                      class="fl valuex shengmq-jg"
                      @click="
                        toCompon(
                          2,
                          'PYT_B_PV',
                          infoList.PYT_B_PV_node_string,
                          'PYT_B_PV_BM',
                          '排烟温度右'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.PYT_B_PV,
                          'PYT_B_PV',
                          infoList.PYT_B_PV_node_string,
                          'PYT_B_PV_BM'
                        )
                      "
                    >
                      {{ "PYT_B_PV" in infoList ? infoList.PYT_B_PV : 0 }}
                    </div>
                    <div class="fl danwei">℃</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 右侧部分 -->
            <div class="rightmain">
              <!-- 给水部分 -->
              <div class="geishui">
                <div class="rightmain-row1">
                  <div class="rightmain-row1-col color1 rightmain-jg fl">
                    减温后
                  </div>
                  <div class="rightmain-row1-col color1 rightmain-jg fl">
                    开度
                  </div>
                  <div class="rightmain-row1-col color1 rightmain-jg fl">
                    减温前
                  </div>
                  <div
                    class="fl valuex rightmain-jg"
                    @click="
                      toCompon(
                        2,
                        'ZQT_2A_PV',
                        infoList.ZQT_2A_PV_node_string,
                        'ZQT_2A_PV_BM',
                        '甲主汽温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.ZQT_2A_PV,
                        'ZQT_2A_PV',
                        infoList.ZQT_2A_PV_node_string,
                        'ZQT_2A_PV_BM'
                      )
                    "
                  >
                    {{ "ZQT_2A_PV" in infoList ? infoList.ZQT_2A_PV : 0 }}
                  </div>
                  <div class="fl danwei rightmain-jg">℃</div>
                  <div
                    class="fl valuex rightmain-jg"
                    @click="
                      toCompon(
                        2,
                        'ZQT_2B_PV',
                        infoList.ZQT_2B_PV_node_string,
                        'ZQT_2B_PV_BM',
                        '乙主汽温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.ZQT_2B_PV,
                        'ZQT_2B_PV',
                        infoList.ZQT_2B_PV_node_string,
                        'ZQT_2B_PV_BM'
                      )
                    "
                  >
                    {{ "ZQT_2B_PV" in infoList ? infoList.ZQT_2B_PV : 0 }}
                  </div>
                  <div class="fl danwei rightmain-jg">℃</div>
                </div>
                <div class="rightmain-row1 rightmain-jg2 fl">
                  <div class="Kclick" @click="toCompon(7)"></div>
                  <div
                    class="fl valuex"
                    @click="
                      toCompon(
                        2,
                        'JWHT_2A_PV',
                        infoList.JWHT_2A_PV_node_string,
                        'JWHT_2A_PV_BM',
                        '甲二级减温后温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.JWHT_2A_PV,
                        'JWHT_2A_PV',
                        infoList.JWHT_2A_PV_node_string,
                        'JWHT_2A_PV_BM'
                      )
                    "
                  >
                    {{ "JWHT_2A_PV" in infoList ? infoList.JWHT_2A_PV : 0 }}
                  </div>
                  <div class="fl danwei">℃</div>
                  <div
                    class="fl valuex rightmain-jg2"
                    @click="
                      toCompon(
                        2,
                        'ZQT_2A_FV',
                        infoList.ZQT_2A_FV_node_string,
                        'ZQT_2A_FV_BM',
                        '甲二级减温阀反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.ZQT_2A_FV,
                        'ZQT_2A_FV',
                        infoList.ZQT_2A_FV_node_string,
                        'ZQT_2A_FV_BM'
                      )
                    "
                  >
                    {{ "ZQT_2A_FV" in infoList ? infoList.ZQT_2A_FV : 0 }}
                  </div>
                  <div class="fl danwei">%</div>
                  <div
                    class="fl valuex rightmain-jg3"
                    @click="
                      toCompon(
                        2,
                        'JWQT_2A_PV',
                        infoList.JWQT_2A_PV_node_string,
                        'JWQT_2A_PV_BM',
                        '甲二级减温前温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.JWQT_2A_PV,
                        'JWQT_2A_PV',
                        infoList.JWQT_2A_PV_node_string,
                        'ZQT_2A_FV_BM'
                      )
                    "
                  >
                    {{ "JWQT_2A_PV" in infoList ? infoList.JWQT_2A_PV : 0 }}
                  </div>
                  <div class="fl danwei">℃</div>

                  <div class="fl widthx3 color1 fl">二级</div>
                  <div
                    class="bottonx fl"
                    :style="{
                      background: !infoList.ZQT_XK5.RM ? '#2AFC30' : 'red',
                    }"
                    @click="
                      infoList.ZQT_XK5
                        ? toDetail(
                            1,
                            'ZQT_XK5',
                            infoList.ZQT_XK5_node_string,
                            '',
                            '二级甲减温先控调节'
                          )
                        : ''
                    "
                  >
                    X
                  </div>
                  <div
                    class="bottonx fl buttonjg"
                    :style="{
                      background: !infoList.ZQT_QK3.TS ? '#2AFC30' : 'red',
                    }"
                    @click="
                      infoList.ZQT_QK3
                        ? toDetail(
                            2,
                            'ZQT_QK3',
                            infoList.ZQT_QK3_node_string,
                            '',
                            '二级减温左前馈'
                          )
                        : ''
                    "
                  >
                    Q
                  </div>

                  <div
                    class="bottonx fl buttonjg"
                    :style="{
                      background: !infoList.ZQT_QK5.TS ? '#2AFC30' : 'red',
                    }"
                    @click="
                      infoList.ZQT_QK5
                        ? toDetail(
                            2,
                            'ZQT_QK5',
                            infoList.ZQT_QK5_node_string,
                            '',
                            '负荷前馈'
                          )
                        : ''
                    "
                  >
                    Q
                  </div>

                  <div class="fl widthx4 color1">左二级减温SP</div>
                  <div
                    class="fl valuex"
                    @click="
                      toCompon(
                        2,
                        'ZQT_2A_SP',
                        infoList.ZQT_2A_SP_node_string,
                        'ZQT_2A_SP_BM',
                        '甲二级减温控制点'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.ZQT_2A_SP,
                        'ZQT_2A_SP',
                        infoList.ZQT_2A_SP_node_string,
                        'ZQT_2A_FV_BM'
                      )
                    "
                  >
                    {{ "ZQT_2A_SP" in infoList ? infoList.ZQT_2A_SP : 0 }}℃
                  </div>
                  <!-- <div class="fl danwei">℃</div> -->
                </div>
                <div class="rightmain-row1 rightmain-jg2 rightmain-jg5 fl">
                  <div class="Kclick" @click="toCompon(7)"></div>
                  <div
                    class="fl valuex"
                    @click="
                      toCompon(
                        2,
                        'JWHT_2B_PV',
                        infoList.JWHT_2B_PV_node_string,
                        'JWHT_2B_PV_BM',
                        '乙二级减温后温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.JWHT_2B_PV,
                        'JWHT_2B_PV',
                        infoList.JWHT_2B_PV_node_string,
                        'JWHT_2B_PV_BM'
                      )
                    "
                  >
                    {{ "JWHT_2B_PV" in infoList ? infoList.JWHT_2B_PV : 0 }}
                  </div>
                  <div class="fl danwei">℃</div>
                  <div
                    class="fl valuex rightmain-jg2"
                    @click="
                      toCompon(
                        2,
                        'ZQT_2B_FV',
                        infoList.ZQT_2B_FV_node_string,
                        'ZQT_2B_FV_BM',
                        '乙二级减温阀反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.ZQT_2B_FV,
                        'ZQT_2B_FV',
                        infoList.ZQT_2B_FV_node_string,
                        'ZQT_2B_FV_BM'
                      )
                    "
                  >
                    {{ "ZQT_2B_FV" in infoList ? infoList.ZQT_2B_FV : 0 }}
                  </div>
                  <div class="fl danwei">%</div>
                  <div
                    class="fl valuex rightmain-jg3"
                    @click="
                      toCompon(
                        2,
                        'JWQT_2B_PV',
                        infoList.JWQT_2B_PV_node_string,
                        'JWQT_2B_PV_BM',
                        '乙二级减温前温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.JWQT_2B_PV,
                        'JWQT_2B_PV',
                        infoList.JWQT_2B_PV_node_string,
                        'JWQT_2B_PV_BM'
                      )
                    "
                  >
                    {{ "JWQT_2B_PV" in infoList ? infoList.JWQT_2B_PV : 0 }}
                  </div>
                  <div class="fl danwei">℃</div>

                  <div class="fl widthx3 color1 fl"></div>
                  <div
                    class="bottonx fl"
                    :style="{
                      background: !infoList.ZQT_XK7.RM ? '#2AFC30' : 'red',
                    }"
                    @click="
                      infoList.ZQT_XK7
                        ? toDetail(
                            1,
                            'ZQT_XK7',
                            infoList.ZQT_XK7_node_string,
                            '',
                            '二级乙减温先控调节'
                          )
                        : ''
                    "
                  >
                    X
                  </div>
                  <div
                    class="bottonx fl buttonjg"
                    :style="{
                      background: !infoList.ZQT_QK4.TS ? '#2AFC30' : 'red',
                    }"
                    @click="
                      infoList.ZQT_QK4
                        ? toDetail(
                            2,
                            'ZQT_QK4',
                            infoList.ZQT_QK4_node_string,
                            '',
                            '二级减温右前馈'
                          )
                        : ''
                    "
                  >
                    Q
                  </div>

                  <div class="widthx31 fl buttonjg"></div>
                  <div class="fl widthx4 color1">右二级减温SP</div>
                  <div
                    class="fl valuex"
                    @click="
                      toCompon(
                        2,
                        'ZQT_2B_SP',
                        infoList.ZQT_2B_SP_node_string,
                        'ZQT_2B_SP_BM',
                        '乙一级减温控制点'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.ZQT_2B_SP,
                        'ZQT_2B_SP',
                        infoList.ZQT_2B_SP_node_string,
                        'ZQT_2B_SP_BM'
                      )
                    "
                  >
                    {{ "ZQT_2B_SP" in infoList ? infoList.ZQT_2B_SP : 0 }}℃
                  </div>
                  <!-- <div class="fl danwei">℃</div> -->
                </div>
                <div class="rightmain-row1 rightmain-jg2 rightmain-jg4 fl">
                  <div class="Kclick" @click="toCompon(7)"></div>
                  <div
                    class="fl valuex"
                    @click="
                      toCompon(
                        2,
                        'JWHT_1A_PV',
                        infoList.JWHT_1A_PV_node_string,
                        'JWHT_1A_PV_BM',
                        '甲一级减温后温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.JWHT_1A_PV,
                        'JWHT_1A_PV',
                        infoList.JWHT_1A_PV_node_string,
                        'JWHT_1A_PV_BM'
                      )
                    "
                  >
                    {{ "JWHT_1A_PV" in infoList ? infoList.JWHT_1A_PV : 0 }}
                  </div>
                  <div class="fl danwei">℃</div>
                  <div
                    class="fl valuex rightmain-jg2"
                    @click="
                      toCompon(
                        2,
                        'ZQT_1A_FV',
                        infoList.ZQT_1A_FV_node_string,
                        'ZQT_1A_FV_BM',
                        '甲一级减温阀反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.ZQT_1A_FV,
                        'ZQT_1A_FV',
                        infoList.ZQT_1A_FV_node_string,
                        'ZQT_1A_FV_BM'
                      )
                    "
                  >
                    {{ "ZQT_1A_FV" in infoList ? infoList.ZQT_1A_FV : 0 }}
                  </div>
                  <div class="fl danwei">%</div>
                  <div
                    class="fl valuex rightmain-jg3"
                    @click="
                      toCompon(
                        2,
                        'JWQT_1A_PV',
                        infoList.JWQT_1A_PV_node_string,
                        'JWQT_1A_PV_BM',
                        '甲一级减温前温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.JWQT_1A_PV,
                        'JWQT_1A_PV',
                        infoList.JWQT_1A_PV_node_string,
                        'JWQT_1A_PV_BM'
                      )
                    "
                  >
                    {{ "JWQT_1A_PV" in infoList ? infoList.JWQT_1A_PV : 0 }}
                  </div>
                  <div class="fl danwei">℃</div>

                  <div class="fl widthx3 color1 fl">一级</div>
                  <div
                    class="bottonx fl"
                    :style="{
                      background: !infoList.ZQT_XK1.RM ? '#2AFC30' : 'red',
                    }"
                    @click="
                      infoList.ZQT_XK1
                        ? toDetail(
                            1,
                            'ZQT_XK1',
                            infoList.ZQT_XK1_node_string,
                            '',
                            '一级甲主汽温度'
                          )
                        : ''
                    "
                  >
                    X
                  </div>
                  <div
                    class="bottonx fl buttonjg"
                    :style="{
                      background: !infoList.ZQT_QK1.TS ? '#2AFC30' : 'red',
                    }"
                    @click="
                      infoList.ZQT_QK1
                        ? toDetail(
                            2,
                            'ZQT_QK1',
                            infoList.ZQT_QK1_node_string,
                            '',
                            '一级减温左前馈'
                          )
                        : ''
                    "
                  >
                    Q
                  </div>

                  <div class="widthx31 fl buttonjg"></div>
                  <div class="fl widthx4 color1">左一级减温SP</div>
                  <div
                    class="fl valuex"
                    @click="
                      toCompon(
                        2,
                        'ZQT_1A_SP',
                        infoList.ZQT_1A_SP_node_string,
                        'ZQT_1A_SP_BM',
                        '甲一级减温控制点'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.ZQT_1A_SP,
                        'ZQT_1A_SP',
                        infoList.ZQT_1A_SP_node_string,
                        'ZQT_1A_SP_BM'
                      )
                    "
                  >
                    {{ "ZQT_1A_SP" in infoList ? infoList.ZQT_1A_SP : 0 }}℃
                  </div>
                  <!-- <div class="fl danwei">℃</div> -->
                </div>
                <div class="rightmain-row1 rightmain-jg2 rightmain-jg4 fl">
                  <div class="Kclick" @click="toCompon(7)"></div>
                  <div
                    class="fl valuex"
                    @click="
                      toCompon(
                        2,
                        'JWHT_1B_PV',
                        infoList.JWHT_1B_PV_node_string,
                        'JWHT_1B_PV_BM',
                        '乙一级减温后温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.JWHT_1B_PV,
                        'JWHT_1B_PV',
                        infoList.JWHT_1B_PV_node_string,
                        'JWHT_1B_PV_BM'
                      )
                    "
                  >
                    {{ "JWHT_1B_PV" in infoList ? infoList.JWHT_1B_PV : 0 }}
                  </div>
                  <div class="fl danwei">℃</div>
                  <div
                    class="fl valuex rightmain-jg2"
                    @click="
                      toCompon(
                        2,
                        'ZQT_1B_FV',
                        infoList.ZQT_1B_FV_node_string,
                        'ZQT_1B_FV_BM',
                        '乙一级减温阀反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.ZQT_1B_FV,
                        'ZQT_1B_FV',
                        infoList.ZQT_1B_FV_node_string,
                        'ZQT_1B_FV_BM'
                      )
                    "
                  >
                    {{ "ZQT_1B_FV" in infoList ? infoList.ZQT_1B_FV : 0 }}
                  </div>
                  <div class="fl danwei">%</div>
                  <div
                    class="fl valuex rightmain-jg3"
                    @click="
                      toCompon(
                        2,
                        'JWQT_1B_PV',
                        infoList.JWQT_1B_PV_node_string,
                        'JWQT_1B_PV_BM',
                        '乙一级减温前温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.JWQT_1B_PV,
                        'JWQT_1B_PV',
                        infoList.JWQT_1B_PV_node_string,
                        'JWQT_1B_PV_BM'
                      )
                    "
                  >
                    {{ "JWQT_1B_PV" in infoList ? infoList.JWQT_1B_PV : 0 }}
                  </div>
                  <div class="fl danwei">℃</div>

                  <div class="fl widthx3 color1 fl"></div>
                  <div
                    class="bottonx fl"
                    :style="{
                      background: !infoList.ZQT_XK3.RM ? '#2AFC30' : 'red',
                    }"
                    @click="
                      infoList.ZQT_XK3
                        ? toDetail(
                            1,
                            'ZQT_XK3',
                            infoList.ZQT_XK3_node_string,
                            '',
                            '一级乙主汽温度'
                          )
                        : ''
                    "
                  >
                    X
                  </div>
                  <div
                    class="bottonx fl buttonjg"
                    :style="{
                      background: !infoList.ZQT_QK2.TS ? '#2AFC30' : 'red',
                    }"
                    @click="
                      infoList.ZQT_QK2
                        ? toDetail(
                            2,
                            'ZQT_QK2',
                            infoList.ZQT_QK2_node_string,
                            '',
                            '一级减温右前馈'
                          )
                        : ''
                    "
                  >
                    Q
                  </div>

                  <div
                    class="bottonx fl buttonjg"
                    :style="{
                      background:
                      'ZQT_MAN1'||'ZQT_MAN2'||'ZQT_MAN3'||'ZQT_MAN4' in infoList?
                        infoList.ZQT_MAN1.RM == 1 ||
                        infoList.ZQT_MAN2.RM == 1 ||
                        infoList.ZQT_MAN3.RM == 1 ||
                        infoList.ZQT_MAN4.RM == 1
                          ? '#2AFC30'
                          : 'red': 'red',
                    }"
                    @click="toCompon(0, 'MFL_MANQW', 4)"
                  >
                    A
                  </div>
                  <div class="fl widthx4 color1">右一级减温SP</div>
                  <div
                    class="fl valuex"
                    @click="
                      toCompon(
                        2,
                        'ZQT_1B_SP',
                        infoList.ZQT_1B_SP_node_string,
                        'ZQT_1B_SP_BM',
                        '乙一级减温控制点'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.ZQT_1B_SP,
                        'ZQT_1B_SP',
                        infoList.ZQT_1B_SP_node_string,
                        'ZQT_1B_SP'
                      )
                    "
                  >
                    {{ "ZQT_1B_SP" in infoList ? infoList.ZQT_1B_SP : 0 }}℃
                  </div>
                  <!-- <div class="fl danwei">℃</div> -->
                </div>
                <div class="rightmain-row1 rightmain-jg2 rightmain-jg4 fl">
                  <div class="Kclick Kclick1" @click="toCompon(9)"></div>
                  <div
                    class="fl valuex"
                    @click="
                      toCompon(
                        2,
                        'GST_PV',
                        infoList.GST_PV_node_string,
                        'GST_PV_BM',
                        '给水温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.GST_PV,
                        'GST_PV',
                        infoList.GST_PV_node_string,
                        'GST_PV_BM'
                      )
                    "
                  >
                    {{ "GST_PV" in infoList ? infoList.GST_PV : 0 }}
                  </div>
                  <div class="fl danwei">℃</div>
                </div>
                <div class="rightmain-row1 rightmain-jg2 fl">
                  <div class="Kclick Kclick2" @click="toCompon(9)"></div>
                  <div
                    class="fl valuex"
                    @click="
                      toCompon(
                        2,
                        'GSP_B_PV',
                        infoList.GSP_B_PV_node_string,
                        'GSP_B_PV_BM',
                        '2#给水压力'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.GSP_B_PV,
                        'GSP_B_PV',
                        infoList.GSP_B_PV_node_string,
                        'GSP_B_PV_BM'
                      )
                    "
                  >
                    {{ "GSP_B_PV" in infoList ? infoList.GSP_B_PV : 0 }}
                  </div>
                  <div class="fl danwei">MPa</div>
                  <div
                    class="fl valuex rightmain-jg6"
                    @click="
                      toCompon(
                        2,
                        'QBW_A_FV',
                        infoList.QBW_A_FV_node_string,
                        'QBW_A_FV_BM',
                        '主给水阀门反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.QBW_A_FV,
                        'QBW_A_FV',
                        infoList.QBW_A_FV_node_string,
                        'GSP_B_PV_BM'
                      )
                    "
                  >
                    {{ "QBW_A_FV" in infoList ? infoList.QBW_A_FV : 0 }}
                  </div>
                  <div class="fl danwei">%</div>
                  <div class="fl widthx1 color1 fl rightmain-jg7">主给水</div>
                </div>
                <div>
                  <div class="rightmain-row1 rightmain-jg2 fl">
                    <div
                      class="fl valuex"
                      @click="
                        toCompon(
                          2,
                          'GSF_PV',
                          infoList.GSF_PV_node_string,
                          'GSF_PV_BM',
                          '给水流量'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.GSF_PV,
                          'GSF_PV',
                          infoList.GSF_PV_node_string,
                          'GSP_B_PV_BM'
                        )
                      "
                    >
                      {{ "GSF_PV" in infoList ? infoList.GSF_PV : 0 }}
                    </div>
                    <div class="fl danwei">t/h</div>
                  </div>
                  <div class="rightmain-row1 rightmain-jg8 fl">
                    <div
                      class="fl valuex"
                      @click="
                        toCompon(
                          2,
                          'QBW_B_FV',
                          infoList.QBW_B_FV_node_string,
                          'QBW_B_FV_BM',
                          '副给水阀门反馈'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.QBW_B_FV,
                          'QBW_B_FV',
                          infoList.QBW_B_FV_node_string,
                          'QBW_B_FV_BM'
                        )
                      "
                    >
                      {{ "QBW_B_FV" in infoList ? infoList.QBW_B_FV : 0 }}
                    </div>
                    <div class="fl danwei rightmain-jg31">℃</div>
                    <div
                      class="bottonx fl buttonjg"
                      :style="{
                        background: !infoList.QBW_XK1.RM ? '#2AFC30' : 'red',
                      }"
                      @click="
                        infoList.QBW_XK1
                          ? toDetail(
                              1,
                              'QBW_XK1',
                              infoList.QBW_XK1_node_string,
                              '',
                              '汽包水位主调节先控'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="bottonx fl buttonjg"
                      :style="{
                        background: !infoList.QBW_XK2.RM ? '#2AFC30' : 'red',
                      }"
                      @click="
                        infoList.QBW_XK2
                          ? toDetail(
                              1,
                              'QBW_XK2',
                              infoList.QBW_XK2_node_string,
                              '',
                              '主给水副调节'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="bottonx fl buttonjg"
                      :style="{
                        background: !infoList.QBW_XK3.RM ? '#2AFC30' : 'red',
                      }"
                      @click="
                        infoList.QBW_XK3
                          ? toDetail(
                              1,
                              'QBW_XK3',
                              infoList.QBW_XK3_node_string,
                              '',
                              '副给水副调节'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="bottonx fl buttonjg"
                      :style="{
                        background:
                        'QBW_MAN1'||'QBW_MAN2' in infoList?
                          infoList.QBW_MAN1.RM == 1 || infoList.QBW_MAN2.RM == 1
                            ? '#2AFC30'
                            : 'red': 'red',
                      }"
                      @click="toCompon(0, 'MFL_MANGS', 2)"
                    >
                      A
                    </div>
                  </div>
                </div>
              </div>
              <!-- 送风机部分 -->
              <div class="songfeng">
                <div class="fl yinfen-jg">
                  <div
                    class="fl valuex"
                    @click="
                      toCompon(
                        2,
                        'SFF_B_PV',
                        infoList.SFF_B_PV_node_string,
                        'SFF_B_PV_BM',
                        '送风流量过程值2'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.SFF_B_PV,
                        'SFF_B_PV',
                        infoList.SFF_B_PV_node_string,
                        'SFF_B_PV_BM'
                      )
                    "
                  >
                    {{ "SFF_B_PV" in infoList ? infoList.SFF_B_PV : 0 }}t/h
                  </div>
                </div>
                <div class="fl">
                  <div class="yinfen-jg2" style="margin-left: 7.7vw">
                    <div class="Kclick Kclick3" @click="toCompon(10)"></div>
                    <div
                      class="fl valuex"
                      @click="
                        toCompon(
                          2,
                          'SFI_A_PV',
                          infoList.SFI_A_PV_node_string,
                          'SFI_A_PV_BM',
                          '1#送风机电流'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.SFI_A_PV,
                          'SFI_A_PV',
                          infoList.SFI_A_PV_node_string,
                          'SFF_B_PV_BM'
                        )
                      "
                    >
                      {{ "SFI_A_PV" in infoList ? infoList.SFI_A_PV : 0 }}A
                    </div>
                    <!-- <div class="fl danwei">A</div> -->
                    <div class="fl widthx5 color1" style="margin-top: -2vh">
                      送风机1#
                    </div>
                  </div>
                  <div
                    class="yinfen-jg2"
                    style="margin-left: 7.7vw; margin-top: 9vh"
                  >
                    <div class="Kclick Kclick3" @click="toCompon(10)"></div>
                    <div
                      class="fl valuex"
                      @click="
                        toCompon(
                          2,
                          'SFI_B_PV',
                          infoList.SFI_B_PV_node_string,
                          'SFI_B_PV_BM',
                          '2#送风机电流'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.SFI_B_PV,
                          'SFI_B_PV',
                          infoList.SFI_B_PV_node_string,
                          'SFI_B_PV_BM'
                        )
                      "
                    >
                      {{ "SFI_B_PV" in infoList ? infoList.SFI_B_PV : 0 }}A
                    </div>
                    <!-- <div class="fl danwei">A</div> -->
                    <div class="fl widthx5 color1" style="margin-top: -2vh">
                      送风机2#
                    </div>
                  </div>
                </div>
                <div class="fl yinfen-jg4">
                  <div>
                    <div
                      class="valuexzr"
                      @click="
                        toCompon(
                          2,
                          'YQO_BA_MV',
                          infoList.YQO_BA_MV_node_string,
                          'YQO_BA_MV_BM',
                          '1#送风挡板XK输出'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.YQO_BA_MV,
                          'YQO_BA_MV',
                          infoList.YQO_BA_MV_node_string,
                          'YQO_BA_MV_BM'
                        )
                      "
                    >
                      {{ "YQO_BA_MV" in infoList ? infoList.YQO_BA_MV : 0 }}%
                    </div>
                    <div
                      class="valuexzr"
                      @click="
                        toCompon(
                          2,
                          'YQO_BA_FV',
                          infoList.YQO_BA_FV_node_string,
                          'YQO_BA_FV_BM',
                          '1#送风反馈'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.YQO_BA_FV,
                          'YQO_BA_FV',
                          infoList.YQO_BA_FV_node_string,
                          'YQO_BA_FV_BM'
                        )
                      "
                    >
                      {{ "YQO_BA_FV" in infoList ? infoList.YQO_BA_FV : 0 }}%
                    </div>
                  </div>
                  <div class="yinfen-jg5">
                    <div class="fl">
                      <div
                        class="valuexzr"
                        @click="
                          toCompon(
                            2,
                            'YQO_BB_MV',
                            infoList.YQO_BB_MV_node_string,
                            'YQO_BB_MV_BM',
                            '2#送风挡板XK输出'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.YQO_BB_MV,
                            'YQO_BB_MV',
                            infoList.YQO_BB_MV_node_string,
                            'YQO_BB_MV_BM'
                          )
                        "
                      >
                        {{ "YQO_BB_MV" in infoList ? infoList.YQO_BB_MV : 0 }}%
                      </div>
                      <div
                        class="valuexzr"
                        @click="
                          toCompon(
                            2,
                            'YQO_BB_FV',
                            infoList.YQO_BB_FV_node_string,
                            'YQO_BB_FV_BM',
                            '2#送风反馈'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.YQO_BB_FV,
                            'YQO_BB_FV',
                            infoList.YQO_BB_FV_node_string,
                            'YQO_BB_FV_BM'
                          )
                        "
                      >
                        {{ "YQO_BB_FV" in infoList ? infoList.YQO_BB_FV : 0 }}%
                      </div>
                    </div>
                    <div class="fl yinfen-jg6">
                      <div
                        class="bottonx fl buttonjg"
                        :style="{
                          background: !infoList.YQO_RSF1.TS ? '#2AFC30' : 'red',
                        }"
                        @click="
                          infoList.YQO_RSF1
                            ? toDetail(
                                3,
                                'YQO_RSF1',
                                infoList.YQO_RSF1_node_string,
                                '',
                                '送风机电流纠偏'
                              )
                            : ''
                        "
                      >
                        R
                      </div>
                      <div
                        class="bottonx fl buttonjg"
                        :style="{
                          background: !infoList.YQO_XK1.RM ? '#2AFC30' : 'red',
                        }"
                        @click="
                          infoList.YQO_XK1
                            ? toDetail(
                                1,
                                'YQO_XK1',
                                infoList.YQO_XK1_node_string,
                                '',
                                '氧量先控调节'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                      <div
                        class="bottonx fl buttonjg"
                        :style="{
                          background: !infoList.YQO_XK2.RM ? '#2AFC30' : 'red',
                        }"
                        @click="
                          infoList.YQO_XK2
                            ? toDetail(
                                1,
                                'YQO_XK2',
                                infoList.YQO_XK2_node_string,
                                '',
                                '送风变频先控调节'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                      <div
                        class="bottonx fl buttonjg"
                        :style="{
                          background:
                          'YQO_MAN1'||'YQO_MAN2' in infoList?
                            infoList.YQO_MAN1.RM == 1 ||
                            infoList.YQO_MAN2.RM == 1
                              ? '#2AFC30'
                              : 'red': 'red',
                        }"
                        @click="toCompon(0, 'MFL_MANSF', 2)"
                      >
                        A
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 一次风机 -->
              <div class="yicfeng">
                <div class="Kclick Kclick4" @click="toCompon(11)"></div>
                <div class="Kclick Kclick5" @click="toCompon(11)"></div>
                <div class="fl">
                  <div class="yinfen-jg2" style="margin-left: 4.7vw">
                    <div
                      class="fl valuex"
                      @click="
                        toCompon(
                          2,
                          'YCF_A_PV',
                          infoList.YCF_A_PV_node_string,
                          'YCF_A_PV_BM',
                          '1#一次风过程值'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.YCF_A_PV,
                          'YCF_A_PV',
                          infoList.YCF_A_PV_node_string,
                          'YCF_A_PV_BM'
                        )
                      "
                    >
                      {{ "YCF_A_PV" in infoList ? infoList.YCF_A_PV : 0 }}KPa
                    </div>
                    <!-- <div class="fl danwei">A</div> -->
                    <div class="fl widthx5 color1">一次风机1</div>
                  </div>
                  <div
                    class="yinfen-jg2"
                    style="margin-left: 4.7vw; margin-top: 9vh"
                  >
                    <div
                      class="fl valuex"
                      @click="
                        toCompon(
                          2,
                          'YCF_B_PV',
                          infoList.YCF_B_PV_node_string,
                          'YCF_B_PV_BM',
                          '2#一次风过程值'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.YCF_B_PV,
                          'YCF_B_PV',
                          infoList.YCF_B_PV_node_string,
                          'YCF_B_PV_BM'
                        )
                      "
                    >
                      {{ "YCF_B_PV" in infoList ? infoList.YCF_B_PV : 0 }}KPa
                    </div>
                    <!-- <div class="fl danwei">A</div> -->
                    <div class="fl widthx5 color1">一次风机2</div>
                  </div>
                </div>
                <div class="fl yinfen-jg4">
                  <div>
                    <div
                      class="valuex yinfen-jg7"
                      @click="
                        toCompon(
                          2,
                          'SFI_A_PV',
                          infoList.SFI_A_PV_node_string,
                          'SFI_A_PV_BM',
                          '1#送风机电流'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.SFI_A_PV,
                          'SFI_A_PV',
                          infoList.SFI_A_PV_node_string,
                          'SFI_A_PV_BM'
                        )
                      "
                    >
                      {{ "SFI_A_PV" in infoList ? infoList.SFI_A_PV : 0 }}A
                    </div>
                  </div>
                  <div class="yinfen-jg5">
                    <div class="fl">
                      <div
                        class="valuex yinfen-jg7"
                        style="margin-top: 4vh"
                        @click="
                          toCompon(
                            2,
                            'SFI_B_PV',
                            infoList.SFI_B_PV_node_string,
                            'SFI_B_PV_BM',
                            '2#送风机电流'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.SFI_B_PV,
                            'SFI_B_PV',
                            infoList.SFI_B_PV_node_string,
                            'SFI_B_PV_BM'
                          )
                        "
                      >
                        {{ "SFI_B_PV" in infoList ? infoList.SFI_B_PV : 0 }}A
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fl yinfen-jg9">
                  <div
                    class="valuex"
                    @click="
                      toCompon(
                        2,
                        'YCF_A_MV',
                        infoList.YCF_A_MV_node_string,
                        'YCF_A_MV_BM',
                        '1#一次风XK输出'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.YCF_A_MV,
                        'YCF_A_MV',
                        infoList.YCF_A_MV_node_string,
                        'YCF_A_MV_BM'
                      )
                    "
                  >
                    {{ "YCF_A_MV" in infoList ? infoList.YCF_A_MV : 0 }}%
                  </div>
                  <div
                    class="valuex yinfen-jg10"
                    @click="
                      toCompon(
                        2,
                        'YCF_A_FV',
                        infoList.YCF_A_FV_node_string,
                        'YCF_A_FV_BM',
                        '1#一次风开度反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.YCF_A_FV,
                        'YCF_A_FV',
                        infoList.YCF_A_FV_node_string,
                        'YCF_A_FV_BM'
                      )
                    "
                  >
                    {{ "YCF_A_FV" in infoList ? infoList.YCF_A_FV : 0 }}%
                  </div>
                  <div
                    class="valuex yinfen-jg2"
                    @click="
                      toCompon(
                        2,
                        'YCF_B_MV',
                        infoList.YCF_B_MV_node_string,
                        'YCF_B_MV_BM',
                        '2#一次风XK输出'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.YCF_B_MV,
                        'YCF_B_MV',
                        infoList.YCF_B_MV_node_string,
                        'YCF_B_MV_BM'
                      )
                    "
                  >
                    {{ "YCF_B_MV" in infoList ? infoList.YCF_B_MV : 0 }}%
                  </div>
                  <div
                    class="valuex yinfen-jg11"
                    @click="
                      toCompon(
                        2,
                        'YCF_B_FV',
                        infoList.YCF_B_FV_node_string,
                        'YCF_B_FV_BM',
                        '2#一次风开度反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.YCF_B_FV,
                        'YCF_B_FV',
                        infoList.YCF_B_FV_node_string,
                        'YCF_B_FV_BM'
                      )
                    "
                  >
                    {{ "YCF_B_FV" in infoList ? infoList.YCF_B_FV : 0 }}%
                  </div>
                </div>
                <div class="fl yinfen-jg8">
                  <div
                    class="bottonx fl buttonjg"
                    :style="{
                      background: !infoList.YCF_QK1.SP ? '#2AFC30' : 'red',
                    }"
                    @click="
                      infoList.YCF_QK1
                        ? toDetail(
                            2,
                            'YCF_QK1',
                            infoList.YCF_QK1_node_string,
                            '',
                            '一次风前馈'
                          )
                        : ''
                    "
                  >
                    Q
                  </div>

                  <div
                    class="bottonx fl buttonjg"
                    :style="{
                      background: !infoList.YCF_RSF1.TS ? '#2AFC30' : 'red',
                    }"
                    @click="
                      infoList.YCF_RSF1
                        ? toDetail(
                            3,
                            'YCF_RSF1',
                            infoList.YCF_RSF1_node_string,
                            '',
                            '一次风机电流纠偏'
                          )
                        : ''
                    "
                  >
                    R
                  </div>
                  <div
                    class="bottonx fl buttonjg"
                    :style="{
                      background: !infoList.YCF_XK1.RM ? '#2AFC30' : 'red',
                    }"
                    @click="
                      infoList.YCF_XK1
                        ? toDetail(
                            1,
                            'YCF_XK1',
                            infoList.YCF_XK1_node_string,
                            '',
                            '一次风压先控调节'
                          )
                        : ''
                    "
                  >
                    X
                  </div>

                  <div
                    class="bottonx fl buttonjg"
                    :style="{
                      background:
                      'YCF_MAN1'||'YCF_MAN2' in  infoList?
                        infoList.YCF_MAN1.RM == 1 || infoList.YCF_MAN2.RM == 1
                          ? '#2AFC30'
                          : 'red': 'red',
                    }"
                    @click="toCompon(0, 'MFL_MANYCF', 2)"
                  >
                    A
                  </div>
                </div>
              </div>
              <!-- 引风机 -->
              <div class="yinfeng">
                <div class="Kclick Kclick6" @click="toCompon(12)"></div>
                <div class="Kclick Kclick7" @click="toCompon(12)"></div>
                <div class="fl">
                  <div class="yinfen-jg2" style="margin-left: 4vw">
                    <div
                      class="fl valuexzr"
                      @click="
                        toCompon(
                          2,
                          'LTP_DA_FV',
                          infoList.LTP_DA_FV_node_string,
                          'LTP_DA_FV_BM',
                          '1#引风挡板反馈'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.LTP_DA_FV,
                          'LTP_DA_FV',
                          infoList.LTP_DA_FV_node_string,
                          'LTP_DA_FV_BM'
                        )
                      "
                    >
                      {{ "LTP_DA_FV" in infoList ? infoList.LTP_DA_FV : 0 }}%
                    </div>
                  </div>
                  <div
                    class="yinfen-jg2"
                    style="margin-left: 4vw; margin-top: 13vh"
                  >
                    <div
                      class="fl valuex"
                      @click="
                        toCompon(
                          2,
                          'LTP_DB_FV',
                          infoList.LTP_DB_FV_node_string,
                          'LTP_DB_FV_BM',
                          '2#引风挡板反馈'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.LTP_DB_FV,
                          'LTP_DB_FV',
                          infoList.LTP_DB_FV_node_string,
                          'LTP_DB_FV_BM'
                        )
                      "
                    >
                      {{ "LTP_DB_FV" in infoList ? infoList.LTP_DB_FV : 0 }}%
                    </div>
                  </div>
                </div>
                <div class="fl yinfeng-jg2" style="margin-left: 1.8vw">
                  <div
                    class="valuex"
                    @click="
                      toCompon(
                        2,
                        'LTP_BA_FV',
                        infoList.LTP_BA_FV_node_string,
                        'LTP_BA_FV_BM',
                        '1#引风液耦反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.LTP_BA_FV,
                        'LTP_BA_FV',
                        infoList.LTP_BA_FV_node_string,
                        'YCF_A_MV_BM'
                      )
                    "
                  >
                    {{ "LTP_BA_FV" in infoList ? infoList.LTP_BA_FV : 0 }}%
                  </div>
                  <div
                    class="valuex"
                    style="margin-top: 0.5vh"
                    @click="
                      toCompon(
                        2,
                        'LTP_BA_MV',
                        infoList.LTP_BA_MV_node_string,
                        'LTP_BA_MV_BM'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.LTP_BA_MV,
                        'LTP_BA_MV',
                        infoList.LTP_BA_MV_node_string,
                        'LTP_BA_MV_BM',
                        '1#引风液耦XK输出'
                      )
                    "
                  >
                    {{ "LTP_BA_MV" in infoList ? infoList.LTP_BA_MV : 0 }}%
                  </div>
                  <div
                    class="valuex yinfen-jg2 yinfeng-jg1"
                    @click="
                      toCompon(
                        2,
                        'LTP_BB_FV',
                        infoList.LTP_BB_FV_node_string,
                        'LTP_BB_FV_BM'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.LTP_BB_FV,
                        'LTP_BB_FV',
                        infoList.LTP_BB_FV_node_string,
                        'LTP_BB_FV_BM',
                        '2#引风液耦反馈'
                      )
                    "
                  >
                    {{ "LTP_BB_FV" in infoList ? infoList.LTP_BB_FV : 0 }}%
                  </div>
                  <div
                    class="valuex"
                    style="margin-top: 0.8vh"
                    @click="
                      toCompon(
                        2,
                        'LTP_BB_MV',
                        infoList.LTP_BB_MV_node_string,
                        'LTP_BB_MV_BM',
                        '2#引风液耦XK输出'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.LTP_BB_MV,
                        'LTP_BB_MV',
                        infoList.LTP_BB_MV_node_string,
                        'LTP_BB_MV_BM'
                      )
                    "
                  >
                    {{ "LTP_BB_MV" in infoList ? infoList.LTP_BB_MV : 0 }}%
                  </div>
                </div>
                <div class="fl yinfeng-jg2 yinfeng-jg3">
                  <div
                    class="valuex"
                    @click="
                      toCompon(
                        2,
                        'YFI_A_PV',
                        infoList.YFI_A_PV_node_string,
                        'YFI_A_PV_BM',
                        '1#引风机电流'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.YFI_A_PV,
                        'YFI_A_PV',
                        infoList.YFI_A_PV_node_string,
                        'YCF_A_MV_BM'
                      )
                    "
                  >
                    {{ "YFI_A_PV" in infoList ? infoList.YFI_A_PV : 0 }}A
                  </div>
                  <div
                    class="valuex yinfeng-jg4"
                    @click="
                      toCompon(
                        2,
                        'YFI_B_PV',
                        infoList.YFI_B_PV_node_string,
                        'YFI_B_PV_BM',
                        '2#引风机电流'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.YFI_B_PV,
                        'YFI_B_PV',
                        infoList.YFI_B_PV_node_string,
                        'YFI_B_PV_BM'
                      )
                    "
                  >
                    {{ "YFI_B_PV" in infoList ? infoList.YFI_B_PV : 0 }}A
                  </div>
                </div>
                <div class="fl yinfeng-jg5">
                  <div class="widthx5 color1">引风机1#</div>
                  <div class="widthx5 yinfeng-jg4 color1">引风机2#</div>
                </div>
                <div class="fl yinfeng-jg5">
                  <div>
                    <div
                      class="bottonx fl buttonjg"
                      :style="{
                        background: !infoList.LTP_QK1.TS ? '#2AFC30' : 'red',
                      }"
                      @click="
                        infoList.LTP_QK1
                          ? toDetail(
                              2,
                              'LTP_QK1',
                              infoList.LTP_QK1_node_string,
                              '',
                              '炉膛负压前馈'
                            )
                          : ''
                      "
                    >
                      Q
                    </div>
                    <div
                      class="bottonx fl buttonjg"
                      :style="{
                        background: !infoList.LTP_RSF1.TS ? '#2AFC30' : 'red',
                      }"
                      @click="
                        infoList.LTP_RSF1
                          ? toDetail(
                              3,
                              'LTP_RSF1',
                              infoList.LTP_RSF1_node_string,
                              '',
                              '引风机电流纠偏'
                            )
                          : ''
                      "
                    >
                      R
                    </div>
                  </div>
                  <div style="margin-top: 3vh">
                    <div
                      class="bottonx fl buttonjg"
                      :style="{
                        background: !infoList.LTP_XK1.RM ? '#2AFC30' : 'red',
                      }"
                      @click="
                        infoList.LTP_XK1
                          ? toDetail(
                              1,
                              'LTP_XK1',
                              infoList.LTP_XK1_node_string,
                              '',
                              '引风先控调节'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="bottonx fl buttonjg"
                      :style="{
                        background:
                        'LTP_MAN1'||'LTP_MAN2' in infoList?
                          infoList.LTP_MAN1.RM == 1 || infoList.LTP_MAN2.RM == 1
                            ? '#2AFC30'
                            : 'red': 'red',
                      }"
                      @click="toCompon(0, 'MFL_MANYF', 2)"
                    >
                      A
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mflmanyManual
      v-if="mflmanyManual"
      :titname="Manualtitname"
      :numM="Manualnode"
      :mark="Manualmark"
      :manvar="Manualvar"
      @sendStatus="isClose"
      :infoList="infoList"
    ></mflmanyManual>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal"></inputVal>

    <Param01
      v-if="isPshow01"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param01>
    <Param02
      v-if="isPshow02"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param02>
    <Param03
      v-if="isPshow03"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param03>
    <Param04
      v-if="isPshow04"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param04>
    <MfZRQTC
      v-if="iszqwdshow"
      @sendStatus="isClose"
      :infoList="infoList"
    ></MfZRQTC>
    <Param05
      v-if="isPshow05"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param05>
    <Param06
      v-if="isPshow06"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param06>
    <MfMAC
      v-if="MfMAC"
      @sendStatus="isClose"
      :infoList="infoList"
      :MfMACCS1="MfMACCS1"
      :MfMACCS2="MfMACCS2"
      :MfMACCS3="MfMACCS3"
      :MfMACCS4="MfMACCS4"
    ></MfMAC>
  </div>
</template>

<script>
import index from "./_index/index.js";
import mflmanyManual from "@/components/MflmanyManual1.vue"; //多手操器
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Param01 from "@/views/MfBoiler/MFZQTC/index.vue";
import Param02 from "@/views/MfBoiler/MFZQP_C/index.vue";
import Param03 from "@/views/MfBoiler/MFQBW_C/index.vue";
import Param04 from "@/views/MfBoiler/MFYQO_C/index.vue";
import Param05 from "@/views/MfBoiler/MFYCFC/index.vue";
import Param06 from "@/views/MfBoiler/MFLTPC/index.vue";
import MfMAC from "@/views/MfBoiler/MfMAC/index.vue";
import MfZRQTC from "@/views/MfBoiler/MfZRQT_C/index.vue";
export default {
  name: "index",
  components: {
    mflmanyManual,
    Historical,
    inputVal,
    Param01,
    Param02,
    Param03,
    Param04,
    Param05,
    Param06,
    MfMAC,
    MfZRQTC,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      grouptime: null,
      mflmanyManual: false,
      Manualtitname: "",
      Manualnode: "",
      Manualmark: "",
      Manualvar: "",
      headerStyle: {
        "font-weight": "500",
        "font-size": "1.7vh",
        height: "1vh",
        borderColor: "#0f3747",
        background: "#205365",
        padding: "0px",
        border: "none",
        color: "#5ca4a6",
      },
      isPshow01: false,
      isPshow02: false,
      isPshow03: false,
      isPshow04: false,
      isPshow05: false,
      isPshow06: false,
      iszqwdshow: false,
      MfMAC: false,
      flag: 0,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      screenWidth: document.body.clientWidth,
      screeHeight: "",
      conHeight: "",
      leftHeight: "",
      leftcon: "",
      tabline: "",
      tabline1: "",
      tabstyle: "",
      tablist: "",
      tabdatawidth: "",
      tabdatawidth1: "",
      Aname: "",
      changValue: null,
      config: {
        data: [10],
        shape: "round",
        colors: ["#43ff44", "#43ff44"],
        waveOpacity: "1",
        waveNum: "50",
        formatter: "{value}",
      },
      rightHeight: "",
      projectData: "",
    };
  },
  mixins: [index],
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.config.data[0] = this.infoList.QBW_PV || 0.0;
    this.$bus.$on("sendMsg", (msg) => {
      // 底部组件发送来的消息
      this.flag = msg;
      if (this.flag == 1) {
        this.$router.push({
          path: "/MFZFXT",
        });
      } else if (this.flag == 0) {
        this.$router.push({
          path: "/Mfindex",
        });
      } else if (this.flag == 2) {
        this.$router.push({
          path: "/MFECF",
        });
      } else if (this.flag == 3) {
        this.$router.push({
          path: "/MFYXPJ",
        });
      } else if (this.flag == 4) {
        this.$router.push({
          path: "/MFSwitch",
        });
      } else if (this.flag == 4) {
        this.$router.push({
          path: "/MFSecurity",
        });
      } else if (this.flag == 4) {
        this.$router.push({
          path: "/MFTLTX",
        });
      }
    });
  },
  computed: {},
  mounted() {},
  methods: {
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },

    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无操作权限");
        }
      } else {
        this.$refs.inputVal.open(data, name, historyname, node, type);
      }
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.mflindex {
  width: 98vw;
  height: 90vh;
  margin-left: 1vw;
  overflow-x: hidden;
  overflow-y: hidden;
  color: #fff;

  .Kclick {
    width: 1.5vw;
    height: 3.5vh;
    position: absolute;
    z-index: 99;
    top: 0;
    left: 9vw;
    cursor: pointer;
  }

  .Kclick1 {
    top: 2vh;
    cursor: pointer;
  }

  .Kclick2 {
    top: 4vh;
    cursor: pointer;
  }

  .Kclick3 {
    left: 6.9vw;
    cursor: pointer;
  }

  .Kclick4 {
    top: 3vh;
    left: 19vw;
    cursor: pointer;
  }

  .Kclick5 {
    top: 10.2vh;
    left: 19vw;
    cursor: pointer;
  }

  .Kclick6 {
    top: -1vh;
    left: 5vw;
    cursor: pointer;
  }

  .Kclick7 {
    top: 9vh;
    left: 5vw;
    cursor: pointer;
  }

  .fl {
    float: left;
  }

  .main {
    margin-top: -12vh;
    width: 100vw;
    height: 76vh;
    font-size: 0.9vw;
    // border: 1px solid red;

    // margin:auto
  }

  .maincenter {
    width: 93vw;
    height: 77vh;
    background-image: url("~@/assets/images/MFL_rslc.png");
    background-size: 100% 100%;
    // border: 1px saddlebrown solid;
  }

  .col1 {
    height: 2vh;
    line-height: 2vh;
  }

  .color2 {
    color: #14a4ff;
  }

  .col1jg {
    margin-left: 2.5vw;
  }

  .col1jg1 {
    margin-left: 2.5vw;
    margin-top: 0.5vh;
    height: 2vh;
    width: 10vw;
    cursor: pointer;
  }

  .col1jg2 {
    // margin-left: 2.5vw;
    margin-top: 0.5vh;
    height: 2vh;
    width: 10vw;
    cursor: pointer;
  }

  .widthx {
    width: 3vw;
    line-height: 2vh;
  }

  .widthxx {
    width: 2.5vw;
    line-height: 2vh;
    height: 2vh;
  }

  .widthx1 {
    width: 4vw;
    line-height: 2vh;
    text-align: center;
  }

  .valuex1 {
    width: 1.5vw;
  }

  .valuex2 {
    width: 3vw;
    text-align: center;
    // border:1px saddlebrown solid;
    // padding-right:0.5vw;
  }

  .fu-color {
    background-color: #00000020;
  }

  .title {
    width: 58vw;
    margin-left: 36vw;
    margin-top: 1vh;
    margin-bottom: 14vh;
    height: 4vh;
    font-family: MicrosoftYaHei;
    font-size: 1.8vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 4vh;
    letter-spacing: 0vw;
    color: #ffffff;
    box-shadow: 0vw 0vw 0vw 0vw #009ed2;
  }

  .leftmain {
    width: 6vw;
    height: 76vh;
    color: #5ca4a6;
  }

  .left-widthx1 {
    width: 5.5vw;
    line-height: 2vh;
    text-align: right;
  }

  .left-widthx2 {
    width: 5.5vw;
    line-height: 2vh;
    line-height: 2vh;
    text-align: left;
    color: #fff;
    cursor: pointer;
  }

  .buttonjg {
    margin-left: 0.2vw;
  }

  .left-jg {
    margin-top: 5.2vh;
  }

  .left-jg1 {
    margin-top: 0.2vh;
  }

  .valuex {
    line-height: 2vh;
    width: 2.5vw;
    text-align: center;
    cursor: pointer;
  }

  .valuexzr {
    line-height: 3vh;
    width: 2.5vw;
    text-align: center;
    cursor: pointer;
  }

  .valuex12 {
    line-height: 2vh;
    width: 4vw;
    text-align: center;
    cursor: pointer;
  }

  .danwei {
    line-height: 2vh;
    width: auto;
    margin-left: 0.5vw;
  }

  .control-jg3 {
    margin-top: 6vh;
    height: 3vh;
    border: rgb(35, 228, 17) 1px solid;
  }

  .control-jg4 {
    margin-top: 2vh;
  }

  .waterline {
    width: 10vw;
    height: 5.5vh;
  }

  .color1 {
    color: #5ca4a6;
  }

  .color3 {
    color: #49eb49;
  }

  .control-col1 {
    width: 5vw;
    line-height: 3vh;
    height: 3vh;
    text-align: center;
    cursor: pointer;
  }

  .control-col1x {
    width: 5vw;
    line-height: 2.5vh;
    height: 2.5vh;
    text-align: center;
    color: #fff;
  }

  .control-col2 {
    width: 11vw;
    height: 2.5vh;
    line-height: 3.5vh;
    margin-top: 0.5vh;
    // text-align: center;
    // border:1px solid saddlebrown;
  }

  .control-model {
    width: 10vw;
    height: 13vh;
  }

  .bottonx {
    width: 1vw;
    height: 2vh;
    text-align: center;
    line-height: 2vh;
    border: 2px solid rgb(217, 243, 145);
    font-size: 1.7vh;
    // margin-right: 0.1vw;
    color: #fff;
    cursor: pointer;
  }

  .control-jg {
    margin-left: 1vw;
  }

  .control-jg1 {
    margin-left: 0.2vw;
  }

  .control-jg2 {
    margin-left: 1.5vw;
  }

  .control-jg4 {
    margin-top: 6vh;
  }

  .control-jg5 {
    margin-top: 2.5vh;
  }

  .left-jg2 {
    margin-top: 5.7vh;
  }

  .left-jg3 {
    margin-top: 1vh;
  }

  .left-jg4 {
    margin-top: 0.7vh;
  }

  .left-jg5 {
    margin-top: 15vh;
    margin-bottom: 0.5vh;
  }

  .left-jg6 {
    margin-top: 2。2vh;
    margin-bottom: 0.5vh;
  }

  .molgd {
    margin-top: 17vh;
    margin-left: 2vh;
    width: 6vw;
  }

  .molgd-jg {
    margin-top: 3vh;
  }

  .molgd-col1 {
    height: 2vh;
  }

  .shuiweisp {
    width: 10vw;
    height: 2vh;
    // border:1px solid orangered;
  }

  .lutang {
    margin-top: 30vh;
    margin-left: 5vw;
  }

  .lutang-col1 {
    height: 2vh;
    cursor: pointer;
  }

  .lutang-jg {
    margin-left: 1vw;
  }

  .lutang-jg1 {
    margin-top: 1vh;
  }

  .lutang-jg2 {
    margin-left: 0.5vw;
  }

  .glqmain {
    width: 30vw;
    height: 10vh;
    margin-top: 10vh;
    margin-left: 3vw;
    // border: #009ed2 1px solid;
  }

  .glqtop-jg {
    margin-left: 3vw;
    margin-top: 1vh;
    // border: #009ed2 1px solid;
  }

  .glqtop-jg1 {
    margin-left: 5vw;
    margin-top: 1vh;
  }

  .glqtop-jg2 {
    margin-left: 6vw;
    margin-top: 1vh;
  }

  .yangliang {
    margin-left: 22vw;
    margin-top: 3vh;
    height: 5vh;
  }

  .lutangall {
    width: 30vw;
    // height: 2vh;
    // margin-top: 3vh;
    // border: #009ed2 1px solid;
  }

  .ltbottom-row1 {
    margin-top: 3vh;
    margin-left: 6vw;
    width: 10vw;
    height: 2vh;
    // border: #009ed2 1px solid;
  }

  .ltbottom-row2 {
    margin-top: 1vh;
    margin-left: 6vw;
    width: 10vw;
    height: 2vh;
    // border: #009ed2 1px solid;
  }

  .ltbottom-row3 {
    margin-top: 1vh;
    margin-left: 4vw;
    width: 15vw;
    height: 2vh;
    // border: #009ed2 1px solid;
  }

  .ltbottom-row4 {
    margin-top: 1vh;
    margin-left: 1.8vw;
    width: 18vw;
    height: 2vh;
  }

  .widthx2 {
    width: 6vw;
    height: 2vh;
    line-height: 2vh;
    text-align: right;
    padding-right: 1vw;
    // border: #009ed2 1px solid;
  }

  .widthx3 {
    width: 2.5vw;
    height: 2vh;
    line-height: 2vh;
    text-align: right;
    margin-left: 0.5vw;
    padding-right: 0.5vw;
  }

  .widthx4 {
    width: 6vw;
    height: 2vh;
    line-height: 2vh;
    text-align: center;
    // padding-right: 1vw;
  }

  .widthx5 {
    width: 5vw;
    height: 2vh;
    line-height: 2vh;
    text-align: center;
    padding-left: 1vw;
  }

  .ltbottm-jg {
    margin-top: 3vh;
  }

  .ltbottm-jg2 {
    margin-left: 1vw;
  }

  .ltbottm-jg3 {
    margin-top: 6vh;
  }

  .shengmq {
    width: 10vw;
    margin-top: 8.5vh;
    margin-left: 3.2vw;
    // border: #009ed2 1px solid;
  }

  .shengmq-row1 {
    height: 2vh;
  }

  .shengmq-jg {
    margin-left: 0.5vw;
  }

  .shengmq-jg1 {
    margin-top: 8vh;
  }

  .shengmq-jg2 {
    margin-top: 2vh;
  }

  .rightmain {
    width: 33vw;
    // height: 40vh;
    // border: 1px solid saddlebrown;
    margin-left: 2vw;
    margin-top: 1vh;
  }

  .rightmain-row1 {
    height: 2vh;
    line-height: 2vh;
    width: 38vw;
    text-align: center;
    margin-top: 0.5vh;
    position: relative;
  }

  .rightmain-row1-col {
    width: 4vw;
    text-align: center;
    // border: 1px solid saddlebrown;
  }

  .rightmain-jg {
    margin-top: 2vh;
    margin-left: 1vw;
  }

  .rightmain-jg2 {
    margin-left: 0.5vw;
  }

  .rightmain-jg7 {
    margin-left: 28.7vw;
    margin-top: -1vh;
  }

  .rightmain-jg8 {
    margin-left: 5.7vw;
    margin-top: -1vh;
  }

  .rightmain-jg9 {
    margin-left: 16.7vw;
    margin-top: -2vh;
  }

  .rightmain-jg31 {
    margin-right: 5vw;
  }

  .rightmain-jg7 {
    margin-left: 6vw;
  }

  .rightmain-jg3 {
    margin-left: 2vw;
  }

  .rightmain-jg4 {
    margin-top: 2vh;
  }

  .rightmain-jg5 {
    margin-top: 1.5vh;
  }

  .rightmain-jg6 {
    margin-left: 0.5vw;
  }

  .buttonjg {
    margin-left: 0.2vw;
  }

  .widthx31 {
    width: 0.5vw;
    height: 2vh;
    line-height: 2vh;
    text-align: right;
    margin-left: 0.5vw;
    padding-right: 0.5vw;
  }

  .geishui {
    height: 25vh;
  }

  .songfeng {
    margin-top: 5vh;
    // border: rgb(35, 228, 17) 1px solid;
    height: 14vh;
    // border: #009ed2 1px solid;
  }

  .yicfeng {
    position: relative;
    // margin-top: 5vh;
    // border: rgb(35, 228, 17) 1px solid;
    height: 15.5vh;
    // border: #009ed2 1px solid;
  }

  .yinfen-jg {
    margin-top: 6vh;
    margin-left: 2vw;
  }

  .yinfen-jg2 {
    margin-top: 2vh;
    position: relative;
    z-index: 100;
  }

  .yinfen-jg4 {
    margin-top: 1.5vh;
    margin-left: 1vw;
    position: relative;
    z-index: 101;
  }

  // .yinfen-jg5 {
  //   margin-top: 1.5vh;
  // }
  .yinfen-jg5 {
    margin-top: 1.5vh;
    position: relative;
    z-index: 102;
  }

  .yinfen-jg6 {
    margin-left: 1.5vw;
  }

  .yinfen-jg7 {
    margin-left: 1.8vw;
    // margin-top:1vh;
  }

  .yinfen-jg3 {
    margin-top: 4vh;
    margin-left: 13vh;
  }

  .yinfen-jg8 {
    margin-top: 2.5vh;
    margin-left: 1.5vw;
  }

  .yinfen-jg9 {
    margin-top: 2vh;
    margin-left: 3vw;
  }

  .yinfen-jg10 {
    margin-top: 1vh;
    margin-left: -3vw;
  }

  .yinfen-jg11 {
    margin-top: 1vh;
    margin-left: -3vw;
  }

  .yinfeng {
    margin-left: 6.7vw;
    margin-top: 3vh;
    position: relative;

    .yinfeng-jg1 {
      margin-top: 5vh;
    }

    .yinfeng-jg2 {
      margin-left: 1vw;
    }

    .yinfeng-jg3 {
      margin-top: 4vh;
    }

    .yinfeng-jg4 {
      margin-top: 7vh;
    }

    .yinfeng-jg5 {
      margin-left: 0.5vw;
    }
  }
}
</style>
