<template>
  <div class="jrlmqzg drag" id="QBWC" v-draw>
    <!-- 标题部分 -->
    <div>
      <div class="title-hang" fl>{{projectData.project}}_QBW_C</div>
      <div class="param11-header-icon" fr @click="closeCompon">
        <img
          src="~@/assets/images/close.png"
          style="width: 100%; height: auto"
        />
      </div>
    </div>
    <div class="titlelable">汽包水位优化控制模型</div>
    <div class="main">
      <!-- 第一部分 上面两个框 -->
      <div class="row1">
        <div class="SWSPH fl">
          <ul>
            <li>
              <div class="sl">P01-SWSPH</div>
              <div
                class="sr"
                @click="
                  toIpt(infoList.QBW_SP_HL, 'P01-SWSPH', 'QBW_SP_HL', infoList.QBW_SP_HL_node_string)
                "
              >
                {{'QBW_SP_HL' in infoList? infoList.QBW_SP_HL :0}}
              </div>
            </li>
            <li>
              <div class="sl">P01-SWSPL</div>
              <div
                class="sr"
                @click="
                  toIpt(infoList.QBW_SP_LL, 'P01-SWSPL', 'QBW_SP_LL', infoList.QBW_SP_LL_node_string)
                "
              >
                {{'QBW_SP_LL' in infoList? infoList.QBW_SP_LL :0}}
              </div>
            </li>
            <li>
              <div class="sl">P01-SWSP</div>
              <div
                class="sr"
                style="color: #8aeaff"
                @click="toCompon(2, 'QBW_SP', infoList.QBW_SP_node_string, 'QBW_SP_BM', '水位设定值')"
                @dblclick="
                  Cclick(infoList.QBW_SP, 'QBW_SP', infoList.QBW_SP_node_string, 'QBW_SP_BM')
                "
              >
                {{'QBW_SP' in infoList? infoList.QBW_SP :0}}
              </div>
            </li>
          </ul>
        </div>
        <div class="QBW fl">
          <ul>
            <li>
              <div class="sl">QBW_MVA_HL</div>
              <div
                class="sr"
                @click="
                  toIpt(
                    infoList.QBW_MAN1.OutT,
                    'QBW_MVA_HL',
                    'OutT',
                    infoList.QBW_MAN1_node_string
                  )
                "
              >
                {{'QBW_MAN1' in infoList? infoList.QBW_MAN1.OutT :0}}
              </div>
            </li>
            <li>
              <div class="sl">QBW_MVA_LL</div>
              <div
                class="sr"
                @click="
                  toIpt(
                    infoList.QBW_MAN1.OutB,
                    'QBW_MVA_LL',
                    'OutB',
                    infoList.QBW_MAN1_node_string
                  )
                "
              >
                {{'QBW_MAN1' in infoList? infoList.QBW_MAN1.OutB :0}}
              </div>
            </li>
            <li>
              <div class="sl">QBW_MVB_HL</div>
              <div
                class="sr"
                @click="
                  toIpt(
                    infoList.QBW_MAN2.OutT,
                    'QBW_MVB_HL',
                    'OutT',
                    infoList.QBW_MAN2_node_string
                  )
                "
              >
                {{'QBW_MAN2' in infoList? infoList.QBW_MAN2.OutT :0}}
              </div>
            </li>
            <li>
              <div class="sl">QBW_MVB_LL</div>
              <div
                class="sr"
                @click="
                  toIpt(
                    infoList.QBW_MAN2.OutB,
                    'QBW_MVB_LL',
                    'OutB',
                    infoList.QBW_MAN2_node_string
                  )
                "
              >
                {{'QBW_MAN2' in infoList? infoList.QBW_MAN2.OutB :0}}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!--第一行控div  -->
      <!-- 第二部分  带图主体 -->
      <div class="model">
        <div class="shuiwei fl">
          <ul>
            <li class="flex">
              <div
                class="sl"
                :style="{
                  background: infoList.QBW_SEL == 1 ? '#2AFC30' : 'red',
                }"
                @click="toIpt('1', '水位1', 'QBW_SEL', infoList.QBW_SEL_node_string, 'true')"
              >
                水位1
              </div>
              <div
                class="sr"
                @click="
                  toCompon(
                    2,
                    'QBW_A_PV',
                    infoList.QBW_A_PV_node_string,
                    'QBW_A_PV_BM',
                    '汽包水位过程值1'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.QBW_A_PV,
                    'QBW_A_PV',
                    infoList.QBW_A_PV_node_string,
                    'QBW_A_PV_BM'
                  )
                "
              >
                {{'QBW_A_PV' in infoList? infoList.QBW_A_PV :0}}
              </div>
            </li>
            <li class="flex">
              <div
                class="sl"
                style="margin-top: 0.2vh"
                :style="{
                  background: infoList.QBW_SEL == 2 ? '#2AFC30' : 'red',
                }"
                @click="toIpt('2', '水位2', 'QBW_SEL', infoList.QBW_SEL_node_string, 'true')"
              >
                水位2
              </div>
              <div
                class="sr"
                @click="
                  toCompon(
                    2,
                    'QBW_B_PV',
                    infoList.QBW_B_PV_node_string,
                    'QBW_B_PV_BM',
                    '汽包水位过程值2'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.QBW_B_PV,
                    'QBW_B_PV',
                    infoList.QBW_B_PV_node_string,
                    'QBW_B_PV_BM'
                  )
                "
              >
                {{'QBW_B_PV' in infoList? infoList.QBW_B_PV :0}}
              </div>
            </li>
            <li class="flex">
              <div
                class="sl"
                style="margin-top: 0.2vh"
                :style="{
                  background: infoList.QBW_SEL == 3 ? '#2AFC30' : 'red',
                }"
                @click="toIpt('3', '水位3', 'QBW_SEL', infoList.QBW_SEL_node_string, 'true')"
              >
                水位3
              </div>
              <div
                class="sr"
                @click="
                  toCompon(
                    2,
                    'QBW_C_PV',
                    infoList.QBW_C_PV_node_string,
                    'QBW_C_PV_BM',
                    '汽包水位过程值3'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.QBW_C_PV,
                    'QBW_C_PV',
                    infoList.QBW_C_PV_node_string,
                    'QBW_C_PV_BM'
                  )
                "
              >
                {{'QBW_C_PV' in infoList? infoList.QBW_C_PV :0}}
              </div>
            </li>
          </ul>
        </div>
        <div class="P01_tc fl">
          <ul>
            <li>
              <div class="sl">P01-TC</div>
              <div
                class="sr"
                @click="toIpt(infoList.QBW_TC, 'P01-TC', 'QBW_TC', infoList.QBW_TC_node_string)"
              >
                {{'QBW_TC' in infoList? infoList.QBW_TC :0}}
              </div>
            </li>
            <li>
              <div class="sl">P01-YZ</div>
              <div
                class="sr"
                @click="toIpt(infoList.QBW_C, 'P01-YC', 'QBW_C', infoList.QBW_C_node_string)"
              >
                {{'QBW_C' in infoList? infoList.QBW_C :0}}
              </div>
            </li>
            <li>
              <div class="sl a">P01-SWPV</div>
              <div
                class="sr"
                style="color: #8aeaff"
                @click="toCompon(2, 'QBW_PV', infoList.QBW_PV_node_string, 'QBW_PV_BM', '水位过程值')"
                @dblclick="
                  Cclick(infoList.QBW_PV, 'QBW_PV', infoList.QBW_PV_node_string, 'QBW_PV_BM')
                "
              >
                {{'QBW_PV' in infoList? infoList.QBW_PV :0}}
              </div>
            </li>
          </ul>
        </div>
        <div
          class="bottonx fl b_pos_1"
          :style="{
            background:
              infoList.QBW_XK1.RM == 1 || infoList.QBW_XK1.RM == 2
                ? '#2AFC30'
                : 'red',
          }"
          @click="
            infoList
              ? toDetail(
                  1,
                  'QBW_XK1',
                  infoList.QBW_XK1_node_string,
                  '',
                  '汽包水位主调节先控'
                )
              : ''
          "
        >
          X
        </div>
        <div
          class="bottonx fl b_pos_2"
          :style="{
            background:
              infoList.QBW_XK2 == 1 || infoList.QBW_XK2 == 2
                ? '#2AFC30'
                : 'red',
          }"
          @click="
            infoList
              ? toDetail(1, 'QBW_XK2', infoList.QBW_XK2_node_string, '', '主给水副调节')
              : ''
          "
        >
          X
        </div>
        <div
          class="bottonx fl b_pos_3"
          :style="{
            background:
              infoList.QBW_XK3 == 1 || infoList.QBW_XK3 == 2
                ? '#2AFC30'
                : 'red',
          }"
          @click="
            infoList
              ? toDetail(1, 'QBW_XK3', infoList.QBW_XK3_node_string, '', '副给水副调节')
              : ''
          "
        >
          X
        </div>
        <div
          class="bottonx fl b_pos_4"
          :style="{
            background:
			'QBW_MAN1'||'QBW_MAN2' in infoList?
              infoList.QBW_MAN1.RM == 1 ||
              infoList.QBW_MAN2.RM == 1
                ? '#2AFC30'
                : 'red': 'red',
          }"
          @click="toCompon(0, 'MFL_MANGS', 2)"
        >
          A
        </div>
        <div class="P01-A01 fl">
          <ul>
            <li>
              <div class="sl">P01-A01</div>
              <div
                class="sr"
                @click="
                  toCompon(
                    2,
                    'QBW_A_FV',
                    infoList.QBW_A_FV_node_string,
                    'QBW_A_FV_BM',
                    '主给水阀门反馈'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.QBW_A_FV,
                    'QBW_A_FV',
                    infoList.QBW_A_FV_node_string,
                    'QBW_A_FV_BM'
                  )
                "
              >
                {{'QBW_A_FV' in infoList? infoList.QBW_A_FV :0}}
              </div>
            </li>
          </ul>
        </div>
        <div class="P01-A02 fl">
          <ul>
            <li>
              <div class="sl">P01-A02</div>
              <div
                class="sr"
                @click="
                  toCompon(
                    2,
                    'QBW_B_FV',
                    infoList.QBW_B_FV_node_string,
                    'QBW_B_FV_BM',
                    '副给水阀门反馈'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.QBW_B_FV,
                    'QBW_B_FV',
                    infoList.QBW_B_FV_node_string,
                    'QBW_B_FV_BM'
                  )
                "
              >
                {{'QBW_B_FV' in infoList? infoList.QBW_B_FV :0}}
              </div>
            </li>
          </ul>
        </div>
        <div class="P01-QStC fl">
          <ul>
            <div>
              <div class="sl widthx fl">P01-QStC</div>
              <div
                class="sr value fl color1"
                @click="toIpt(infoList.QSTC, 'P01-GSF', 'QSTC', infoList.QSTC_node_string)"
              >
                {{'QSTC' in infoList? infoList.QSTC :0}}
              </div>
            </div>
            <div>
              <div class="sl sl widthx fl">P01-QSPV</div>
              <div
                class="sr value fl"
                style="color: #8aeaff"
                @click="toCompon(2, 'QSPV', infoList.QSPV_node_string, 'QSPV_BM', '汽水偏差过程值')"
                @dblclick="Cclick(infoList.QSPV, 'QSPV', infoList.QSPV_node_string, 'QSPV_BM')"
              >
                {{'QSPV' in infoList? infoList.QSPV :0}}
              </div>
            </div>
          </ul>
        </div>
        <div class="P01-GSF fl">
          <ul>
            <li>
              <div class="sl">P01-GSF</div>
              <div
                class="sr"
                @click="toCompon(2, 'GSF_PV', infoList.GSF_PV_node_string, 'GSF_PV_BM', '给水流量')"
                @dblclick="
                  Cclick(infoList.GSF_PV, 'GSF_PV', infoList.GSF_PV_node_string, 'GSF_PV_BM')
                "
              >
                {{'GSF_PV' in infoList? infoList.GSF_PV :0}}
              </div>
            </li>
            <li>
              <div class="sl">P01-ZQF</div>
              <div
                class="sr"
                @click="toCompon(2, 'ZQF_PV', infoList.ZQF_PV_node_string, 'ZQF_PV_BM', '蒸汽流量')"
                @dblclick="
                  Cclick(infoList.ZQF_PV, 'ZQF_PV', infoList.ZQF_PV_node_string, 'ZQF_PV_BM')
                "
              >
                {{'ZQF_PV' in infoList? infoList.ZQF_PV :0}}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <mflmanyManual
      v-if="mflmanyManual"
      :titname="Manualtitname"
      :numM="Manualnode"
      :mark="Manualmark"
      :manvar="Manualvar"
      @sendStatus="isClose"
      :infoList="infoList"
    ></mflmanyManual>

     <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="node"
      :Lkname="Lkname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal"></inputVal>
  </div>
</template>


<script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Rsf from "@/components/RSF.vue"; //阮伺服组件
import mflmanyManual from "@/components/MflmanyManual1.vue"; //多手操器
export default {
  name: "Param11",
  props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
  },
  components: {
    inputVal,
    Historical,
    Firstcontrol,
    Rsf,
    mflmanyManual,
  },
  data: () => {
    return {
      chName: "",
      mflmanyManual: false,
      Manualmark: "",
      Manualvar: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      isHshow: false,
      isMshow: false,
      isRshow: false,
      historyname: "",
      node: "",
      Lkname: "",
      isIndex: "",
      Firstcontrolname: "",
      Firstcontroltitname: "",
      Firstcontrolnode: "",
      isFshow: false,
      Rsfname: "",
      Rsfnode: "",
      Rsftitname: "",
      projectData: "",
      grouptime: null,
      spotArr: [],
      authInfo: [],
      UserInfo: [],
    };
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }
        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
			case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "mfkfirstcontol";
            break;
          case 3:
            pathname = "mfRfirstcontol";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    isshowfase() {
      this.isComShow = false;
    },
    closeCompon() {
      this.$emit("sendStatus", "isPshow03", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
        case "mflmanyManual":
          return (this.mflmanyManual = val1);
      }
    },
    toIpt(data, name, historyname, node, status) {
      this.$refs.inputVal.open(data, name, historyname, node, status);
    },
    toCompon(key, name, name2, name3, name4, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            return (
              (this.mflmanyManual = true),
              (this.Manualtitname = name),
              (this.Manualnode = name2),
              (this.Manualmark = name3),
              (this.Manualvar = name4)
            );
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (this.isHshow = true), (this.chName = name4);
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = name2),
              (this.Rsftitname = titname)
            );
        }
      }, 300);
    },
  },
};
</script>


<style lang="scss" scoped>
.jrlmqzg {
  width: 55vw;
  height: 78vh;
  //  background: #e9f7ff;
  background-image: url("~@/assets/images/jrl-param.png");
  background-size: 100% 100%;
  //  cursor:move;
  border-radius: 5px;
  color: #ffffff;

  .zhuangshi1 {
    width: 30vw;
    height: 10vh;
    background-color: #0ef7ff;
    opacity: 0.66;
  }

  .main {
    margin-left: 1vw;
    font-size: 0.9vw;
  }

  .bottonx {
    width: 1vw;
    height: 2vh;
    text-align: center;
    line-height: 2vh;
    border: 2px solid rgb(217, 243, 145);
    font-size: 1.7vh;cursor: pointer;
    // margin-right: 0.1vw;
    // color: #000000;
  }

  .row1 {
    width: 51vw;
    height: 8vh;
    margin-top: 5vh;
  }

  .SWSPH {
    width: 11vw;
    height: 8vh;
    margin-left: 20vw;
    margin-top: 0vh;
    background-color: #001b2860;
    border-radius: 0vh;
    border: solid 0vh #236f8d60;

    ul {
      margin: 0.2vh 0.5vw;

      li {
        font-family: PingFangSC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.5vh;
        letter-spacing: 0vh;
        color: #8aeaff;

        .sl {
          float: left;
        }

        .sr {
          float: right;
          color: #00ffb4;
		  cursor: pointer;
        }
      }
    }
  }

  .QBW {
    width: 11vw;
    height: 10.5vh;
    background-color: #001b2860;
    border-radius: 0vh;
    border: solid 0vh #236f8d60;
    margin-left: 6vw;
    margin-top: -3vh;

    ul {
      margin: 0.2vh 0.5vw;

      li {
        font-family: PingFangSC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.5vh;
        letter-spacing: 0vh;
        color: #8aeaff;

        .sl {
          float: left;
        }

        .sr {
          float: right;
          color: #00ffb4;cursor: pointer;
        }
      }
    }
  }

  .shuiwei {
    width: 6vw;
    height: 10vh;
    position: relative;
    left: -7vw;
    top: 3.5vh;

    ul {
      li {
        font-family: PingFangSC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.5vh;
        letter-spacing: 0vh;
        color: #8aeaff;

        .sl {
          width: 2.4vw;
          height: 2.5vh;
          line-height: 2.5vh;
          // float: left;
          background-color: #22fff760;
          border-radius: 0vh;
          // border: solid 0vh #236f8d60;
        }

        .sr {
          width: 3vw;
          text-align: center;
          // float: right;
          color: #8aeaff;cursor: pointer;
        }
      }
    }
  }

  .P01_tc {
    width: 6vw;
    height: 8vh;
    position: relative;
    left: -6.5vw;
    top: 3.5vh;

    ul {
      li {
        font-family: PingFangSC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.5vh;
        letter-spacing: 0vh;
        color: #8aeaff;
        width: 11vw;

        .sl {
          width: 5vw;
          float: left;

          border-radius: 0vh;
          // border: solid 0vh #236f8d60;
        }

        .a {
          background-color: #22fff760;
        }

        .sr {
          width: 4vw;
          height: 2.5vh;
          text-align: center;
          float: left;
          color: #00ffb4;cursor: pointer;
        }
      }
    }
  }

  .color1 {
    color: #00ffb4;
  }

  .P01-A01 {
    width: 9vw;
    height: 3vh;
    position: relative;
    left: 20.3vw;
    top: 18vh;
    background-color: #153b4f;
    border-radius: 0vh;
    border: solid 0vh #236f8d60;

    ul {
      margin: 0.2vh 0.5vw;

      li {
        font-family: PingFangSC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.5vh;
        letter-spacing: 0vh;
        color: #8aeaff;

        width: 9vw;

        .sl {
          width: 5vw;
          float: left;

          border-radius: 0vh;
          // border: solid 0vh #236f8d60;
          // background-color: #22fff760;
        }

        .sr {
          width: 4vw;
          height: 2.5vh;
          text-align: center;
          float: right;cursor: pointer;
          // color: #00ffb4;
        }
      }
    }
  }

  .widthx {
    font-family: PingFangSC-Regular;
    font-size: 0.9vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2.5vh;
    height: 2.5vh;
    letter-spacing: 0vh;
    color: #8aeaff;

    width: 5vw;
  }

  .value {
    width: 5vw;
    height: 2.5vh;
    line-height: 2.5vh;
    border-radius: 0vh;
    text-align: center;
  }

  .P01-QStC {
    width: 11vw;
    height: 6vh;
    position: relative;
    left: -8.7vw;
    top: 23.3vh;
    background-color: #001b2860;
    border-radius: 0vh;
    border: solid 0vh #236f8d60;

    ul {
      margin: 0.2vh 0.5vw;

      li {
        font-family: PingFangSC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.5vh;
        letter-spacing: 0vh;
        color: #8aeaff;

        width: 5vw;

        .sl {
          width: 5vw;
          float: left;
          height: 2.5vh;
          border-radius: 0vh;
          // border: solid 0vh #236f8d60;
          // background-color: #22fff760;
        }

        .sr {
          width: 3vw;
          height: 2.5vh;
          text-align: right;
          float: right;
          color: #00ffb4;
          margin-left: 4vw;cursor: pointer;
        }
      }
    }
  }

  .P01-GSF {
    width: 8vw;
    height: 6vh;
    position: relative;
    left: -29.7vw;
    top: 23.3vh;

    // background-color: #001b2860;
    // border-radius: 0vh;
    // border: solid 0vh #236f8d60;
    ul {
      li {
        font-family: PingFangSC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.5vh;
        letter-spacing: 0vh;
        color: #8aeaff;

        .sl {
          width: 5vw;
          float: left;
          margin-left: -1vw;

          border-radius: 0vh;
          // border: solid 0vh #236f8d60;
          // background-color: #22fff760;
        }

        .sr {
          width: 3vw;
          height: 2.5vh;
          text-align: center;
          float: left;
          color: #8aeaff;
          margin-left: -1vw;cursor: pointer;
        }
      }
    }
  }

  .P01-A02 {
    width: 9vw;
    height: 3vh;
    position: relative;
    left: 11.3vw;
    top: 28vh;
    background-color: #153b4f;
    border-radius: 0vh;
    border: solid 0vh #236f8d60;

    ul {
      margin: 0.2vh 0.5vw;

      li {
        font-family: PingFangSC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.5vh;
        letter-spacing: 0vh;
        color: #8aeaff;

        width: 9vw;

        .sl {
          width: 5vw;
          float: left;

          border-radius: 0vh;
          // border: solid 0vh #236f8d60;
          // background-color: #22fff760;
        }

        .sr {
          width: 4vw;
          height: 2.5vh;
          text-align: center;
          float: right;cursor: pointer;
          // color: #00ffb4;
        }
      }
    }
  }

  .b_pos_1 {
    position: relative;
    left: 5vw;
    top: 8.6vh;
  }

  .b_pos_2 {
    position: relative;
    left: 11.2vw;
    top: 18.7vh;
  }

  .b_pos_3 {
    position: relative;
    left: 16.7vw;
    top: 28vh;
  }

  .b_pos_4 {
    position: relative;
    left: 17vw;
    top: 23vh;
  }

  .model {
    width: 40vw;
    height: 30vh;
    //  background: #e9f7ff;
    background-image: url("~@/assets/images/MFL_汽包水位切图.png");
    background-size: 100% 100%;
    //  cursor:move;
    border-radius: 5px;
    color: #ffffff;
    margin-left: 7vw;
  }

  .bottonx {
    width: 1vw;
    height: 2vh;
    text-align: center;
    line-height: 2vh;
    border: 2px solid rgb(217, 243, 145);
    font-size: 1.7vh;cursor: pointer;
    // margin-right: 0.1vw;
    // color: #000000;
  }

  .col_bgc {
    background-color: #001b2860;
    border-radius: 0vw;
    border: solid 0vw #236f8d60;
  }

  .fl {
    float: left;
  }

  .fr {
    float: right;
  }

  .title-hang {
    height: 5vh;
    width: 53vw;
    font-family: MicrosoftYaHei;
    font-size: 1.8vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    padding-top: 0.5vh;
    // margin-top:vh;
  }

  .title1 {
    width: 8vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 0.9vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #ffffff;
    margin-top: 1vh;
    margin-left: 1vw;
  }

  .title-hang {
    width: 100%;
    height: 6vh;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-size: 3vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #0ef7ff;
  }

  .lable1 {
    font-family: PingFang-SC-Regular;
    font-size: 2.5vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2.5vh;
    letter-spacing: 0vh;
    color: #d5fffe;
    margin-left: 2.5vw;
    margin-top: 1vh;
  }

  .titlelable {
    width: 53vw;
    height: 3vh;
    font-family: PingFang-SC-Regular;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0.3vw;
    color: #d5fffe;
    text-align: center;
    margin-top: 4vh;
    margin-bottom: 2vh;
  }

  .param11-header-icon {
    width: 2vw;
    height: 2vh;
    right: 1vw;
    margin-top: -5vh;
    cursor: pointer;
    margin-left: 52vw;
  }

  .button-top {
    width: 10vw;
    height: 3vh;
    background-color: #008db1;
    text-align: center;
    line-height: 3vh;
    margin-right: 3vw;
  }
}
</style>
