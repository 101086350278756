var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"mflmac drag",attrs:{"id":"YCFC"}},[_c('div',[_c('div',{staticClass:"title-hang",attrs:{"fl":""}},[_vm._v(_vm._s(_vm.projectData.project)+"_YCF_C")]),_c('div',{staticClass:"param11-header-icon",attrs:{"fr":""},on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"titlelable"},[_vm._v("一次风压优化控制模型")]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"leftmain fl"},[_c('div',{staticClass:"leftmain-col"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCF_A_SPB")]),_c('div',{staticClass:"valuex fl",on:{"click":function($event){return _vm.toCompon(
                2,
                'YCF_A_SPB2',
                _vm.infoList.YCF_A_SPB2_node_string,
                'YCF_A_SPB_BM',
                '1#一次风设定值偏置'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.YCF_A_SPB2,
                'YCF_A_SPB2',
                _vm.infoList.YCF_A_SPB2_node_string,
                'YCF_A_SPB_BM'
              )}}},[_vm._v(" "+_vm._s('YCF_A_SPB2' in _vm.infoList?_vm.infoList.YCF_A_SPB2 :0)+" ")])]),_c('div',{staticClass:"fybutton",on:{"click":function($event){return _vm.toCompon(13, 'ZQFS', '最小风量折线', 'GML', 'FM')}}},[_vm._v(" 风压SP ")]),_c('div',{staticClass:"leftmain-col leftmain-jg"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCF_A_SP")]),_c('div',{staticClass:"valuex fl",on:{"click":function($event){return _vm.toCompon(2, 'YCF_A_SP', _vm.infoList.YCF_A_SP_node_string, 'YCF_A_SP_BM', '1#一次风设定值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.YCF_A_SP, 'YCF_A_SP', _vm.infoList.YCF_A_SP_node_string, 'YCF_A_SP_BM')}}},[_vm._v(" "+_vm._s('YCF_A_SP' in _vm.infoList?_vm.infoList.YCF_A_SP :0)+" ")])]),_c('div',{staticClass:"leftmain-col leftmain-jg"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCF_SPH")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.YCF_SP_HL, 'YCF_SPH', 'YCF_SP_HL', _vm.infoList.YCF_SP_HL_node_string)}}},[_vm._v(" "+_vm._s('YCF_SP_HL' in _vm.infoList?_vm.infoList.YCF_SP_HL :0)+" ")])]),_c('div',{staticClass:"leftmain-col"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCF_SPL")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.YCF_SP_LL, 'YCF_SPL', 'YCF_SP_LL', _vm.infoList.YCF_SP_LL_node_string)}}},[_vm._v(" "+_vm._s('YCF_SP_LL' in _vm.infoList?_vm.infoList.YCF_SP_LL :0)+" ")])])]),_c('div',{staticClass:"centermain fl"},[_c('div',{staticClass:"fl centmian-left"},[_c('div',{staticClass:"leftmain-col centmian-jg"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCI_PVA_HL")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.YCI_PVA_HL,
                  'YCI_PVA_HL',
                  'YCI_PVA_HL',
                  _vm.infoList.YCI_PVA_HL_node_string
                )}}},[_vm._v(" "+_vm._s('YCI_PVA_HL' in _vm.infoList?_vm.infoList.YCI_PVA_HL :0)+" ")])]),_c('div',{staticClass:"leftmain-col centmian-jg1"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCI_PVB_HL")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.YCI_PVA_LL,
                  'YCI_PVA_LL',
                  'YCI_PVA_LL',
                  _vm.infoList.YCI_PVA_LL_node_string
                )}}},[_vm._v(" "+_vm._s('YCI_PVA_LL' in _vm.infoList?_vm.infoList.YCI_PVA_LL :0)+" ")])]),_c('div',[_c('div',{staticClass:"bottonx fl",style:({
                background: !_vm.infoList.YCF_XK1.RM ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.YCF_XK1
                  ? _vm.toDetail(
                      1,
                      'YCF_XK1',
                      _vm.infoList.YCF_XK1_node_string,
                      '',
                      '一次风压先控调节'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"leftmain-colx centmian-jg2 fl"},[_c('div',{staticClass:"widthd fl"},[_vm._v("BP-AV")]),_c('div',{staticClass:"valuex fl",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.YCF_XK1_node_string,
                    'YCF_XK1_AV_BM',
                    '一次风压先控调节',
					'YCF_XK1'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.YCF_XK1.AV,
                    'AV',
                    _vm.infoList.YCF_XK1_node_string,
                    'YCF_XK1_AV_BM'
                  )}}},[_vm._v(" "+_vm._s('YCF_XK1' in _vm.infoList?_vm.infoList.YCF_XK1.AV :0)+" ")])])]),_c('div',{staticClass:"leftmain-col centmian-jg3"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCF_TC")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.YCF_TC, 'YCF_TC', 'YCF_TC', _vm.infoList.YCF_TC_node_string)}}},[_vm._v(" "+_vm._s('YCF_TC' in _vm.infoList?_vm.infoList.YCF_TC :0)+" ")])]),_c('div',{staticClass:"leftmain-col centmian-jg4"},[_c('div',{staticClass:"widthd fl"},[_vm._v("YCF_A_PVL")]),_c('div',{staticClass:"valuex fl",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'YCF_A_PVL',
                  _vm.infoList.YCF_A_PVL_node_string,
                  'YCF_A_PVL_BM',
                  'A一次风压滤波值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.YCF_A_PVL,
                  'YCF_A_PVL',
                  _vm.infoList.YCF_A_PVL_node_string,
                  'YCF_A_PVL_BM'
                )}}},[_vm._v(" "+_vm._s('YCF_A_PVL' in _vm.infoList?_vm.infoList.YCF_A_PVL :0)+" ")])])]),_c('div',{staticClass:"fl centmian-right"},[_c('div',{staticClass:"bottonx centmian-jg5",style:({
              background: !_vm.infoList.YCF_RSF1.TS ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.YCF_RSF1
                ? _vm.toDetail(
                    3,
                    'YCF_RSF1',
                    _vm.infoList.YCF_RSF1_node_string,
                    '',
                    '一次风机电流纠偏'
                  )
                : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"leftmain-col1 centmian-jg6"},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF_MVBA_B")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.YCF_MVA_B, 'YCF_MVA_B', 'YCF_MVA_B', _vm.infoList.YCF_MVA_B_node_string)}}},[_vm._v(" "+_vm._s('YCF_MVA_B' in _vm.infoList?_vm.infoList.YCF_MVA_B :0)+" ")])]),_c('div',{staticClass:"bottonx centmian-jg7",style:({
              background:
			  'YCF_MAN1'||'YCF_MAN2' in _vm.infoList?
                _vm.infoList.YCF_MAN1.RM == 1 ||
                _vm.infoList.YCF_MAN2.RM == 1
                  ? '#2AFC30'
                  : 'red': 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'MFL_MANYCF', 2)}}},[_vm._v(" A ")]),_c('div',{staticClass:"leftmain-col1 centmian-jg8"},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF_MVBB_B")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.YCF_MVB_B, 'YCF_MVB_B', 'YCF_MVB_B', _vm.infoList.YCF_MVB_B_node_string)}}},[_vm._v(" "+_vm._s('YCF_MVB_B' in _vm.infoList?_vm.infoList.YCF_MVB_B :0)+" ")])]),_c('div',{staticClass:"centmian-jg9"},[_c('div',{staticClass:"bottonx fl",style:({
                background: !_vm.infoList.YCF_QK1.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.YCF_QK1
                  ? _vm.toDetail(2, 'YCF_QK1', _vm.infoList.YCF_QK1_node_string, '', '一次风前馈')
                  : ''}}},[_vm._v(" Q ")]),_c('div',{staticClass:"leftmain-col centmian-jg10 fl"},[_c('div',{staticClass:"widthd fl"},[_vm._v("ZQF_SP_TC")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.ZQF_SP_TC, 'ZQF_SP_TC', 'ZQF_SP_TC', _vm.infoList.ZQF_SP_TC_node_string)}}},[_vm._v(" "+_vm._s('ZQF_SP_TC' in _vm.infoList?_vm.infoList.ZQF_SP_TC :0)+" ")])])])])]),_c('div',{staticClass:"rightmain fl"},[_c('div',{staticClass:"leftmain-col1"},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCFI_JPPC")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.YCJI_JPPC, 'YCJI_JPPC', 'YCJI_JPPC', _vm.infoList.YCJI_JPPC_node_string)}}},[_vm._v(" "+_vm._s('YCJI_JPPC' in _vm.infoList?_vm.infoList.YCJI_JPPC :0)+" ")])]),_c('div',{staticClass:"leftmain-col1 rightmain-jg1"},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF_MAN1")]),_c('div',{staticClass:"valuex fl",on:{"click":function($event){return _vm.toCompon(
                2,
                'AV',
                _vm.infoList.YCF_MAN1_node_string,
                'YCF_MAN1_AV_BM',
                '1#一次风机手操器',
				'YCF_MAN1'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.YCF_MAN1.AV,
                'AV',
                _vm.infoList.YCF_MAN1_node_string,
                'YCF_MAN1_AV_BM'
              )}}},[_vm._v(" "+_vm._s('YCF_MAN1' in _vm.infoList?_vm.infoList.YCF_MAN1.AV :0)+" ")])]),_c('div',{staticClass:"leftmain-col1 rightmain-jg2"},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF-MVA-HL")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.YCF_MAN1.OutT,
                'YCF-MVA-HL',
                'OutT',
                _vm.infoList.YCF_MAN1_node_string
              )}}},[_vm._v(" "+_vm._s('YCF_MAN1' in _vm.infoList?_vm.infoList.YCF_MAN1.OutT :0)+" ")])]),_c('div',{staticClass:"leftmain-col1"},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF-MVA-LL")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.YCF_MAN1.OutB,
                'YCF-MVA-LL',
                'OutB',
                _vm.infoList.YCF_MAN1_node_string
              )}}},[_vm._v(" "+_vm._s('YCF_MAN1' in _vm.infoList?_vm.infoList.YCF_MAN1.OutB :0)+" ")])]),_c('div',{staticClass:"leftmain-col1 rightmain-jg3"},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF-MAN2")]),_c('div',{staticClass:"valuex fl",on:{"click":function($event){return _vm.toCompon(
                2,
                'AV',
                _vm.infoList.YCF_MAN2_node_string,
                'YCF_MAN2_AV_BM',
                '2#一次风机手操器',
				'YCF_MAN2'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.YCF_MAN2.AV,
                'AV',
                _vm.infoList.YCF_MAN2_node_string,
                'YCF_MAN2_AV_BM'
              )}}},[_vm._v(" "+_vm._s('YCF_MAN2' in _vm.infoList?_vm.infoList.YCF_MAN2.AV :0)+" ")])]),_c('div',{staticClass:"leftmain-col1 rightmain-jg2"},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF-MVB-HL")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.YCF_MAN2.OutT,
                'YCF-MVB-HL',
                'OutT',
                _vm.infoList.YCF_MAN2_node_string
              )}}},[_vm._v(" "+_vm._s('YCF_MAN2' in _vm.infoList?_vm.infoList.YCF_MAN2.OutT :0)+" ")])]),_c('div',{staticClass:"leftmain-col1"},[_c('div',{staticClass:"widthd1 fl"},[_vm._v("YCF-MVB-LL")]),_c('div',{staticClass:"valuex fl colorl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.YCF_MAN2.OutB,
                'YCF-MVB-LL',
                'OutB',
                _vm.infoList.YCF_MAN2_node_string
              )}}},[_vm._v(" "+_vm._s('YCF_MAN2' in _vm.infoList?_vm.infoList.YCF_MAN2.OutB :0)+" ")])])])]),(_vm.mflmanyManual)?_c('mflmanyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"mark":_vm.Manualmark,"manvar":_vm.Manualvar,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.MfZQFOZX)?_c('MfZQFOZX',{attrs:{"infoList":_vm.infoList,"MfZQFOZXCS1":_vm.MfZQFOZXCS1,"MfZQFOZXCS2":_vm.MfZQFOZXCS2,"MfZQFOZXCS3":_vm.MfZQFOZXCS3,"MfZQFOZXCS4":_vm.MfZQFOZXCS4},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"node1":_vm.node1,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),_c('inputVal',{ref:"inputVal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }